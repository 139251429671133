<mat-card *ngIf="currentKPIs">
    <div style="position: relative">
      <mat-card-header>
        <mat-card-title>
          <i class="material-icons" mat-card-avatar>account_balance_wallet</i> Average Claim Cost
        </mat-card-title>
      </mat-card-header>
    </div>
    <mat-card-content>
      <div topmargin20 flex>
        <div>
          <div class="data-label">{{currentKPIs.reportPeriod | date: 'MMMM y'}} Average Claim Cost</div>
          <h1 id="acc-figure" class="big-data">{{currentKPIs.averageClaimCost | number: '1.2-2'}}</h1>
        </div>
        <div>
          <div class="data-label">Figures</div>

          <div flex>
            <div># Claims:</div>
            <div nogrow style="text-align: right">{{currentKPIs.claimCount}}</div>
          </div>
          <div flex>
            <div>Claim Costs:</div>
            <div style="text-align: right">
              {{currentKPIs.claimCost | number: '1.2-2'}}
            </div>
          </div>
        </div>
      </div>
      <app-dashboard-contractor-kpi-chart title="Average Claim Cost" [kpis]="kpis" chartType="line"
      metricField="averageClaimCost" targetField="accTarget" yAxisLabel="ACC"></app-dashboard-contractor-kpi-chart>
    </mat-card-content>
  </mat-card>