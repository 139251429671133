import { Component, OnInit } from '@angular/core';
import { CustomFunctionApiService } from '@cogent/client/shared/services/api/custom-function-api.service';
import { ContractorApiService } from '@cogent/client/shared/services/api/contractor-api.service';
import { Address, BecomeAContractorArgs, Brand, ServiceProTerritorySummary, Trade } from '@upkeeplabs/models/cogent';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';

@Component({
    selector: 'app-become-a-contractor',
    templateUrl: './become-a-contractor.component.html',
    styleUrls: ['./become-a-contractor.component.css']
})
export class BecomeAContractorComponent implements OnInit {

    args: BecomeAContractorArgs = new BecomeAContractorArgs();
    trades: Trade[];
    brands: Brand[];
    areas: ServiceProTerritorySummary[] = [];
    territories: ServiceProTerritorySummary[] = [];
    selectedIndex = 0;
    sources: string[] = [
        'Internet Search',
        'Local Supply House',
        'Other Service Professional',
        'Dispatch App',
        'Other'
    ];
    selectedSource: string;

    constructor(private contractorApi: ContractorApiService,
        private functionApi: CustomFunctionApiService,
        private missionService: MissionService) { }

    ngOnInit(): void {
        this.args.address = new Address();
        this.args.address.id = UtilitiesService.newid();

        this.contractorApi.getBrands().then(brands => this.brands = brands);
        this.contractorApi.getTrades().then(trades => this.trades = trades);

        this.loadTerritories();
    }

    loadTerritories() {
        this.contractorApi.getContractorTerritories().then(results => {
            const areaNames = [...new Set(results.map(i => i.areaName))].sort((a, b) => a > b ? 1 : -1);
            for (const areaName of areaNames) {
                const areas = results.filter(i => i.areaName === areaName);
                const area = new ServiceProTerritorySummary();
                area.areaName = areaName;
                area.areaId = areas[0].areaId;

                const regionNames = [...new Set(areas.map(i => i.regionName))].sort((a, b) => a > b ? 1 : -1);
                for (const regionName of regionNames) {
                    const regions = results.filter(i => i.areaName === areaName && i.regionName === regionName);
                    const region = new ServiceProTerritorySummary();
                    region.areaName = areaName;
                    region.regionName = regionName;
                    region.regionId = regions[0].regionId;
                    region.parent = area;

                    const territoryNames = [...new Set(regions.map(i => i.territoryName))].sort((a, b) => a > b ? 1 : -1);
                    for (const territoryName of territoryNames) {
                        const territories = results.filter(i => i.areaName === areaName && i.regionName === regionName && i.territoryName === territoryName);
                        const territory = new ServiceProTerritorySummary();
                        territory.areaName = areaName;
                        territory.regionName = regionName;
                        territory.territoryName = territoryName;
                        territory.territoryId = territories[0].territoryId;
                        territory.parent = region;

                        region.territories.push(territory);
                        this.territories.push(territory);
                    }

                    area.regions.push(region);
                }

                this.areas.push(area);
            }
        });
    }

    updateArea(value: ServiceProTerritorySummary) {
        const totalCount = value.parent.regions.length;
        const selectedCount = value.parent.regions.filter(i => i.hasSelected).length;
        if (selectedCount < totalCount) {
            value.parent.hasSelected = false;
        } else {
            value.parent.hasSelected = true;
        }
    }

    areaChanged(value: ServiceProTerritorySummary) {
        if (value.hasSelected) value.expanded = true;
        //SELECT OR UNSELECT REGIONS
        for (const region of value.regions) {
            region.hasSelected = value.hasSelected;
            if (value.hasSelected) region.expanded = true;
            //SELECT OR UNSELECT TERRITORIES
            for (const territory of region.territories) {
                territory.hasSelected = value.hasSelected;
            }
        }
    }

    regionChanged(value: ServiceProTerritorySummary) {
        if (value.hasSelected) value.expanded = value.hasSelected;
        for (const item of value.territories) {
            item.hasSelected = value.hasSelected;
        }

        this.updateArea(value);
    }

    territoryChanged(value: ServiceProTerritorySummary) {
        const totalCount = value.parent.territories.length;
        const selectedCount = value.parent.territories.filter(i => i.hasSelected).length;
        if (selectedCount < totalCount) {
            value.parent.hasSelected = false;
            value.parent.parent.hasSelected = false;
        } else {
            value.parent.hasSelected = true;
            this.updateArea(value.parent);
        }
    }

    get isApplianceContractor() {
        return this.trades && this.trades.filter(i => i.selected && i.name === 'Appliance').length > 0;
    }

    get allBrands() {
        if (this.brands) {
            return false;
        }
        if (this.brands.filter(i => !i.selected).length > 0) {
            return false;
        }

        return true;
    }

    set allBrands(value: boolean) {
        for (const brand of this.brands) {
            brand.selected = value;
        }
    }

    saving = false;
    async save() {
        if (!this.args.name) {
            this.missionService.showWarningToast('Name required');
            this.setFocus('name');
            return;
        }
        if (!this.args.phoneNumber) {
            this.missionService.showWarningToast('Phone # required');
            this.setFocus('phoneNumber');
            return;
        }

        if (!this.args.email) {
            this.missionService.showWarningToast('Email Required');
            this.setFocus('email');
            return;
        }
        if (!this.args.companyName) {
            this.missionService.showWarningToast('Company Name Required');
            this.setFocus('companyName');
            return;
        }

        this.saving = true;
        this.args.trades = this.trades.filter(i => i.selected);
        this.args.brands = this.brands.filter(i => i.selected);
        if (!this.args.address.address1) {
            this.args.address.address1 = '';
        }
        if (!this.args.address.address2) {
            this.args.address.address2 = '';
        }
        if (!this.args.address.city) {
            this.args.address.city = '';
        }
        if (!this.args.address.state) {
            this.args.address.state = '';
        }
        if (!this.args.address.postalCode) {
            this.args.address.postalCode = '';
        }

        if (!this.args.fullyLicensed) {
            this.args.fullyLicensed = false;
        }
        if (!this.args.hasAutoInsurance) {
            this.args.hasAutoInsurance = false;
        }
        if (!this.args.hasLiabilityInsurance) {
            this.args.hasLiabilityInsurance = false;
        }
        if (!this.args.hasPropertyDamageInsurance) {
            this.args.hasPropertyDamageInsurance = false;
        }
        if (!this.args.hasWorkmansComp) {
            this.args.hasWorkmansComp = false;
        }

        const areas = [];
        for (const area of this.areas) {
            for (const region of area.regions) {
                const selectedTerritories = region.territories.filter(i => i.hasSelected);
                if (selectedTerritories?.length > 0) {
                    region.hasTerritorySelected = true;
                    area.hasTerritorySelected = true;
                    for (const territory of selectedTerritories) {
                        areas.push('[' + region.regionName + '] ' + territory.territoryName);
                    }
                }
            }
        }
        if (this.areas.filter(i => i.hasTerritorySelected).length > 0) {
            this.args.areas = areas;
        }

        this.args.source = this.selectedSource;
        await this.functionApi.runCustomFunction('contractors-apply', null, null, this.args);
        await this.contractorApi.apply(this.args);
        this.saving = false;
        this.selectedIndex = 1;
    }

    setFocus(id: string) {
        document.getElementById(id).focus();
    }
}
