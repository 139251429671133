export class WorkOrderSurveySummaryModel {
    id: string;
    workOrderId: string;
    rating: number;
    contractorId: string;
    comments: string;
    createdDate: Date;
    itemName: string;
    customerName: string;
    number: number;
}