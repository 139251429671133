import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContractorsService } from '@upkeeplabs/service-pros/app/services/contractors.service';
import { ContractorInvoicePaymentSummary } from '@upkeeplabs/service-pros/app/model/contractors/contractor-invoice-payment-summary.model';

@Component({ 
  selector: 'app-view-contractor-statement',
  templateUrl: './view-contractor-statement.component.html',
  styleUrls: ['./view-contractor-statement.component.css']
})
export class ViewContractorStatementComponent implements OnInit {

  html: string;
  defaultEmailAddress: string;
  defaultSubject: string;
  downloadFileName: string;

  constructor(
    public dialogRef: MatDialogRef<ViewContractorStatementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ViewContractorStatementDialogData,
    private contractorRepository: ContractorsService) { }

  ngOnInit() {
    this.defaultEmailAddress = this.data.contractorInvoicePayment.email;
    this.defaultSubject = 'Service Pro Statement';
    this.downloadFileName = 'statement_' + this.data.contractorInvoicePayment.quickbooksId + '.html';
    this.loadStatement();
  }

  async loadStatement() {
    this.html = await this.contractorRepository.getContractorInvoicePaymentStatementHtml(this.data.contractorInvoicePayment.id);

  }

  closeDialog() {
    this.dialogRef.close();
  }

}

export class ViewContractorStatementDialogData {
  constructor(public contractorInvoicePayment: ContractorInvoicePaymentSummary) {

  }
}
