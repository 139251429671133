<mat-form-field class="example-chip-list" appearance="outline">
    <mat-label *ngIf="placeholder">{{placeholder}}</mat-label>
    <mat-chip-grid #chipList>
      <mat-chip-row
        *ngFor="let status of statuses"
        [removable]="removable"
        (removed)="remove(status)">
        {{status}}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip-row>
      <input
        #statusInput
        matInput
        [formControl]="statusCtrl"
        autocomplete="new-password"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="add($event)">
    </mat-chip-grid>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let status of filteredStatuses | async" [value]="status">
        {{status}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>