import { Component, OnInit } from '@angular/core';
import { ContractorsService } from '@upkeeplabs/service-pros/app/services/contractors.service';
import { ContractorKPIModel } from '@upkeeplabs/service-pros/app/model/contractors/contractor-kpi.model';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { ContractorApiService } from '@cogent/client/shared/services/api/contractor-api.service';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
    contractorTrades: any[];
    selectedTradeId: string;
    startDate: Date = UtilitiesService.monthStart(new Date());
    endDate: Date = UtilitiesService.monthEnd(new Date());
    kpis: ContractorKPIModel[];
    showQuerySelection = false;
    showCriteria = false;
    accKpis: ContractorKPIModel[] = [];

    constructor(
        private contractorsService: ContractorsService,
        private dialogService: DialogsService,
        private contractorApi: ContractorApiService,
        private entityApi: EntityApiService
    ) { }

    ngOnInit() {
        this.entityApi.getLoggedInUser().then(user => {
            this.contractorsService.getContractorTrades(user.id).then(contractorTrades => {
                this.contractorTrades = contractorTrades;
                if (this.contractorTrades.length > 0) {
                    this.selectedTradeId = this.contractorTrades[0].tradeId;
                }
                // this.refreshAll();
            });
        });
        setTimeout(() => this.showCriteria = true, 500);
    }

    loading = false;
    refreshAll() {
        this.kpis = null;
        this.loading = true;
        const startDate = new Date(this.startDate);

        this.entityApi.getLoggedInUser().then(user => {

            this.contractorApi.getCostsByContractor(user.id).then(results => {
                this.accKpis = [];
                const today = new Date();
                const nextMonth = new Date(today.setMonth(today.getMonth() + 1));
                let endOfMonth = nextMonth;
                for (let i = 0; i < 6; i++) {
                    endOfMonth = new Date(endOfMonth.setDate(0)); //console.log(endOfMonth.toLocaleDateString());
                    let kpi = new ContractorKPIModel();
                    kpi.reportPeriod = new Date(endOfMonth.toLocaleDateString());
                    kpi.reportMonth = endOfMonth.getMonth() + 1;
                    kpi.reportYear = endOfMonth.getFullYear();

                    const cost = results[0].filter(i => i.month == kpi.reportMonth && i.year == kpi.reportYear)[0];
                    kpi.claimCost = cost ? cost.amount.toFixed(2) : 0;

                    const claim = results[1].filter(i => i.month == kpi.reportMonth && i.year == kpi.reportYear)[0];
                    kpi.claimCount = claim ? claim.claims : 0;

                    this.accKpis.push(kpi);
                }
                this.accKpis = this.accKpis.sort((a, b) => a.reportPeriod < b.reportPeriod ? -1 : 1);
            });

            this.contractorsService.getContractorKPIs(user.id, this.selectedTradeId, startDate, this.endDate).then(kpis => {
                this.kpis = kpis;
                this.loading = false;
            });
        });
    }

    get currentContractorScore() {
        if (!this.kpis || !this.kpis[this.kpis.length - 1]) {
            return null;
        }

        return this.kpis[this.kpis.length - 1].contractorRatingLetter;
    }



}
