export class ContractorInvoice {
    id: string;
    workOrderId: string;
    workPerformed: string;
    partsCost = 0;
    laborCost = 0;
    tax = 0;
    receivedDate: Date;
    contractorInvoiceNumber: string;
    approvedDate: Date;
    completionDate: Date;
    authorizationLimit: number;
    appliedItemsJson: string;

    get amount() {
        return this.partsCost + this.laborCost + this.tax;
    }
    get canSave() {
        return this.tax >= 0
            && this.partsCost >= 0
            && this.laborCost >= 0
            && this.contractorInvoiceNumber
            && this.workPerformed;
    }
}