import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ContractorKPIModel } from '@upkeeplabs/service-pros/app/model/contractors/contractor-kpi.model';
declare var Chart: any;
declare var document: any;

@Component({
  selector: 'app-acc-view',
  templateUrl: './acc-view.component.html',
  styleUrls: ['./acc-view.component.css']
})
export class AccViewComponent implements OnInit, OnChanges {

  @Input() kpis: ContractorKPIModel[];
  accHistoryChart: any;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['kpis'] && changes['kpis'].currentValue) {
     // this.setACCHistoryChart();
    }
  }

  get currentKPIs(): ContractorKPIModel {
    if (!this.kpis) {
      return null;
    }

    return this.kpis[this.kpis.length - 1];
  }

  // setACCHistoryChart() {
  //   const canvas = document.getElementById('acc-history-chart');
  //   if (!canvas) {
  //     setTimeout(() => this.setACCHistoryChart(), 500);
  //     return;
  //   }

    
  //   const backgroundColors = [
  //     'rgba(184, 89, 149, .3)',
  //     'rgba(110,124,185, .6)',
  //     'rgba(184,52,45, .3)'
  //   ];
  //   const borderColors = [
  //     'rgba(184, 89, 149, .5)',
  //     'rgba(110,124,185, 1)',
  //     'rgba(184,52,45, 1)'
  //   ];

  //   const MONTHS = [
  //     'Jan',
  //     'Feb',
  //     'Mar',
  //     'Apr',
  //     'May',
  //     'Jun',
  //     'Jul',
  //     'Aug',
  //     'Sep',
  //     'Oct',
  //     'Nov',
  //     'Dec'
  //   ];
  //   const config = {
  //     type: 'bar',
  //     data: {
  //       labels:
  //         this.kpis.map(i => MONTHS[i.reportMonth - 1] + ' ' + i.reportYear),
  //       datasets: [
  //         {
  //           data: this.kpis.map(i => i.averageClaimCost),
  //           backgroundColor: backgroundColors[1],
  //           borderColor: borderColors[1],
  //           label: 'Actual',
  //         },
  //         {
  //           data: this.kpis.map(i=> i.accTarget),
  //           borderColor: borderColors[0],
  //           fill: false,
  //           type: 'line',
  //           label: 'Target'
  //         }
  //       ]
  //     },
  //     options: {
  //       responsive: true,
  //       legend: {
  //         position: 'bottom',
  //         display: true,
  //       },
  //       title: { display: false, text: 'Chart.js Line Chart' },
  //       tooltips: {
  //         mode: 'index',
  //         intersect: false,
  //       },
  //       hover: { mode: 'nearest', intersect: true },
  //       scales: {
  //         xAxes: [
  //           {
  //             display: true,
  //             scaleLabel: { display: true, labelString: 'Month' },
  //             gridLines: { display: false }
  //           }
  //         ],
  //         yAxes: [
  //           {
  //             display: true,
  //             scaleLabel:
  //               { display: true, labelString: 'Average Claim Cost' }
  //           }
  //         ]
  //       }
  //     }
  //   };

  //   const ctx = canvas.getContext('2d');
  //   if (this.accHistoryChart) {
  //     this.accHistoryChart.destroy();
  //   }
  //   this.accHistoryChart = new Chart(ctx, config);
  // }


}
