<mat-card *ngIf="currentKPIs">
    <div style="position: relative">
      <mat-card-header>
        <mat-card-title>
          <i class="material-icons" mat-card-avatar>replay</i> Recall %
        </mat-card-title>
      </mat-card-header>
    </div>
    <mat-card-content>
      <div>
        <div style="text-align: center;">
          <app-circle-widget [percent]="currentKPIs.recallPercent * 100" label="{{currentKPIs.reportPeriod | date: 'MMMM y'}} recall %"
            number="{{currentKPIs.recallPercent | percent: '1.0-1'}}"></app-circle-widget>
        </div>
        <div>
          <canvas topmargin30 id="recall-history-chart"></canvas>
        </div>
      </div>
    </mat-card-content>
  </mat-card>