export class SearchResultModel {
    constructor(public name = '',
        public id = '',
        public recordType = '',
        public recordSubType = '',
        public email = '',
        public address1 = '',
        public city = '',
        public state = '',
        public postalCode = '',
        public inative = '',
        public homeNumber = '',
        public mobileNumber = '',
        public number = 0,
        public lastModifiedDate = new Date(),
        public deletedDate?: Date,
        public inactiveDate?: Date,
        public additionalInfo = null) { }


    get url(): string {
        if (this.recordType === 'WorkOrder') {
            return `/work-order-detail/${this.id}`;
        }
    }

    get icon(): string {
        if (this.recordType === 'Policy') {
            return 'folder';
        }
        if (this.recordType === 'Entity') {
            return 'person';
        }
        if (this.recordType === 'WorkOrder') {
            return 'build';
        }
        if (this.recordType === 'MenuItem') {
            return this.recordSubType;
        }
    }

    get title(): string {
        if (this.recordType === 'Policy') {
            return 'PO# ' + this.number + ' - ' + this.name;
        }

        if (this.recordType === 'WorkOrder') {
            return 'WO# ' + this.number + ' - ' + this.additionalInfo;
        }

        return this.name;
    }

    get description(): string {
        if (this.recordType === 'Entity') {
            return this.email;
        }
        if (this.recordType === 'MenuItem') {
            return this.email;
        }

        return this.address1 + ' ' + this.city + ', ' + this.state + ' ' + this.postalCode;
    }
}