import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
// import { UserSessionService } from '@upkeeplabs/service-pros/app/services/user-session.service';
import { ContractorsService } from '@upkeeplabs/service-pros/app/services/contractors.service';
import { MaintenanceServiceApiService } from '@cogent/client/shared/services/api/maintenance-services-api.service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { MaintenanceServiceContractorScores, MaintenanceServiceCustomerPropertyServiceAppointmentSummary } from '@upkeeplabs/models/cogent';
import { ApiService } from '@cogent/client/api';
declare var Chart: any;
declare var document: any;

@Component({
    selector: 'app-maint-services-report-dashboard',
    templateUrl: './maint-services-report-dashboard.component.html',
    styleUrls: ['./maint-services-report-dashboard.component.css']
})
export class MaintServicesReportDashboardComponent implements OnInit {

    //@Input() kpis: ContractorKPIModel[];
    @Input() selectedTradeId: string;
    hideShowMoreSurvyes = false;
    surveyHistoryChart: any;
    loadingSurveys = false;
    surveys: MaintenanceServiceCustomerPropertyServiceAppointmentSummary[];

    startDate: Date;
    endDate: Date;
    scores: MaintenanceServiceContractorScores[];

    constructor(
        // private userSession: UserSessionService,
        private entityApi: EntityApiService,
        private maintApi: MaintenanceServiceApiService,
        private contractorRepository: ContractorsService
    ) { }

    ngOnInit() {
        this.startDate = UtilitiesService.monthStart(new Date());
        this.startDate.setFullYear(this.startDate.getFullYear() - 1);
        this.endDate = UtilitiesService.monthEnd(new Date());

        this.entityApi.getLoggedInUser().then(user => {
            this.maintApi.getContractorSurveyScores(user.id, this.startDate, this.endDate).then(scores => {
                this.scores = scores;
                for (const score of scores) {
                    score.reportDate.setDate(score.reportDate.getDate() + 1);
                }
                this.setSurveyChartHistory();
            });
        });
        this.refreshSurveys();
    }

    ngOnChanges(changes: SimpleChanges) {

    }

    private refreshSurveys() {
        this.entityApi.getLoggedInUser().then(user => {
            this.maintApi.getSurveys(user.id, null, 5)
                .then(surveys => {
                    this.surveys = surveys;
                });
        });
    }

    getResponseIcon(response: MaintenanceServiceCustomerPropertyServiceAppointmentSummary) {
        return `${ApiService.endPointNode}scheduled-maintenance/item-photo/${response.maintenanceServiceOfferingId}`;
    }


    // get currentKPIs(): ContractorKPIModel {
    //     if (!this.kpis) {
    //         return null;
    //     }

    //     return this.kpis[this.kpis.length - 1];
    // }

    loadMoreSurveys() {
        this.loadingSurveys = true;
        let startDate: Date = null;
        if (this.surveys && this.surveys.length > 0) {
            startDate = this.surveys[this.surveys.length - 1].surveyDate;
        }
        this.entityApi.getLoggedInUser().then(user => {
            this.maintApi.getSurveys(user.id, startDate)
                .then(surveys => {
                    this.loadingSurveys = false;
                    if (surveys.length < 10) {
                        this.hideShowMoreSurvyes = true;
                    }
                    surveys.forEach(element => { this.surveys.push(element); });
                });
        });
    }

    setSurveyChartHistory() {
        const canvas = document.getElementById('survey-history-chart');
        if (!canvas) {
            setTimeout(() => this.setSurveyChartHistory(), 500);
            return;
        }

        const MONTHS = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
        ];
        const config = {
            type: 'line',
            data: {
                labels:
                    this.scores.map(i => MONTHS[i.reportMonth - 1] + ' ' + i.reportYear),
                datasets: [
                    {
                        data: this.scores.map(i => i.averageRating),
                        fill: false,
                    },
                ]
            },
            options: {
                responsive: true,
                legend: {
                    position: 'right',
                    display: false,
                },
                title: { display: false, text: 'Chart.js Line Chart' },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                hover: { mode: 'nearest', intersect: true },
                scales: {
                    xAxes: [
                        {
                            display: true,
                            scaleLabel: { display: true, labelString: 'Month' },
                            gridLines: { display: false }
                        }
                    ],
                    yAxes: [
                        {
                            display: true,
                            scaleLabel: { display: true, labelString: 'Rating' }
                        }
                    ]
                }
            }
        };

        const ctx = canvas.getContext('2d');
        if (this.surveyHistoryChart) {
            this.surveyHistoryChart.destroy();
        }
        this.surveyHistoryChart = new Chart(ctx, config);
    }

}
