import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ContractorKPIModel } from '@upkeeplabs/service-pros/app/model/contractors/contractor-kpi.model';
declare var Chart: any;
declare var document: any; 

@Component({
  selector: 'app-recall-view',
  templateUrl: './recall-view.component.html',
  styleUrls: ['./recall-view.component.css']
})
export class RecallViewComponent implements OnInit, OnChanges {
  @Input() kpis: ContractorKPIModel[];
  recallHistoryChart: any;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['kpis'] && changes['kpis'].currentValue) {
      this.setRecallHistoryChart();
    }
  }

  get currentKPIs(): ContractorKPIModel {
    if (!this.kpis) {
      return null;
    }

    return this.kpis[this.kpis.length - 1];
  }

  setRecallHistoryChart() {
    const canvas = document.getElementById('recall-history-chart');
    if (!canvas) {
      setTimeout(() => this.setRecallHistoryChart(), 500);
      return;
    }

    const MONTHS = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];

    const backgroundColors = [
      'rgba(184, 89, 149, .05)',
      'rgba(110,124,185, .1)',
      'rgba(184,52,45, .3)'
    ];
    const borderColors = [
      'rgba(184, 89, 149, .3)',
      'rgba(110,124,185, .5)',
      'rgba(184,52,45, 1)'
    ];
    const config = {
      type: 'line',
      data: {
        labels:
          this.kpis.map(i => MONTHS[i.reportMonth - 1] + ' ' + i.reportYear),
        datasets: [
          {
            data: this.kpis.map(i => i.recallPercent * 100),
            backgroundColor: backgroundColors[0],
            borderColor: borderColors[0]
          },
        ]
      },
      options: {
        responsive: true,
        legend: {
          position: 'right',
          display: false,
        },
        title: { display: false, text: 'Chart.js Line Chart' },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        hover: { mode: 'nearest', intersect: true },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: { display: true, labelString: 'Month' },
              gridLines: { display: false }
            }
          ],
          yAxes: [
            { display: true, scaleLabel: { display: true, labelString: '%' } }
          ]
        }
      }
    };

    const ctx = canvas.getContext('2d');
    if (this.recallHistoryChart) {
      this.recallHistoryChart.destroy();
    }

    this.recallHistoryChart = new Chart(ctx, config);
  }



}
