import { Component, OnInit, ViewChild } from '@angular/core';
import { MaintenanceServiceApiService } from '@cogent/client/shared/services/api/maintenance-services-api.service';
import { EntityDocumentsComponent } from '@upkeeplabs/service-pros/app/parts/entity-documents/entity-documents.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { LoginApiService } from '@cogent/client/shared/services/api/login-api.service';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { SwapableImageComponent } from '@cogent/client/shared/components/misc/swapable-image/swapable-image.component';
import { UtilityDialogService } from '@cogent/client/shared/services/utility-dialog.service';
import { ContractorContactsComponent} from '@cogent/client/shared/components/contractors/contractor-contacts/contractor-contacts.component';
import { Login } from '@cogent/shared/models/user/login.model';
import { Entity, EntityACH } from '@upkeeplabs/models/cogent';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";

@Component({
    selector: 'app-contractor-settings',
    templateUrl: './contractor-settings.component.html',
    styleUrls: ['./contractor-settings.component.css']
})
export class ContractorSettingsComponent implements OnInit {

    saving = false;
    contractor: Entity;
    entityACH: EntityACH;
    newRoutingNumber: string;
    newAccountNumber: string;
    editingACH = false;
    canEntitySave = false;
    employees: Entity[];
    selectedEmployee: Entity;
    url: any = {};
    savingTechnician = false;
    time = new Date().getTime();
    imageUrl: string;
    newLogin: Login = new Login();
    creatingLogin = false;
    login: Login;
    deleting = false;
    newTechMode = false;
    isMaintEnabled = false;
    maintVisible = false;

    @ViewChild('contractorContacts') contractorContacts: ContractorContactsComponent;

    @ViewChild('swapableImage') swapableImage: SwapableImageComponent;

    @ViewChild('entityDocuments') entityDocuments: EntityDocumentsComponent;

    constructor(private entityApi: EntityApiService,
        private dialog: MatDialog,
        private dialogService: DialogsService,
        private missionService: MissionService,
        private loginApi: LoginApiService,
        private maintApi: MaintenanceServiceApiService,
        private utilityDialog: UtilityDialogService,
        private snackBar: MatSnackBar) { }

    ngOnInit() {
        this.loadScreen();
        this.maintApi.maintServicesAvailable().then(available => {
            this.isMaintEnabled = available;
            this.maintVisible = this.missionService.stateValues['isMaintContractor'];
        });


    }

    async loadScreen() {
        this.contractor = await this.entityApi.getLoggedInUser(true);
        this.imageUrl = this.entityApi.getImageUrl(this.contractor.id);
        this.loadTechnicians();
        this.loadACHInfo();
    }

    get isMaintContractor() {
        return this.missionService.stateValues['isMaintContractor'];
    }

    async changeMaintVisible($event) {
        this.missionService.stateValues['isMaintContractor'] = $event;
        this.missionService.publish({
            messageBody: $event,
            type: 'CHANGE-IS-MAINT-CONTRACTOR'
        });

        await this.maintApi.toggleMaintenanceServiceView(this.contractor.id, $event);
        this.contractor.isMaintenanceServiceContractor = $event;
    }

    loadTechnicians() {
        this.entityApi.getContractorTechnicians(this.contractor.id).then(technicians => this.employees = technicians);
    }

    getEntityThumbnailUrl(entityId: string) {
        return `${this.entityApi.getThumbnailUri(entityId)}?tick=${this.time}`;
    }

    async selectEmployee(employee: Entity) {
        this.selectedEmployee = null;
        this.selectedEmployee = await this.entityApi.getFullEntity(employee.id);
        this.url[employee.id] = this.getEntityThumbnailUrl(employee.id);
        if (!this.contractorContacts.accessedTechnicians.find(i => i.id === this.selectedEmployee.id)) {
            this.contractorContacts.accessedTechnicians.push(this.selectedEmployee);
        }
        this.login = null;
        this.newLogin = new Login();
        this.newLogin.userName = this.selectedEmployee.email;
        this.loginApi.getEntityLogin(this.selectedEmployee.loginId).then(login => {
            this.login = login;
        });
        this.newTechMode = false;
    }


    async loadACHInfo() {
        this.entityACH = await this.entityApi.getContractorACHPaymentInfo(this.contractor.id);
        if (!this.entityACH) {
            this.entityACH = new EntityACH();
            this.entityACH.id = UtilitiesService.newid();
            this.entityACH.entityId = this.contractor.id;
        }
    }

    get canSave() {
        if (!this.canEntitySave) {
            return false;
        }

        if (this.newAccountNumber) {
            if (!this.newRoutingNumber || this.newRoutingNumber.length !== 9) {
                return false;
            }
        }

        if (this.newRoutingNumber) {
            if (!this.newAccountNumber) {
                return false;
            }
        }

        return true;
    }

    addNewUser() {
        const employee = new Entity();

        employee.type = 'Technician';
        employee.parentId = this.contractor.id;
        employee.id = UtilitiesService.newid();
        this.selectedEmployee = employee;

        this.employees.push(employee);
        // this.url = this.entityApi.getThumbnailUri(employee.id);
        this.contractorContacts.accessedTechnicians.push(employee);
        this.newTechMode = true;
        this.login = null;
    }

    deleteTechnician() {
        const deletedEmployee = this.selectedEmployee;
        const employeeInList = this.employees.filter(i => i.id === deletedEmployee.id)[0];
        const employeeIndex = this.employees.indexOf(employeeInList);
        this.contractorContacts.deletedTechnicians.push(this.selectedEmployee);
        this.selectedEmployee = null;
        this.employees.splice(employeeIndex, 1);
        if (this.contractorContacts.accessedTechnicians.indexOf(deletedEmployee) > -1) {
            this.contractorContacts.accessedTechnicians.splice(this.contractorContacts.accessedTechnicians.indexOf(deletedEmployee), 1);
        }
    }

    get canCreateLogin() {

        return (this.newLogin.userName && this.newLogin.passwordHash && this.selectedEmployee.firstName && this.selectedEmployee.lastName);
    }

    async changePassword() {
        const result = await this.utilityDialog.showChangePasswordDialog(this.selectedEmployee.id).toPromise();

        if (result) {
            this.missionService.showSuccessToast('Password has been changed');
        }
    }

    private async saveTechnicians() {
        const promises = [];

        for (const technician of this.contractorContacts.accessedTechnicians) {
            technician.name = `${technician.firstName} ${technician.lastName}`;
            promises.push(this.entityApi.updateEntity(technician, false));
            const url = this.url[technician.id];
            if (url && url.indexOf('data:image') > -1) {
                const index = url.indexOf(',');
                const justBase64 = url.substring(index + 1, url.length);
                promises.push(this.entityApi.updateEntityPhoto(this.selectedEmployee.id, justBase64, this.swapableImage.imageType));
            }
        }
        for (const technician of this.contractorContacts.deletedTechnicians) {
            promises.push(this.entityApi.deleteEntity(technician));
        }

        await Promise.all(promises);
        this.contractorContacts.accessedTechnicians = [];
        this.contractorContacts.deletedTechnicians = [];
    }

    async createLogin() {
        this.creatingLogin = true;
        await this.saveTechnicians();
        this.newLogin.id = UtilitiesService.newid();
        this.newLogin.entityType = 'Technician';

        this.loginApi.loginExists(this.newLogin.userName, this.newLogin.entityType).then(exists => {
            if (exists) {
                this.creatingLogin = false;
                this.dialogService.alert('User Exists', 'Sorry, that user name already exists.');
            } else {
                this.newLogin.entityId = this.selectedEmployee.id;

                this.loginApi.saveLogin(this.newLogin).then(login => {
                    this.login = JSON.parse(JSON.stringify(this.newLogin));
                    this.loginApi.updateEntityLogin(this.selectedEmployee.id, this.login.id).then(() => {
                        this.newLogin = new Login();
                        this.creatingLogin = false;
                    });
                });
            }
        });
    }

    async deleteLogin() {
        const login = this.login;
        await this.loginApi.deleteLogin(this.login.id);
        this.login = null;
    }

    async deleteACHInfo() {
        const achInfo = this.entityACH;
        await this.entityApi.deleteContractorACHPaymentInfo(this.entityACH.id);
        this.entityACH = new EntityACH();
        this.entityACH.id = UtilitiesService.newid();
        this.entityACH.entityId = this.contractor.id;
        const ref = this.snackBar.open('ACH Payment Information Removed', 'Undo', { duration: 10000 });
        ref.onAction().subscribe(async action => {
            await this.entityApi.unDeleteContractorACHPaymentInfo(achInfo.id);
            this.entityACH = achInfo;
        });
    }

    async save() {
        this.saving = true;
        await this.entityApi.updateEntity(this.contractor, false);
        await this.entityDocuments.save();

        if (this.newAccountNumber && this.newRoutingNumber) {
            this.entityACH.accountNumber = this.newAccountNumber;
            this.entityACH.routingNumber = this.newRoutingNumber;
            await this.entityApi.saveContractorACHPaymentInfo(this.entityACH);
        }

        this.contractorContacts.selected = null;
        const promises = [];
        for (const technician of this.contractorContacts.accessedTechnicians) {
            technician.name = `${technician.firstName} ${technician.lastName}`;
            promises.push(this.entityApi.updateEntity(technician, false));
            const url = this.url[technician.id];
            if (url && url.indexOf('data:image') > -1) {
                const index = url.indexOf(',');
                const justBase64 = url.substring(index + 1, url.length);
                promises.push(this.entityApi.updateEntityPhoto(this.selectedEmployee.id, justBase64, this.swapableImage.imageType));
            }
        }
        for (const technician of this.contractorContacts.deletedTechnicians) {
            promises.push(this.entityApi.deleteEntity(technician));
        }

        if (this.imageUrl != null && this.imageUrl.startsWith('data:image')) {
            const index = this.imageUrl.indexOf(',');
            const justBase64 =
                this.imageUrl.substring(index + 1, this.imageUrl.length);
            promises.push(
                this.entityApi.updateEntityPhoto(
                    this.contractor.id, justBase64,
                    this.swapableImage.imageType));
        }

        await Promise.all(promises);

        this.saving = false;
        await this.loadACHInfo();
        this.editingACH = false;
        this.newAccountNumber = '';
        this.newRoutingNumber = '';
        this.missionService.showSuccessToast('Settings Saved');

        this.contractorContacts.load();
        this.contractorContacts.time = new Date().getTime();
        this.contractorContacts.accessedTechnicians = [];
        this.contractorContacts.deletedTechnicians = [];
    }

    showChangePassword() {
        const ref = this.dialog.open(ChangePasswordComponent);
        ref.afterClosed().subscribe(results => {
            if (results) {
                this.missionService.showSuccessToast('Your password has been changed');
            }
        });
    }

}
