import { Component, HostListener, AfterViewInit, NgZone, Inject } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { ScrollAnimationService } from './services/scroll-animation.service';
import { Observable, merge, fromEvent, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { ApiService } from '@cogent/client/api';
import { IndexedDbWrapperService } from '@cogent/client/shared/services/indexed-db-wrapper.service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { UserNotificationApiService } from '@cogent/client/shared/services/api/user-notification-api.service';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { ToastSuccessComponent } from '@cogent/client/shared/components/toast-success/toast-success.component';
import { MissionService, ShellMenuItemModel } from '@cogent/client/shared/services/mission-service';
import { UndoArguments } from '@cogent/client/shared/services/mission-service-args';
import { setupLazyComponents } from './common/functions/lazy-component.setup'
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { ToastWarningComponent } from '@cogent/client/shared/components/misc/toast-warning/toast-warning.component';
import { MatDialog } from '@angular/material/dialog';
import { MaintServicesOpenCustomerComponent } from './pages/maint-services-open-customer/maint-services-open-customer.component';
import { EntityCommunicationSummary, UserNotification } from '@upkeeplabs/models/cogent';
import { ApiFailureComponent } from '@cogent/client/shared/components/api-failure/api-failure.component';
import { AuthService } from '@cogent/client/auth';
declare var ga: any;
 
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
    isScrolled = false;
    isHome = false;
    isLogin = false;
    isPolicyView = false;
    isContractorHome = false;
    sideNavOpen = false;
    isBilling = false;
    isPartsOrder = false;
    breadcrumbs: any[];
    allowMaintenanceServiceToggle = false;

    userNotifications: UserNotification[] = [];
    handledMessages: UserNotification[] = [];
    promotedUserNotifications: UserNotification[] = [];
    isMaintContractor = false;

    contractorPortalMenuItems: ShellMenuItemModel[] = [
        new ShellMenuItemModel('Overview', null, null, '/'),
        new ShellMenuItemModel('Jobs Report', null, null, '/jobs/report'),
        new ShellMenuItemModel('Jobs Board', null, null, '/jobs'),
        new ShellMenuItemModel('Billing', null, null, '/billing'),
        new ShellMenuItemModel('Statistics', null, null, '/reports'),
        new ShellMenuItemModel('Calendar', null, null, '/calendar'),
        new ShellMenuItemModel('Settings', null, null, '/settings'),
        new ShellMenuItemModel('Log Out', null, null, '/auth/log-out'),
    ];
    overrideMenuItems: ShellMenuItemModel[];
    enteringAnimation = false;
    online: Observable<boolean>;
    isOnline = true;
    isContractorPortal = false;
    currentRoute: string;
    hideMenuBar = false;
    hideMenuOptions = false;

    isLoggedIn = false;
    incompleteCount = 0;
    url: string;




    constructor(
        private router: Router,
        private scrollAnimation: ScrollAnimationService,
        private zone: NgZone,
        private activatedRoute: ActivatedRoute,
        private missionService: MissionService,
        private entityApi: EntityApiService,
        private indexedDbService: IndexedDbWrapperService,
        private serviceApi: ServiceApiService,
        private dialog: MatDialog,
        private auth: AuthService,
        private userNotificationApi: UserNotificationApiService,
        private snackBar: MatSnackBar, @Inject(DOCUMENT) private htmlDocument: HTMLDocument) {


        this.isLogin = document.location.pathname === '/security/login';
        this.serviceApi.getContractorPortalEnabled().then(enabled => this.hideMenuOptions = !enabled);
        this.entityApi.getLoggedInUser(false).then(user => {
            if (user) {
                this.isLoggedIn = true;
                this.isMaintContractor = user.isMaintenanceServiceContractor;
     
                this.missionService.stateValues['isMaintContractor'] = this.isMaintContractor;


                this.entityApi.getUserIsPotentialMaintenanceServiceContractor(user.id).then(value => {
                    if (value) {
                        this.allowMaintenanceServiceToggle = value.isContractor;
                        this.missionService.stateValues['allowMaintToggle'] = this.allowMaintenanceServiceToggle;
                    }
                });
            } else {
                this.isLoggedIn = false;
            }
        });
        missionService.overrideMenuItems.subscribe(
            menuItems => {
                this.overrideMenuItems = menuItems;
            });

        // missionService.pageMenuItems.subscribe(items => this.pageMenuItems = items);

        window.addEventListener('beforeinstallprompt', e => {
        });


        missionService.toast.subscribe(message => {
            this.snackBar.open(message, null, { duration: 10000 });
        });

        missionService.subscription.subscribe(message => {
            if (message.type === 'HIDE-MENU-BAR') {
                this.hideMenuBar = true;
            }

            if (message.type === 'UPDATE-INCOMPLETE-AUTHO-COUNT') {
                this.refreshIncompleteAuthoCount();
            }
            if (message.type === 'CHANGE-IS-MAINT-CONTRACTOR') {
                this.isMaintContractor = message.messageBody;
            }
        });



        missionService.toastSuccess.subscribe(message => {
            this.snackBar.openFromComponent(
                ToastSuccessComponent,
                { data: message, duration: 400000, panelClass: ['success-toast'] });
        });

        missionService.toastWarning.subscribe(message => {
            this.snackBar.openFromComponent(ToastWarningComponent, { data: message, duration: 4000, panelClass: 'warning-toast' })
        })

        missionService.apiError.subscribe(parameters => {
            if (this.isOnline) {
                this.snackBar.openFromComponent(
                    ApiFailureComponent,
                    { duration: 10000, data: parameters, panelClass: ['failed-toast'] });
            }
        });

        missionService.undo.subscribe(undoArgs => {
            this.snackBar.open(undoArgs.message, 'UNDO', { duration: undoArgs.timeout })
                .onAction()
                .subscribe(() => { undoArgs.callback(); });
        });

        missionService.userNotificationReceived.subscribe(notification => {
            if (this.userNotifications.find(n => n.id === notification.id)) {
                notification.handled = true;
            }
            if (!notification.handled) {

                this.userNotifications.push(notification);

                const foundMessage = this.handledMessages.find(i => i.id === notification.id);

                if (!foundMessage) {
                    //channel.postMessage(notification);
                    missionService.showDesktopNotification('Message Received', notification.description, () => {

                        if (notification.url) {
                            this.router.navigateByUrl(notification.url);
                        }
                    }, '/assets/images/fav-icon.png');
                }

                setTimeout(() => {
                    if (notification.handled) {
                        this.promotedUserNotifications = [];
                    }
                }, 100);
            }
        });
        this.doNotificationsCheck();
        this.online =
            merge(of(navigator.onLine),
                fromEvent(window, 'online').pipe(map(() => true)),
                fromEvent(window, 'offline').pipe(map(() => false)));

        this.online.subscribe(value => { this.isOnline = value; });

        router.events.subscribe(val => {
            if (val instanceof NavigationStart) {
                const navEnd: NavigationStart = val;

                if (navEnd.navigationTrigger === 'imperative') {
                    this.overrideMenuItems = [];
                }

            }

            if (!this.url) {
                this.url = '';
            }
            this.url += '<br>' + val.toString();
            // document.getElementById('debug').innerHTML = this.url;

            if (val instanceof NavigationEnd) {
                // this.missionService.showMenuItems([]);
                this.entityApi.getLoggedInUser(false).then(user => {
                    if (user) {
                        if (!this.isLoggedIn) {
                            this.entityApi.getUserIsPotentialMaintenanceServiceContractor(user.id).then(value => {
                                if (value) {
                                    this.allowMaintenanceServiceToggle = value.isContractor;
                                    this.missionService.stateValues['allowMaintToggle'] = this.allowMaintenanceServiceToggle;
                                    if(this.allowMaintenanceServiceToggle) {
                                        if(localStorage.getItem('maint-service-view')) {
                                            this.updateIsMaintContactor(localStorage.getItem('maint-service-view') === 'true');
                                        }                
                                    }
                                }
                            });
                        }
                        this.isLoggedIn = true;
                        if (this.missionService.stateValues['isMaintContractor'] === undefined) {

                            this.isMaintContractor = user.isMaintenanceServiceContractor;
                            this.missionService.stateValues['isMaintContractor'] = this.isMaintContractor;
                        } else {
                            this.isMaintContractor = this.missionService.stateValues['isMaintContractor'];
                        }


                    } else {
                        this.isLoggedIn = false;
                    }
                });
                window.scrollTo(0, 0);

                const nav = val as NavigationEnd;

                this.isHome = nav.url.indexOf('/homeowner') > -1;
                this.isLogin = nav.url === '/security/login' || nav.urlAfterRedirects === '/security/login';
                this.isContractorHome = nav.url === '/';

                this.isPartsOrder = nav.url.indexOf('/order-parts/') === 0;

                //this.url = nav.url;
                this.isContractorPortal = nav.url.indexOf('/contractors') > -1 || nav.url.indexOf('work-order-detail') > -1;
                // this.isBilling = nav.url === '/billing';
                this.currentRoute = nav.url;

                this.isPolicyView = nav.url.indexOf('/view-property/') > -1;

                this.breadcrumbs = [];
                let currentRoute = this.activatedRoute.root;
                let url = '';

                do {
                    const childrenRoutes = currentRoute.children;
                    currentRoute = null;
                    childrenRoutes.forEach(r => {
                        if (r.outlet === 'primary') {
                            const routeSnapshot = r.snapshot;
                            url += '/' + routeSnapshot.url.map(segment => segment.path).join('/');
                            this.breadcrumbs.push({ label: r.snapshot.data, url });
                            currentRoute = r;
                        }
                    });
                } while (currentRoute);

                if (this.breadcrumbs && this.breadcrumbs.length > 0) {
                    const breadCrumb = this.breadcrumbs[this.breadcrumbs.length - 1];

                    if (breadCrumb && breadCrumb.label && breadCrumb.label.title) {
                        document.title = `${UtilitiesService.applicationName} - ${breadCrumb.label.title}`;
                    } else {
                        document.title = UtilitiesService.applicationName;
                    }
                }

                if (this.isHome) {
                    document.body.className = 'bg-image';
                } else {
                    document.body.className = 'white-background';
                }

                if (nav && nav.url && nav.url.indexOf('#') === -1) {
                    this.enteringAnimation = true;
                }

                setTimeout(() => this.enteringAnimation = false, 750);
                ga('set', 'page', val.urlAfterRedirects);
                ga('send', 'pageview');
            }
        });
        const favIconUrl = `${ApiService.endPointDotNet}Entity/Company/fav-icon`;
        this.htmlDocument.getElementById('fav-icon-link').setAttribute('href', favIconUrl);

        setupLazyComponents();
    }

    updateIsMaintContactor(e: boolean) {
        this.isMaintContractor = e;
        localStorage.setItem('maint-service-view', e ? 'true': 'false');
        this.missionService.stateValues['isMaintContractor'] = e;
        this.missionService.publish({ messageBody: e, type: '' });
    }

    ngAfterViewInit() {
        setTimeout(() => {

            this.setActiveMenuItem();
        }, 500);
        const splash = document.getElementById('splashScreen');
        splash.classList.add('remove');
        setTimeout(() => {
            splash.parentElement.removeChild(splash);
        }, 1000);
        this.refreshIncompleteAuthoCount();
    }

    createNewSMS() {
        const ref = this.dialog.open(MaintServicesOpenCustomerComponent);
        ref.afterClosed().subscribe(async results => {
            if (results) {
                const com = new EntityCommunicationSummary();
                const user = await this.entityApi.getLoggedInUser();

                com.entityId = user.id;
                com.remoteEndpoint = UtilitiesService.stripPhoneFormatting(results.customerPhoneNumber);
                com.id = UtilitiesService.newid();
                com.remoteEntityId = results.customerId;
                com.remoteName = results.name;

                this.missionService.raiseReceivedBroadcast({
                    message: 'StartSMSConversation',
                    data: com,
                });
            }
        });
    }

    refreshIncompleteAuthoCount() {
        this.indexedDbService.getAll().then(items => {
            this.incompleteCount = items.length;
        });
    }

    async clearNotifications() {
        const promises = [];
        for (const notification of this.userNotifications) {
            notification.wasRead = true;
            promises.push(this.userNotificationApi.updateUserNotification(notification));
        }
        await Promise.all(promises);
        this.userNotifications = [];
    }


    async removeNotificationItem(notification: UserNotification, event: any) {
        // event.stopPropagation();
        // notification.deleting = true;
        // setTimeout(() => {
        //     const index = this.userNotifications.indexOf(notification);
        //     this.userNotifications.splice(index, 1);
        //     notification.deleting = false;
        //     this.missionService.showUndo(new UndoArguments(() => {
        //         this.userNotifications.splice(index, 0, notification);
        //     }, 'Notification Deleted'));
        // }, 300);
        event.stopPropagation();
        notification.wasRead = true;
        await this.userNotificationApi.updateUserNotification(notification);
        notification.deleting = true;

        setTimeout(() => {
            const index = this.userNotifications.indexOf(notification);
            this.userNotifications.splice(index, 1);
            notification.deleting = false;
            this.missionService.showUndo(new UndoArguments(async () => {
                notification.wasRead = false;
                await this.userNotificationApi.updateUserNotification(notification);
                this.userNotifications.splice(index, 0, notification);
            }, 'Notification Deleted'));
        }, 300);
    }

    get isScrolledCalc() {
        return this.isScrolled && !this.isPartsOrder;
    }

    private doNotificationsCheck() {
        // if (!this.loggedInUser) {
        //     return;
        // }

        this.userNotificationApi.getNewNotifications().then(notifications => {
            if (notifications) {
                notifications.forEach(notification => {
                    this.userNotifications.push(notification);
                    this.missionService.raiseUserNotificationReceived(notification);
                });
            }


            // if (this.loggedInUser && this.loggedInUser.settingsModel &&
            //     this.loggedInUser.settingsModel.notifyOnNewMessages) {
            if (notifications && notifications.length > 0) {
                // Don't want more than 3 showing
                if (notifications.length < 4) {
                    this.promotedUserNotifications = notifications;
                } else {
                    this.promotedUserNotifications = [];
                    for (let i = 0; i < 3; i++) {
                        this.promotedUserNotifications.push(notifications[i]);
                    }
                }
                setTimeout(() => this.promotedUserNotifications = [], 20000);
            }
            // }
        });
    }

    get menuItems(): ShellMenuItemModel[] {
        if (!this.overrideMenuItems || this.overrideMenuItems.length === 0) {
            return this.defaultMenuItems;
        }

        return this.overrideMenuItems;
    }

    get defaultMenuItems(): ShellMenuItemModel[] {
        return this.contractorPortalMenuItems;
    }

    get homeUrl() {
        return "/";
    }

    scrollToFragment(fragment: string, $event) {
        $event.cancelBubble = true;
        this.scrollAnimation.scrollToElement(fragment);
    }

    toggleSideNav() {
        this.sideNavOpen = !this.sideNavOpen;
    }

    isInViewport(elem) {
        const bounding = elem.getBoundingClientRect();
        return (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    isScrolledIntoView() {

    }

    setActiveMenuItem() {
        const fragmentMenuItems = this.menuItems.filter(i => i.documentFragment);

        fragmentMenuItems.forEach(i => {
            const element = document.getElementById(i.documentFragment.replace('#', ''));
            if (element && this.isInViewport(element)) {
                i.active = true;
                fragmentMenuItems.filter(ni => ni !== i).forEach(ni => ni.active = false);
                return;
            }
        });
    }


    doMenuItem(menuItem: ShellMenuItemModel, event) {
        this.sideNavOpen = false;
        if (menuItem.url) {
            this.router.navigateByUrl(menuItem.url);
        } else if (menuItem.documentFragment) {
            this.scrollToFragment(menuItem.documentFragment, event);
        }
        else {
            menuItem.action();
        }
    }

    get companyUrl() {
        return ApiService.endPointDotNet + 'entity/company/logo';
    }

    get companyWhiteUrl() {
        return ApiService.endPointDotNet + 'entity/company/logo/white';
    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        this.zone.run(() => {
            this.isScrolled = window.scrollY > 0;
            this.setActiveMenuItem();
        });
    }
}
