import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { UntypedFormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { DataApiService } from '@cogent/client/shared/services/api/data-api.service';
import { Trade } from '@upkeeplabs/models/cogent';

@Component({
    selector: 'app-trade-chip-selection',
    templateUrl: './trade-chip-selection.component.html',
    styleUrls: ['./trade-chip-selection.component.css']
})
export class TradeChipSelectionComponent implements OnInit {

    allTrades: Trade[] = [];

    @Input() selectedTrades: Trade[] = [];
    @Output() selectedTradesChange: EventEmitter<Trade[]> = new EventEmitter();
    @Input() placeholder: string = null;
    @Input() singleSelect: boolean;

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    tradeCtrl = new UntypedFormControl();
    filteredTrades: Observable<Trade[]>;
    @ViewChild('tradeInput') regionInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;

    constructor(private dataAccess: DataApiService) {
        this.filteredTrades = this.tradeCtrl.valueChanges.pipe(
            startWith(null),
            map((trade: string | null) => trade ? this._filter(trade) : this.allTrades.slice()));
    }

    async ngOnInit() {
        this.allTrades = await this.dataAccess.getTrades();
    }


    add(event: MatChipInputEvent): void {

        if (!this.matAutocomplete.isOpen) {
            const input = event.input;
            const value = event.value;

            // Reset the input value
            if (input) {
                input.value = '';
            }

            this.tradeCtrl.setValue(null);
        }
    }

    remove(region: Trade): void {
        const index = this.selectedTrades.indexOf(region);

        if (index >= 0) {
            this.selectedTrades.splice(index, 1);
        }

        this.selectedTradesChange.emit(this.selectedTrades);
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        if (!this.selectedTrades || this.singleSelect) {
            this.selectedTrades = [];
        }
        this.selectedTrades.push(event.option.value);
        this.regionInput.nativeElement.value = '';
        this.tradeCtrl.setValue(null);

        this.selectedTradesChange.emit(this.selectedTrades);
    }

    private _filter(value: any): Trade[] {
        let filterValue = '';
        if (value.name) {
            filterValue = value;
        } else {
            filterValue = value.toLowerCase();
        }


        return this.allTrades.filter(trade => trade.name.toLowerCase().indexOf(filterValue) === 0);
    }

}
