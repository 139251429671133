import { Component, AfterViewInit, OnDestroy, Input, ViewChild, ElementRef, NgZone, OnChanges, SimpleChanges } from '@angular/core';
import { ContractorsService } from '@upkeeplabs/service-pros/app/services/contractors.service';
import { SearchResultModel } from '@upkeeplabs/service-pros/app/model/search-result.model';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { ApiService } from '@cogent/client/api';

@Component({
    selector: 'app-work-order-search',
    templateUrl: './work-order-search.component.html',
    styleUrls: ['./work-order-search.component.css']
})
export class WorkOrderSearchComponent implements AfterViewInit, OnDestroy, OnChanges {

    @Input() searchValue = '';
    searchResultsOpen = false;
    searching = false;
    searchResults: SearchResultModel[];
    selectedItem: SearchResultModel;
    baseApiUrl = '';
    @Input() darkLabel = false;
    @Input() inline = false;

    @ViewChild('searchInput') inputElRef: ElementRef;
    constructor(
        private ngzone: NgZone,
        private contractorService: ContractorsService,
        private entityApi: EntityApiService
        // private userSession: UserSessionService
    ) {
        this.baseApiUrl = ApiService.endPointDotNet;
    }

    ngOnDestroy() {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.searchValue && changes.searchValue.currentValue) {
            this.searchValue = changes.searchValue.currentValue;
            this.executeSearch();
        }
    }

    getEntityUrl(searchResult: SearchResultModel) {
        return ApiService.endPointDotNet + 'entity/thumbnail/' + searchResult.id;
    }

    getAddressUrl(searchResult: SearchResultModel) {
        return ApiService.endPointDotNet + 'policy/' + searchResult.id +
            '/StreetView';
    }

    getWorkOrderItemUrl(searchResult: SearchResultModel) {
        return ApiService.endPointDotNet + 'WorkOrderItem/' +
            searchResult.recordSubType + '/Photo';
    }


    executeSearch() {
        this.searchResults = [];
        this.searchResultsOpen = this.searchValue !== '';

        if (this.searchResultsOpen) {
            this.searching = true;
            this.entityApi.getLoggedInUser().then(user => {
                this.contractorService.searchContractorWorkOrders(user.id, this.searchValue)
                    .then(results => {
                        this.searching = false;
                        this.searchResults = results;
                        this.selectedItem = this.searchResults[0];
                    });
            });
        }
    }

    ngAfterViewInit() {
        this.ngzone.runOutsideAngular(() => {

        });
    }


    closeMenu() {
        if (this.inline) {
            return;
        }
        this.searchValue = '';
    }

    handleKeyEvent($event) {
        if (($event.key === 's' || $event.key === 'S') && $event.altKey) {
            this.inputElRef.nativeElement.focus();
        }
    }
}
