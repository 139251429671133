import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ContractorKPIModel } from '@upkeeplabs/service-pros/app/model/contractors/contractor-kpi.model';
declare var document: any;
declare var Chart: any;

@Component({
  selector: 'app-call-types-view',
  templateUrl: './call-types-view.component.html',
  styleUrls: ['./call-types-view.component.css']
})
export class CallTypesViewComponent implements OnInit, OnChanges {
  @Input() kpis: ContractorKPIModel[];
  callBreakdownChart: any;


  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {

    if(changes['kpis'] && changes['kpis'].currentValue) {
      this.setupCallBreakdownChart();
    }
  }

  get currentKPIs(): ContractorKPIModel {
    if (!this.kpis || !this.kpis[this.kpis.length - 1]) {
      return new ContractorKPIModel();
    }

    return this.kpis[this.kpis.length - 1];
  }

  setupCallBreakdownChart() {
    if (!document.getElementById('call-breakdown-canvas')) {
      setTimeout(() => this.setupCallBreakdownChart(), 500);
      return;
    }

    const config = {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: [
              this.currentKPIs.firstCalls,
              this.currentKPIs.recalls,
              this.currentKPIs.secondOpinions,
            ],
            backgroundColor: [
              '#1b81ac',
              '#759422',
              '#dd8e07',
              'green',
              'blue',
            ],
            label: 'Dataset 1'
          }
        ],
        labels: [
          '1st Calls',
          'Recalls',
          '2nd Opinions',
        ]
      },
      options: {
        responsive: true,
        legend: {
          position: 'right',
        },
        title: {
          display: false,
        },
        animation: { animateScale: true, animateRotate: true }
      }
    };

    const ctx =
      document.getElementById('call-breakdown-canvas').getContext('2d');
    if (this.callBreakdownChart) {
      this.callBreakdownChart.destroy();
    }
    this.callBreakdownChart = new Chart(ctx, config);
  }


}
