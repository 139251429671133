import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CalendarView, CalendarEventAction, CalendarEvent, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { Subject } from 'rxjs';
import {
    isSameDay,
    isSameMonth,
} from 'date-fns';
import { ContractorsService } from '@upkeeplabs/service-pros/app/services/contractors.service';
import { LocalSettingsRepositoryService } from '@upkeeplabs/service-pros/app/services/local-settings-repository.service';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { DatePipe } from '@angular/common';
import { environment } from '@upkeeplabs/service-pros/environments/environment';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { Entity } from '@upkeeplabs/models/cogent';

const colors: any = {
    red: {
        primary: '#ad2121',
        secondary: '#FAE3E3'
    },
    blue: {
        primary: '#1e90ff',
        secondary: '#D1E8FF'
    },
    yellow: {
        primary: '#e3bc08',
        secondary: '#FDF1BA'
    },
    green: {
        primary: '#08e316',
        secondary: '#bfffc3'
    }
};

class TechnicianAndWorkOrders {
    technician: Entity;
    workOrders: WorkOrderSummaryClient[];
    actions: CalendarEventAction[];
    private eventsPrivate: any[];


    getEntityThumbnailUrl(id: string) {


        if (!id) {
            return 'https://upkeeplabs.blob.core.windows.net/doc-public/entity-pictures/anon-person.png';
        }
        return environment.apiBaseUrl + "entity/thumbnail/" + id;

    }


    reset() {
        this.eventsPrivate = null;

        setTimeout(() => {
            this.eventsPrivate = this.events;
        });
    }
    set events(value: any[]) {
        this.eventsPrivate = value;
    }
    get events() {
        if (this.eventsPrivate) {
            return this.eventsPrivate;
        }
        if (!this.workOrders) {
            return null;
        }

        this.eventsPrivate = this.workOrders.map(i => {
            const actions: CalendarEventAction[] = i.technicianId ? [
                {
                    label: `<img class="small-avatar" src="${this.getEntityThumbnailUrl(i.technicianId)}">`,
                    onClick: ({ event }: { event: CalendarEvent }): void => { }
                }
            ] : [];
            return {
                start: this.getDateFromRecord(i, true),
                end: this.getDateFromRecord(i, false),
                meta: {
                    id: i.id,
                    subject: i.propertyAddress,
                    workOrderSummary: i,
                },
                title: `${i.itemName} - ${i.propertyAddress}`,
                color: this.getColorFromSLA(i),
                draggable: true,
                resizable: {
                    beforeStart: true,
                    afterEnd: true
                },
                actions,
            };
        });

        return this.eventsPrivate;
    }

    private getDateFromRecord(record: any, start: boolean) {

        const baseDate = new Date(record.scheduledDate);

        this.setHourAndMinuteFromString(baseDate, start ? record.scheduledStartWindow : record.scheduledEndWindow);
        return baseDate;
    }

    private setHourAndMinuteFromString(date: Date, hourString: string) {
        let hour = 0;
        let minute = 0;
        const dotIndex = hourString.indexOf(':');

        if (dotIndex > -1) {
            hour = parseInt(hourString.substring(0, dotIndex), 10);
        } else {
            hour = parseInt(hourString, 10);
        }

        if (dotIndex > -1) {
            minute = parseInt(hourString.substring(dotIndex + 1, hourString.length), 10);

        }

        if (hourString.toLowerCase().indexOf('pm') > -1 && hour !== 12) {
            hour += 12;
        }

        date.setHours(hour);
        date.setMinutes(minute);
    }


    private getColorFromSLA(item: any) {
        if (item.slaStatus === 'RED') {
            return colors.red;
        }
        if (item.slaStatus === 'YELLOW') {
            return colors.yellow;
        }
        if (item.slaStatus === 'GREEN') {
            return colors.green;
        }

        return colors.blue;
    }

}

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit, AfterViewInit {
    view: CalendarView = CalendarView.Month;

    viewDate: Date = new Date();
    CalendarView = CalendarView;
    loadingCalendar = false;
    rawAppointments: any[];
    selectedDetailIndex = 0;
    selectedItem: any;
    technicians: Entity[];
    techsAndWorkOrders: TechnicianAndWorkOrders[];
    selectedIndex = 0;
    searchField: UntypedFormControl = new UntypedFormControl();
    unassignedAppointments: any[];
    // excludeDays = [0, 6];
    weekendsExcluded = [0, 6];
    weekendsIncluded = [];
    startHour = 6;
    endHour = 19;
    showWeekends = false;

    get excludeDays() {
        if (this.showWeekends) {
            return this.weekendsIncluded;
        }

        return this.weekendsExcluded;
    }



    refresh: Subject<any> = new Subject();

    events: CalendarEvent[] = [];

    activeDayIsOpen = true;

    constructor(
        private contractorsService: ContractorsService,
        private entityApi: EntityApiService,
        private settings: LocalSettingsRepositoryService,
        private snackBar: MatSnackBar,
        private dialogService: DialogsService,
        private missionService: MissionService,
    ) { }

    ngOnInit() {
        this.refreshAppointments();
        if (this.settings.getSetting('contractor-appt-calendar-view')) {
            this.view = JSON.parse(this.settings.getSetting('contractor-appt-calendar-view'));
        }

        this.loadTechnicians();
    }

    async loadTechnicians() {
        const contractor = await this.entityApi.getLoggedInUser(true);

        this.technicians = await this.entityApi.getContractorTechnicians(contractor.id);
        this.groupWorkOrders();
    }

    assignToTechnician(technician: Entity, event: any, doRefresh = false) {
        const id = event.meta.id;
        const workOrder = this.rawAppointments.find(i => i.id === id);
        this.contractorsService.updateWorkOrderTechnician(id, technician.id).then(() => {
            if (doRefresh) {
                this.refreshAppointments();

            }
            this.missionService.showSuccessToast(`Appointment assigned to ${technician.name}`);
        });
    }

    getEntityThumbnailUrl(entityId: string) {
        return `${this.entityApi.getThumbnailUri(entityId)}`;
    }

    private groupWorkOrders() {
        if (!this.technicians || !this.rawAppointments) {
            return;
        }

        this.techsAndWorkOrders = this.technicians.map(technician => {
            const techAndWorkOrders = new TechnicianAndWorkOrders();
            techAndWorkOrders.technician = technician;
            techAndWorkOrders.workOrders = this.rawAppointments.filter(i => i.technicianId === technician.id);

            return techAndWorkOrders;
        });
    }


    ngAfterViewInit() {
        this.searchField.valueChanges
            .pipe(debounceTime(1000))

            .subscribe(term => {
                this.filterItems(term);
            });
    }

    private setHourAndMinuteFromString(date: Date, hourString: string) {
        let hour = 0;
        let minute = 0;
        const dotIndex = hourString.indexOf(':');

        if (dotIndex > -1) {
            hour = parseInt(hourString.substring(0, dotIndex), 10);
        } else {
            hour = parseInt(hourString, 10);
        }

        if (dotIndex > -1) {
            minute = parseInt(hourString.substring(dotIndex + 1, hourString.length), 10);

        }

        if (hourString.toLowerCase().indexOf('pm') > -1 && hour !== 12) {
            hour += 12;
        }

        date.setHours(hour);
        date.setMinutes(minute);
    }


    private getDateFromRecord(record: any, start: boolean) {

        const baseDate = new Date(record.scheduledDate);

        this.setHourAndMinuteFromString(baseDate, start ? record.scheduledStartWindow : record.scheduledEndWindow);
        return baseDate;
    }

    async refreshAppointments() {
        this.loadingCalendar = true;
        const user = await this.entityApi.getLoggedInUser();
        this.rawAppointments = await this.contractorsService.getActiveAppointments(user.id, this.viewDate);
        this.groupWorkOrders();
        this.unassignedAppointments = this.rawAppointments.filter(i => !i.technicianId).map(i => {
            return {
                start: this.getDateFromRecord(i, true),
                end: this.getDateFromRecord(i, false),
                meta: {
                    id: i.id,
                    subject: i.propertyAddress,
                    type: 'unassigned',
                    workOrderSummary: i,
                },
                title: `${i.itemName} - ${i.propertyAddress}`,
                color: this.getColorFromSLA(i),
                draggable: true,
                resizable: {
                    beforeStart: true,
                    afterEnd: true
                },
            };
        });

        this.events = this.rawAppointments.map(i => {
            const actions: CalendarEventAction[] = i.technicianId ? [
                {
                    label: `<img class="small-avatar" src="${this.getEntityThumbnailUrl(i.technicianId)}">`,
                    onClick: ({ event }: { event: CalendarEvent }): void => { }
                }
            ] : [];
            return {
                start: this.getDateFromRecord(i, true),
                end: this.getDateFromRecord(i, false),
                meta: {
                    id: i.id,
                    subject: i.propertyAddress,
                    workOrderSummary: i,
                },
                title: `${i.itemName} - ${i.propertyAddress}`,
                color: this.getColorFromSLA(i),
                draggable: true,
                resizable: {
                    beforeStart: true,
                    afterEnd: true
                },
                actions,
            };
        });
        this.loadingCalendar = false;

    }

    filterItems(term: string) {
        if (!this.rawAppointments) {
            return;
        }
        if (term == null || term === undefined) {
            term = '';
        }

        term = term.toLowerCase();
        this.events = this.rawAppointments.filter(i => {
            return i.itemName.toLowerCase().indexOf(term) > -1
                || i.propertyAddress.toLowerCase().indexOf(term) > -1
                || parseInt(term, 10) === i.number
                || term.toUpperCase() === i.slaStatus
                || i.status.toLowerCase() === term;
        }).map(i => {
            return {
                start: this.getDateFromRecord(i, true),
                end: this.getDateFromRecord(i, false),
                meta: {
                    id: i.id,
                    subject: i.propertyAddress,
                    workOrderSummary: i,
                },
                title: `${i.itemName} - ${i.propertyAddress}`,
                color: this.getColorFromSLA(i),
                draggable: true,
                resizable: {
                    beforeStart: true,
                    afterEnd: true
                },
                // actions: this.actions,
            };
        });
    }

    private getColorFromSLA(item: any) {
        if (item.slaStatus === 'RED') {
            return colors.red;
        }
        if (item.slaStatus === 'YELLOW') {
            return colors.yellow;
        }
        if (item.slaStatus === 'GREEN') {
            return colors.green;
        }

        return colors.blue;
    }

    handleEvent(action: string, event: CalendarEvent): void {
        if (action === 'Clicked') {
            this.selectItem({
                id: event.meta.id,
            });
        }
    }


    selectItem(item: any) {
        this.selectedDetailIndex = 0;
        this.selectedItem = item;
    }

    setView(view: CalendarView) {
        this.view = view;
        this.settings.setSetting('contractor-appt-calendar-view', JSON.stringify(view));
    }

    closeOpenMonthViewDay(data = null) {
        this.activeDayIsOpen = false;
        setTimeout(() => this.refreshAppointments());
    }

    private getTimeString(date: Date) {
        let hour = date.getHours();
        const ampm = hour >= 12 ? 'PM' : 'AM';
        if (hour > 12) {
            hour -= 12;
        }

        let minute = date.getMinutes();
        while (minute !== 0 && minute !== 30) {
            minute -= 1;
        }

        let minuteString = minute.toString();
        if (minuteString.length === 1) {
            minuteString = '0' + minuteString;
        }

        return `${hour}:${minuteString} ${ampm}`;
    }

    async updateAppointment(event: CalendarEvent<any>, newStart: Date, newEnd: Date, doRefresh = false, technicianId: string = null) {
        await this.contractorsService.updateAppointmentTime(event.meta.id, newStart, this.getTimeString(newStart), this.getTimeString(newEnd), technicianId);

        const ref = this.snackBar.open('The appointment time has changed.  Would you like to send a notification to the homeowner?', 'Send Now', { duration: 10000 });
        ref.onAction().subscribe(() => {
            this.contractorsService.resendAppointmentEmail(event.meta.id).then(() => { });
        });
        if (doRefresh) {
            this.refreshAppointments();
        }
    }

    detailPageRefreshed(data) { }

    private datesAreEqual(d1: Date, d2: Date) {
        return d1.getFullYear() === d2.getFullYear()
            && d1.getMonth() === d2.getMonth()
            && d1.getDate() === d2.getDate()
            && d1.getHours() === d2.getHours()
            && d1.getMinutes() === d2.getMinutes();
    }

    async eventTimesChanged({
        event,
        newStart,
        newEnd
    }: CalendarEventTimesChangedEvent, techAndWorkOrders?: TechnicianAndWorkOrders) {

        const datePipe = new DatePipe('en-US');
        if (event && event.meta && event.meta.type === 'unassigned') {
            if (!techAndWorkOrders) {
                return;
            }

            if (!UtilitiesService.datesEqual(newStart, event.start)) {
                this.dialogService.confirm('Confirm', `The original date selected was ${datePipe.transform(event.start, 'shortDate')}.  You selected an appointment date of ${datePipe.transform(newStart, 'shortDate')}.<br>Are you sure you want to continue?`).subscribe(async results => {
                    if (results) {
                        // alert('make change');
                        newEnd = new Date(newStart);
                        const numberOfHours = event.end.getHours() - event.start.getHours();

                        newEnd.setHours(newStart.getHours() + numberOfHours);

                        this.updateAppointment(event, newStart, newEnd, true, techAndWorkOrders.technician.id);
                    }
                });

                return;
            }

            // await this.assignToTechnician(techAndWorkOrders.technician, event);
            // await this.updateAppointment(event, newStart, newEnd);
            // this.refreshAppointments();
            // return;

            return;
        }

        if (!this.datesAreEqual(event.start, newStart) || !this.datesAreEqual(event.end, newEnd)) {
            await this.updateAppointment(event, newStart, newEnd);


            this.events = this.events.map(iEvent => {
                if (iEvent === event) {
                    return {
                        ...event,
                        start: newStart,
                        end: newEnd
                    };
                }
                return iEvent;
            });
            for (const t of this.techsAndWorkOrders) {
                if (t.events) {
                    t.events = t.events.map(iEvent => {
                        if (iEvent === event) {
                            return {
                                ...event,
                                start: newStart,
                                end: newEnd
                            };
                        }
                        return iEvent;
                    });
                }
            }
        }
    }

    dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
        if (isSameMonth(date, this.viewDate)) {
            this.viewDate = date;
            if (
                (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
                events.length === 0
            ) {
                this.activeDayIsOpen = true;
            } else {
                this.activeDayIsOpen = true;
            }
        }
    }
}
