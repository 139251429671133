export class ContractorInvoicePaymentSummary {
    id: string;
    contractorId: string;
    contractorName: string;
    createdDate: Date;
    paymentAmount: number;
    paymentCount: number;
    email: string;
    quickbooksId: string;
    type: string;
}
