import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ContractorKPIModel } from '@upkeeplabs/service-pros/app/model/contractors/contractor-kpi.model';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
declare var document: any;
declare var Chart: any;


@Component({
  selector: 'app-dashboard-contractor-kpi-chart',
  templateUrl: './dashboard-contractor-kpi-chart.component.html',
  styleUrls: ['./dashboard-contractor-kpi-chart.component.css']
})
export class DashboardContractorKpiChartComponent implements OnInit, OnChanges {

  @Input() title: string;
  @Input() kpis: ContractorKPIModel[];
  @Input() chartType = 'line';
  @Input() actualDataSetLabel = 'Actual';
  @Input() metricField: string;
  @Input() targetField: string;
  @Input() yAxisLabel: string;
  @Input() actualChartType: string;
  @Input() targetChartType: string;
  @Input() actualFill = true;
  @Input() targetFill = true;
  @Input() multiplicationFactor = 1;
  @Input() data: any[];
  @Input() showLegend = false;
  @Input() legendPosition = 'bottom';
  @Input() dataSets: any[];
  @Input() stacked = false;
  @Input() light = false;

  accHistoryChart: any;
  chartId = UtilitiesService.newid();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.kpis && changes.kpis.currentValue) {
      this.setACCChartHistory();
    }
  }


  get currentKPIs(): ContractorKPIModel {
    if (!this.kpis) {
      return null;
    }

    return this.kpis[this.kpis.length - 1];
  }

  get showGridLines() {
    if (!this.chartType) {
      return true;
    }
    return this.chartType.toLowerCase() !== 'pie' && this.chartType.toLowerCase() !== 'doughnut';
  }

  setACCChartHistory() {
    const canvas = document.getElementById(this.chartId);
    if (!canvas) {
      setTimeout(() => this.setACCChartHistory(), 500);
      return;
    }

    const backgroundColors = [
      'rgba(184, 89, 149, .3)',
      'rgba(110,124,185, .3)',
      'rgba(184,52,45, .3)'
    ];
    const borderColors = [
      'rgba(184, 89, 149, 1)',
      'rgba(110,124,185, 1)',
      'rgba(184,52,45, 1)'
    ];

    const MONTHS = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    const config: any = {
      type: this.chartType,
      data: {
        labels:
          this.data ? this.data.map(i => i.label) : this.kpis.map(i => MONTHS[i.reportMonth - 1] + ' ' + i.reportYear),
        datasets: this.dataSets ? this.dataSets.map(i => {
          return {
            data: i.data,
            label: i.label,
            backgroundColor: backgroundColors[this.dataSets.indexOf(i)],
            borderColor: borderColors[this.dataSets.indexOf(i)]
          };
        }) : [
            {
              data: this.data ? this.data.map(i => i.value) : this.kpis.map(i => i[this.metricField] * this.multiplicationFactor),
              fill: this.actualFill,
              label: this.actualDataSetLabel,
              backgroundColor: this.data ? backgroundColors : 'rgba(184, 89, 149, .3)',
              borderColor: this.data ? borderColors : 'rgba(184, 89, 149, 1)',
              type: this.actualChartType ? this.actualChartType : this.chartType,
            },
          ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: this.legendPosition,
          display: this.targetField || this.showLegend ? true : false,
          labels: {
            // fontColor: '#fff',
          }
        },
        title: { display: false, text: '' },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        hover: { mode: 'nearest', intersect: true },
        scales: {
          xAxes: [
            {
              display: this.showGridLines,
              scaleLabel: { display: true, labelString: 'Month', fontColor: '#fff' },
              gridLines: {
                display: false,
                // color: 'rgba(255,255,255, .1)'
              },
              ticks: {
                // fontColor: '#fff',
              },
              stacked: this.stacked,
            }
          ],
          yAxes: [
            {
              display: this.showGridLines,
              scaleLabel: { display: true, labelString: this.yAxisLabel, fontColor: '#fff' },
              gridLines: {
                display: this.showGridLines,
                // color: 'rgba(255,255,255, .2)'
              },
              ticks: {
                // fontColor: '#fff',
              },
              stacked: this.stacked,
            }
          ]
        }
      }
    };

    if (this.targetField) {
      config.data.datasets.push(
        {
          data: this.kpis.map(i => i[this.targetField]),
          fill: this.targetFill,
          label: 'Target',
          backgroundColor: 'rgba(110,124,185, .3)',
          borderColor: 'rgba(110,124,185, 1)',
          type: this.targetChartType ? this.targetChartType : this.chartType,
        }
      );
    }

    const ctx = canvas.getContext('2d');
    if (this.accHistoryChart) {
      this.accHistoryChart.destroy();
    }
    this.accHistoryChart = new Chart(ctx, config);
  }

}
