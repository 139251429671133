
import { FunctionCustomFormModalComponent } from '@cogent/client/shared/components/functions/function-custom-form/function-custom-form-modal/function-custom-form-modal.component';

import { FunctionQuestionRendererV2Component } from '@cogent/client/shared/components/functions/function-question-renderer-v2/function-question-renderer-v2.component';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { AuthoDetailEntryComponent } from '@cogent/client/shared/components/autho/autho-detail-entry/autho-detail-entry.component';
import { trigger, transition, style, animate } from '@angular/animations';
import { ExecuteFunctionArgs, MissionService } from '@cogent/client/shared/services/mission-service';
import { getAngularExecutors, getSingletonFunction } from '@cogent/client/shared/logic/client-functions.angular.logic';
import { FunctionRunner } from '../../../../../../../node/logic/functions/function-runner.logic';
import { getSharedExecutors } from '../../../../../../../node/logic/functions/shared/control-flow-functions.logic';
import { ApiService } from '@cogent/client/api';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { LazyComponentProviderService } from '@cogent/client/shared/services/lazy-component-provider.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CustomFunctionApiService } from '@cogent/client/shared/services/api/custom-function-api.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AuthorizationsApiService } from '@cogent/client/shared/services/api/authorizations-api.service';
import { AuthorizationInputArgs, AuthorizationReviewTrigger, CostLine, Entity, ExtendedPolicyServiceOffering, Note, RepairType, SalesItemCoverageWorkOrderItem, WorkOrderLineAuthorizationRepairItem } from '@upkeeplabs/models/cogent';
import { PolicyApiService } from '@cogent/client/shared/services/api/policy-api.service';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { UpkeepPipesModule } from '@cogent/client/shared/pipes/upkeep-pipes/upkeep-pipes.module';
import { Authorization, WorkOrderLineSummaryClient } from '@cogent/shared/models/service/work-order-line-summary.model';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SignatureComponent } from '@cogent/client/shared/components/misc/signature/signature.component';
import { NoteApiService } from '@cogent/client/shared/services/api/note-api.service';
import { AmazonContactApiService } from '@cogent/client/shared/services/api/amazon-contact-api.service';



@Component({
    selector: 'app-authorization-v4',
    standalone: true,
    animations: [
        trigger('moveDown', [
            transition(':leave', [
                style({ opacity: 1, transform: 'translateY(0)', position: 'absolute' }),
                animate('500ms', style({ transform: 'translateY(600px)', opacity: 0 })),

            ]),
            transition(':enter', [

                style({ opacity: 0, transform: 'translateY(-400px)', }),
                animate('500ms', style({ transform: 'translateX(0)', opacity: 1 })),

            ])
        ]),
        trigger('moveUp', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateY(400px)', }),
                animate('500ms', style({ transform: 'translateX(0)', opacity: 1 })),

            ])
        ]),
        trigger('simpleFadeAnimation', [
            transition(':enter', [
                style({ opacity: 0, height: '0' }),
                animate('200ms', style({ height: '*' })),
                animate('100ms', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1, height: '*' }),
                animate('100ms', style({ opacity: 0 })),
                animate('200ms', style({ height: '0' }))
            ])
        ])
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [AuthoDetailEntryComponent, UpkeepPipesModule,
        FunctionQuestionRendererV2Component,
        MatProgressSpinnerModule, MatSlideToggleModule, MatMenuModule, MatSnackBarModule, CommonModule, MatProgressBarModule,
        MatInputModule,
        SignatureComponent,
        MatFormFieldModule,
        FormsModule, MatButtonModule, MatTabsModule, MatIconModule],
    templateUrl: './authorization-v4.component.html',
    styleUrl: './authorization-v4.component.scss'
})
export class AuthorizationV4Component implements OnInit, OnChanges, OnDestroy {
    hasIncompatibilityCoverage: boolean;
    selectedLine: WorkOrderLineSummaryClient;
    workOrderSummary: WorkOrderSummaryClient;
    selectedIndex = 0;
    sideBarOpen = false;
    showFunctionQuestions = false;
    hideFunctionQuestions = false;
    costLines: CostLine[] = [];
    @ViewChild('functionQuestionRendererComponent') functionQuestionRendererComponent: FunctionQuestionRendererV2Component;
    detailOpen = false;
    repairTypes: RepairType[];
    selectedRepairType: RepairType;
    limit: number;
    limitType: 'Plan Limit' | 'Item Limit' | 'Plan Occurrence Limit' | 'Item Occurence Limit';
    salesItemCoverageWorkOrderItem: SalesItemCoverageWorkOrderItem;
    @Input() workOrderId: string;
    @Input() workOrderLineId: string;
    @Input() testMode = false;
    additionalNotes: string;
    signatureDataUrl: string;
    explaingOOP = false;
    showContactsInQueue = false;
    contactsInQueue: number;
    authorizationRepairItemGroupLimits: any;
    workOrderLineAuthorizationRepairItems: WorkOrderLineAuthorizationRepairItem[];
    recognition: any;
    @ViewChild('authoItems') authoItems: AuthoDetailEntryComponent;
    @Input() warnings: AuthorizationReviewTrigger[];
    @Output() authorizationComplete: EventEmitter<boolean> = new EventEmitter();
    lastStepShown = false;
    approvedTotal: number;
    oop: number;
    partsCost: number;
    tcostLines: CostLine[];
    authorizing = false;
    lastAuthoResult: Authorization;
    currentAuthoArgs: AuthorizationInputArgs;
    currentRunner: FunctionRunner;
    currentArgs: ExecuteFunctionArgs;
    policyServiceOffering: ExtendedPolicyServiceOffering;

    constructor(private snackBar: MatSnackBar,
        private api: ApiService,
        private dialog: DialogsService,
        private router: Router,
        private matDialog: MatDialog,
        private serviceApi: ServiceApiService,
        private policyApi: PolicyApiService,
        private route: ActivatedRoute,
        private noteApi: NoteApiService,
        private zone: NgZone,
        private amazonConnect: AmazonContactApiService,
        private authoApi: AuthorizationsApiService,
        private functionApi: CustomFunctionApiService,
        private lazy: LazyComponentProviderService,
        private missionService: MissionService) {
        missionService.publish({
            type: 'HIDE-MENU-BAR',
            messageBody: null
        });
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.workOrderId?.currentValue) {
            this.load();
        }
    }

    ngOnInit(): void {
        this.route.params.subscribe(async params => {
            if (params.id) {
                this.workOrderId = params.id;
                this.load();
            }
        });
    }

    ngOnDestroy(): void {
        try {
            if (this.recognition) {
                this.recognition.stop();
                delete this.recognition;
            }
        } catch { }
    }

    recognize() {
        if (!this.recognition) {
            const recognitionSvc = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
            this.recognition = new recognitionSvc();
            this.recognition.continuous = true;
            this.recognition.onresult = (e) => {
                const accumulatedResult = [];
                for (const result of e.results) { accumulatedResult.push(`${result[0].transcript}`); }
                this.zone.run(() => {
                    this.additionalNotes = accumulatedResult.join(' ');
                })
            }
            this.recognition.start();
        } else {
            this.recognition.stop();
            delete this.recognition;
        }
    }


    async load() {
        this.workOrderSummary = await this.serviceApi.getWorkOrderSummaryById(this.workOrderId);

        if (this.workOrderSummary.limitPerPlanPeriod) {
            this.limit = this.workOrderSummary.limitPerPlanPeriod;
            const totalSpent = await this.authoApi.getTotalSpentForPolicy(this.workOrderSummary.policyId);
            if (totalSpent) {
                this.limit -= totalSpent;
                if (this.limit < 0) {
                    this.limit = 0;
                }
            }
            this.limitType = 'Plan Limit';
        }
        if (this.workOrderSummary.limitPerOccurance) {
            if (this.workOrderSummary.limitPerOccurance < this.limit) {
                this.limit = this.workOrderSummary.limitPerOccurance;
                this.limitType = 'Plan Occurrence Limit';
            }
        }
        for (const line of this.workOrderSummary.lines) {
            if (!line.authorizations) {
                line.authorizations = [];
            }
            line.url = `${ApiService.endPointDotNet}WorkOrderItem/${line.itemId}/photo`;
        }

        if (this.workOrderLineId) {
            this.selectLine(this.workOrderSummary.lines.find(i => i.id === this.workOrderLineId));
        }
    }


    async selectLine(line: WorkOrderLineSummaryClient) {
        // if (this.selectedLine && this.selectedIndex > 0 && this.selectedIndex < 3) {
        //     const result = await this.dialog.confirm('Start Over', 'Are you sure you want to start over?').toPromise();
        //     if (!result) {
        //         return;
        //     }
        // }
        this.selectedLine = line;
        this.authoApi.getWorkOrderLineAuthorizationRepairItemsByLineId(this.selectedLine.id).then(repairItems => {
            this.workOrderLineAuthorizationRepairItems = repairItems;
            this.authoApi.getRepairTypesForWorkOrderItemAnonymous(this.selectedLine.itemId).then(rt => {
                this.repairTypes = rt;
            });
        });
        this.selectedIndex = 0;
        if (!line.authorizations?.length) {
            setTimeout(() => this.startNewAuthorization(), 100);
        }

        this.policyServiceOffering = (await this.policyApi.getPolicyServiceOffering(this.selectedLine.id))[0];
        if (this.policyServiceOffering) {
            this.salesItemCoverageWorkOrderItem = await this.authoApi.getSalesItemCoverageWorkOrderItem(this.policyServiceOffering.salesItemCoverageId, this.selectedLine.itemId);
            if (this.salesItemCoverageWorkOrderItem && this.salesItemCoverageWorkOrderItem.authorizationRepairItemGroupLimits) {
                this.authorizationRepairItemGroupLimits = JSON.parse(this.salesItemCoverageWorkOrderItem.authorizationRepairItemGroupLimits);
            }
        }

        if (this.policyServiceOffering.limitPerOccurance && this.policyServiceOffering.limitPerOccurance < this.limit) {
            this.limit = this.policyServiceOffering.limitPerOccurance;
            this.limitType = 'Item Occurence Limit';
        }

        if (this.policyServiceOffering.limitPerPlanPeriod && this.policyServiceOffering.limitPerPlanPeriod < this.limit) {
            // TODO: Calculate amount spent already
            this.limit = this.policyServiceOffering.limitPerPlanPeriod;
            this.limitType = 'Item Limit';
        }
    }

    get progress() {
        return ((3 - (3 - this.selectedIndex)) / 3) * 100;
    }

    selectedIndexChange(index: number) {
        this.lastStepShown = false;
        if (index === 5) {
            setTimeout(() => this.lastStepShown = true, 500);
        }
    }

    backToJobDetails() {
        delete this.selectedLine;
    }

    selectRepairType(repairType: RepairType) {
        if (this.selectedRepairType) {
            this.clearSelectedRepairType();
        } else {
            this.selectedRepairType = repairType;
            this.startAuthoQuestions();
        }
    }

    clearSelectedRepairType() {
        delete this.selectedRepairType;
        this.functionQuestionRendererComponent.reset();
    }

    logIt() {

    }

    async saveAuthorization() {
        this.approvedTotal = this.authoItems.approvedTotal;
        this.oop = this.authoItems.oop;
        this.partsCost = this.authoItems.partsCost;
        this.tcostLines = this.authoItems.costLines;
        if (this.oop > 0) {
            this.selectedIndex = 3;
        } else {
            this.selectedIndex = 4;
        }
    }

    authorizeOOP() {
        this.explaingOOP = false;
        this.selectedIndex = 4;
    }

    additionalInformationBack() {
        if (!this.oop) {
            this.selectedIndex = this.selectedIndex - 2;
        } else {
            this.selectedIndex = this.selectedIndex - 1;
        }
    }

    callToExplain() {
        this.explaingOOP = true;
        this.selectedIndex = 4;
    }


    async doAutho() {


        this.authorizing = true;
        const autho = new Authorization();
        autho.authorizedAmount = this.approvedTotal;
        autho.outOfPocketAmount = this.oop;
        autho.partsCost = this.partsCost;
        autho.completeDate = new Date();
        autho.warnings = [];
        autho.id = UtilitiesService.newid();
        autho.customerApprovesOutOfPockets = !this.explaingOOP;
        autho.costLines = this.costLines;


        const qAndA = this.functionQuestionRendererComponent.renderer.questions.filter(i => i.answer).map(function (i) {
            const inCells = i.functionCell.ports.items.filter(j => j.group === 'in');

            const cell = inCells.find(j => j.attrs?.label?.text?.toLowerCase() === 'label');
            let question = '';
            let cellIndex = -1;
            if (cell) {
                cellIndex = inCells.indexOf(cell);
                if (cellIndex > -1) {
                    question = i.inputs.inputs[cellIndex];
                }
            }
            if (!question) {
                question = i.functionCell.attrs?.label?.text;
            }
            return {
                question,
                answer: i.answer,
                type: i.functionCell.type,
            };
        });
        autho.questionsAndAnswers = qAndA;

        if (!this.currentAuthoArgs.authorizationWarnings) {
            this.currentAuthoArgs.authorizationWarnings = [];
        }
        for (const trigger of this.currentAuthoArgs.authorizationWarnings) {
            const value: number = trigger.value;
            switch (trigger.type) {
                case 'MANUAL_REVIEW':
                    autho.warnings.push(trigger);
                    break;
                case 'DEFAULT_PARTS_ALTERED':
                    if (this.tcostLines.find(i => !i.automaticallyAdded)) {
                        autho.warnings.push(trigger);
                    }
                    break;
                case 'DEFAULT_PARTS_VARIES':
                    const authoAmount = this.tcostLines.filter(i => i.automaticallyAdded).map(i => i.amount).reduce((a, b) => a + b, 0);
                    const initialAmount = this.tcostLines.filter(i => i.automaticallyAdded).map(i => i.authorizationRepairItemSelector.priceRangeMin).reduce((a, b) => a + b, 0);

                    const pct = 1 + (value > 1 ? value / 100 : value);
                    if (authoAmount > initialAmount * pct) {
                        autho.warnings.push(trigger);
                    }
                    break;
                case 'TOTAL_AUTHO':
                    if (value && autho.authorizedAmount > value) {
                        autho.warnings.push(trigger);
                    }
                    break;
                case 'TOTAL_OOP':
                    if (value && autho.outOfPocketAmount > value) {
                        autho.warnings.push(trigger);
                    }
                    break;
                case 'TOTAL_PARTS':
                    if (value && autho.partsCost > value) {
                        autho.warnings.push(trigger);
                    }
                    break;
                case 'TOTAL_PARTS_AND_AUTHO':
                    if (value && (autho.partsCost + autho.authorizedAmount) > value) {
                        autho.warnings.push(trigger);
                    }
                    break;
            }
        }

        if (this.explaingOOP) {
            const warning = new AuthorizationReviewTrigger();
            warning.type = 'MANUAL_REVIEW';
            warning.description = 'Customer wants a review of out of pocket expenses';
            autho.warnings.push(warning);
        }
        this.lastAuthoResult = autho;

        if (this.testMode) {
            await UtilitiesService.wait(2000);
            this.authorizing = false;
            this.selectedIndex = 5;
            this.selectedLine.authorizations.push(autho);
            this.authorizationComplete.emit(true);
    
            if (this.lastAuthoResult?.warnings?.length) {
                this.amazonConnect.getAuthoContactsInQueue().then(contacts => {
                    this.showContactsInQueue = true;
                    this.contactsInQueue = contacts.Collections[0].Value;
                });
            }
            return;
        }

        for (const item of this.tcostLines) {
            if (item.authorizationRepairItemSelector?.priceRangeMax) {
                if (item.amount > item.authorizationRepairItemSelector.priceRangeMax && !item.companyProvidesPart) {
                    const trigger = new AuthorizationReviewTrigger();
                    trigger.type = 'REPAIR_ITEM_OVER_GUIDELINE';
                    trigger.description = `Part: ${item.description} over the guideline price`

                    autho.warnings.push(trigger);
                }
            }
        }

        if (!this.explaingOOP && this.signatureDataUrl) {
            const note = new Note();
            note.id = UtilitiesService.newid();
            note.workOrderId = this.workOrderId;
            note.workOrderLineId = this.selectedLine?.id;
            note.noteText = 'Customer approved out of pockets';
            note.workorderLineAuthorizationId = autho.id;

            await this.noteApi.saveNote(note);
            await this.noteApi.saveNoteAttachment(note.id, {
                base64: this.signatureDataUrl,
                name: 'customer-signature.png',
                noteId: note.id,
                isImage: true,
            });
        }
        let attachmentNote: Note;
        for (const qAndA of autho.questionsAndAnswers) {
            if (qAndA.type === '/assets/images/function-icons/upload-image.png') {
                for (const noteAttachment of qAndA.answer) {

                    attachmentNote = new Note();

                    attachmentNote.id = UtilitiesService.newid();
                    attachmentNote.noteText = 'Authorization Pictures';
                    attachmentNote.workOrderId = this.selectedLine.workOrderId;
                    attachmentNote.workOrderLineId = this.selectedLine.id;
                    attachmentNote.workorderLineAuthorizationId = autho.id;
                    await this.noteApi.saveNote(attachmentNote);


                    noteAttachment.noteId = attachmentNote.id;
                    await this.noteApi.saveNoteAttachment(attachmentNote.id, noteAttachment);
                }
                //delete qAndA.answer;
                qAndA.answer = attachmentNote.id;
            }
        }

        this.selectedLine.authorizationRequestArgs = JSON.stringify(autho);

        await this.serviceApi.saveNewAuthorization(this.selectedLine.id, autho, this.selectedRepairType.id);
        this.authorizing = false;
        this.selectedIndex = 5;
        this.selectedLine.authorizations.push(autho);
        this.authorizationComplete.emit(true);

        if (this.lastAuthoResult?.warnings?.length) {
            this.amazonConnect.getAuthoContactsInQueue().then(contacts => {
                this.showContactsInQueue = true;
                this.contactsInQueue = contacts.Collections[0].Value;
            });
        }
    }

    async startAuthoQuestions() {

        const args = new AuthorizationInputArgs();
        args.workOrderLineId = this.selectedLine.id;
        this.currentAuthoArgs = args;
        const repairType = this.selectedRepairType;

        const entity = await this.api.getSingleNode(`Authorization/get-entity-by-work-order-line/${this.selectedLine.id}`, null, () => new Entity());
        const firstEffectiveDate = await this.policyApi.getPolicyFirstEffectiveDate(this.workOrderSummary.policyId);
        args.firstPolicyEffectiveDate = firstEffectiveDate.firstEffectiveDate;

        const items = this.workOrderLineAuthorizationRepairItems;
        const costLines: CostLine[] = [];

        for (const authItem of repairType.repairItems) {
            const costLine = new CostLine();
            const foundItem = items.find(i => i.id === authItem.id);
            costLine.authorizationRepairItemSelector = foundItem;
            if (foundItem) {
                costLine.amount = foundItem.priceRangeMin ?? 0;
                costLine.qty = 1;
                costLine.defaultItem = true;
                costLine.description = foundItem.name;
                costLine.id = UtilitiesService.newid();
                costLine.repairItemId = foundItem.id;
                costLine.automaticallyAdded = true;
                costLine.addedByStepId = 'initial';
                costLine.authorizationRepairItemGroupId = foundItem.authorizationRepairItemGroupId;
                if (this.selectedRepairType.coveredType === 'Always' || authItem.coveredType === 'Always') {
                    costLine.authorizationRepairItemSelector.salesItemCoverageAuthorizationRepairItemId = 'covered-by-default';
                }
                costLines.push(costLine);
                if (!foundItem.companyNeverProvides) {
                    // if (!entity.partsOrderingType || entity.partsOrderingType !== 'Never') {
                    //     costLine.companyProvidesPart = true;
                    // }
                    if (foundItem.companyPrefersToProvide || this.costLines.find(i => i.companyProvidesPart)) {
                        costLine.companyProvidesPart = true;
                    }
                }
            }
        }


        args.costLines = costLines;
        args.authorizationWarnings = [];
        args.laborRate = await this.authoApi.getLaborRate(this.selectedLine.id);
        if (repairType.laborHours) {

            const costLine = new CostLine();
            costLine.amount = repairType.calcLaborAsHourlyRate ? args.laborRate : repairType.laborHours;
            costLine.qty = repairType.calcLaborAsHourlyRate ? repairType.laborHours : 1;
            costLine.defaultItem = true;
            costLine.calcLaborAsHourlyRate = repairType.calcLaborAsHourlyRate;
            costLine.description = 'Labor';
            costLine.id = UtilitiesService.newid();
            costLine.repairItemId = 'labor';
            costLine.automaticallyAdded = true;
            costLine.allowQuantityEntry = true;
            costLine.addedByStepId = 'initial';
            costLine.companyProvidedAvailable = false;
            costLines.push(costLine);
            costLine.companyProvidesPart = false;
            costLine.authorizationRepairItemSelector = new WorkOrderLineAuthorizationRepairItem();
            costLine.authorizationRepairItemSelector.companyNeverProvides = true;
            costLine.authorizationRepairItemSelector.id = 'labor';
            costLine.authorizationRepairItemSelector.priceRangeMax = costLine.amount;
            costLine.authorizationRepairItemSelector.name = 'Labor';
            costLine.authorizationRepairItemSelector.companyNeverProvides = true;
            costLine.authorizationRepairItemSelector.salesItemCoverageAuthorizationRepairItemId = 'Labor';

        }
        const guidelinePrice = await this.authoApi.getGuidelinePriceForRepairType(this.selectedLine.id, repairType.id);

        if (guidelinePrice) {
            if (guidelinePrice.price) {
                const trigger = new AuthorizationReviewTrigger();
                trigger.id = UtilitiesService.newid();
                trigger.type = 'TOTAL_PARTS_AND_AUTHO';
                trigger.value = guidelinePrice.price;
                trigger.description = 'Over guideline price';
                trigger.stepId = 'initial';
                args.authorizationWarnings.push(trigger);
            }
            if (guidelinePrice.outOfPocket) {
                const trigger = new AuthorizationReviewTrigger();
                trigger.description = 'Over out of pocket guideline';
                trigger.id = UtilitiesService.newid();
                trigger.stepId = 'initial';
                trigger.value = guidelinePrice.outOfPocket;
                args.authorizationWarnings.push(trigger);
            }
        }
        if (repairType.alwaysWarn) {
            const trigger = new AuthorizationReviewTrigger();
            trigger.type = 'MANUAL_REVIEW';
            trigger.description = repairType.alwaysWarnMessage;
            trigger.id = UtilitiesService.newid();
            trigger.stepId = 'initial';
            args.authorizationWarnings.push(trigger);
        }

        if (repairType.warnIfDefaultItemsChange) {
            const trigger = new AuthorizationReviewTrigger();
            trigger.type = 'DEFAULT_PARTS_ALTERED';
            trigger.id = UtilitiesService.newid();
            trigger.stepId = 'initial';
            args.authorizationWarnings.push(trigger);
        }

        if (this.salesItemCoverageWorkOrderItem && repairType.coveredType === 'Conditional') {
            if (this.salesItemCoverageWorkOrderItem.coveredRepairTypes.indexOf(repairType.id) === -1) {
                const trigger = new AuthorizationReviewTrigger();
                trigger.description = `Repair type: ${repairType.name} is not covered`;
                trigger.type = 'MANUAL_REVIEW';
                trigger.id = UtilitiesService.newid();
                trigger.stepId = 'initial';
                args.authorizationWarnings.push(trigger);
            }
        } else if (repairType.coveredType === 'Never') {
            const trigger = new AuthorizationReviewTrigger();
            trigger.description = `Repair type: ${repairType.name} is not covered`;
            trigger.type = 'MANUAL_REVIEW';
            trigger.id = UtilitiesService.newid();
            trigger.stepId = 'initial';
            args.authorizationWarnings.push(trigger);
        }

        if (repairType.functionDefinition) {
            this.runCustomFunction({
                functionJson: repairType.functionDefinition,
                objectInScope: args
            });
        } else {
            this.dialog.alert('Not Found', 'An authorization function has not been created.');
            this.selectedIndex = 0;
        }

    }

    private async runCustomFunction(args: ExecuteFunctionArgs) {

        this.currentArgs = args;

        const angularFunctions = getAngularExecutors();
        const sharedFunctions = getSharedExecutors();

        const allFunctions = sharedFunctions.concat(angularFunctions);
        const functionRunner = new FunctionRunner(allFunctions, {
            api: this.api,
            missionService: this.missionService,
            dialogService: this.dialog,
            router: this.router,
            lazy: this.lazy,
            matDialog: this.matDialog,
            refreshAction: args.refreshAction ? args.refreshAction : () => {
                if (this.currentArgs.refreshKey) {

                    this.missionService.raiseEvent({
                        eventName: this.currentArgs.refreshKey,
                        eventParameters: {},
                        shouldContinue: null,
                    });
                }
            },
            refreshAllAction: args.refreshAllFunction,
            functionRetriever: (id) => {
                return this.api.getSingleNode('CustomFunction', { id_eq: id });
            }
        }, args.objectInScope, (result, final) => {
            if (final) {
                this.showFunctionQuestions = false;
                this.hideFunctionQuestions = false;
                this.selectedIndex = 2;
                const lines: CostLine[] = [];

                for (const item of this.currentAuthoArgs.costLines) {
                    let foundLine = lines.find(i => i.repairItemId === item.repairItemId);
                    if (foundLine) {
                        foundLine.qty += 1;
                    } else {
                        if (!item.qty) {
                            item.qty = 1;
                        }
                        foundLine = item;
                        lines.push(foundLine);
                    }
                }
                this.costLines = lines;
            }
        }, "angular");

        functionRunner.changeWorkingMessage = args.changeWorkingMessage;
        functionRunner.transferRunner = (newRunner) => {

            this.currentRunner = newRunner;
            this.currentRunner.showCustomForm = async questions => {
                for (const question of questions.questions) {

                    const inputParams = await this.currentRunner.getStepInputParams(question);
                    question.inputParams = inputParams;
                }
                FunctionCustomFormModalComponent.ShowModal(this.matDialog, questions, this.currentRunner);
            }

        }
        this.currentRunner = functionRunner;
        functionRunner.getSingleton = getSingletonFunction();
        functionRunner.changeLanes = args.moveLane;
        functionRunner.removeFromQueue = args.removeFromQueue;
        functionRunner.closeObjectDetail = args.closeObjectDetail;

        functionRunner.showCustomForm = async questions => {

            for (const question of questions.questions) {

                const inputParams = await functionRunner.getStepInputParams(question);
                question.inputParams = inputParams;
            }
            FunctionCustomFormModalComponent.ShowModal(this.matDialog, questions, functionRunner);
        };


        functionRunner.setupFunctionQuestionRender = async runner => {
            return new Promise((resolve, reject) => {

                this.showFunctionQuestions = true;
                this.hideFunctionQuestions = false;
                setTimeout(() => {
                    this.functionQuestionRendererComponent.reset();
                    functionRunner.questionRenderer = this.functionQuestionRendererComponent.renderer;
                    resolve();
                });
            });
        }
        functionRunner.runProcess(args.functionJson);
    }

    startNewAuthorization() {
        this.selectedIndex = 1;
        delete this.selectedRepairType;
        this.functionQuestionRendererComponent.reset();
    }

    deleteAuthorization(authorization: Authorization) {
        const index = this.selectedLine.authorizations.indexOf(authorization);
        this.selectedLine.authorizations.splice(index, 1);
        const ref = this.snackBar.open('Item Deleted', 'Undo', { duration: 10000 }).onAction().subscribe(value => {
            this.selectedLine.authorizations.splice(index, 0, authorization);
        });
    }

    get hasAuthorizationLines() {
        return this.authoItems?.costLines?.length > 0;
    }

    toggleDetail() {
        this.detailOpen = !this.detailOpen;

        if (this.detailOpen) {
            this.policyApi.getPolicyServiceOffering(this.selectedLine.id).then(result => {
                this.policyServiceOffering = result[0];
            });
        }
    }
}

