<div flex>
    <div id="selectionPanel" appAutoHeight #selectionPanel nogrow>
        <app-date-range-display-and-selector memoryKey="contractor-report-card" [(startDate)]="startDate"
            [(endDate)]="endDate"></app-date-range-display-and-selector>

        <mat-form-field topmargin30 appearance="outline">
            <mat-label>Trade</mat-label>
            <mat-select [(ngModel)]="tradeId" (change)="refreshContractors">
                <mat-option *ngFor="let trade of trades" value="{{trade.tradeId}}">{{trade.tradeName}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Area</mat-label>
            <mat-select [(ngModel)]="areaId" (change)="refreshContractors">
                <mat-optgroup *ngFor="let state of states" [label]="state.name">

                    <mat-option *ngFor="let area of state.areas" value="{{area.id}}">{{area.name}}</mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>

        <button mat-button mat-raised-button block large  (click)="runReport()" color="primary"
            [disabled]="!canRunReport || loading"><mat-spinner *ngIf="loading" class="thirty"></mat-spinner> Run Report</button>
    </div>

    <div id="reportContainer">
        <div id="toolbar">
            <button mat-button (click)="printReport()"><i class="material-icons">print</i> Print</button>
            <button mat-button (click)="tableToExcel()"><i class="material-icons">file_download</i> Export</button>
        </div>


        <div id="intro" *ngIf="!reportData && !loading">
            <div>
                <h1>Select Your Criteria<br />
                    <span>and press 'Run Report'</span>
                </h1>

                <i class="material-icons" style="transform: scaleY(-1); font-size: 60px">reply</i>
            </div>
            <i class="material-icons">library_books</i>
        </div>

        <!-- <div id="loadingMessage" *ngIf="loading">
            <div flex id="loadingMessageContainer" class="slide-in-down">
                <mat-spinner nogrow></mat-spinner>
                <h3>Loading...</h3>
            </div>
        </div> -->


        <div class="table" id="reportArea" appAutoHeight #reportArea *ngIf="reportData && !loading"
            style="margin-bottom: 0">
            <h1 topmargin20>Service Pro Status Report</h1>
            <h2 topmargin20>{{ contractor }} - {{ trade }} - {{ area }}</h2>

            <table class="table">
                <thead>
                    <tr>
                        <th style="text-align: right;">Month</th>
                        <th style="text-align: right;">Offers</th>
                        <th style="text-align: right;">Commitment</th>
                        <th style="text-align: right;">Accepted</th>
                        <th style="text-align: right;">First Calls</th>
                        <th style="text-align: right;">Recalls</th>
                        <th style="text-align: right;">%</th>
                        <th style="text-align: right;">Target</th>
                        <th style="text-align: center;">ACC</th>
                        <th style="text-align: right;">Target</th>
                        <th style="text-align: right;">Stars</th>
                        <th style="text-align: right;">Target</th>
                        <!-- <th style="text-align: right;">1st Call Complete</th> -->
                        <th style="text-align: right;">Target</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of reportData">
                        <tr style="text-align: right;">
                            <td>{{ item.month | date: 'MMM y' }}</td>
                            <td>{{ item.offers }}</td>
                            <td>{{ item.commitment }}</td>
                            <td>{{ item.accepted }}</td>
                            <td>{{ item.firstCalls }}</td>
                            <td>{{ item.recalls }}</td>
                            <td>{{ item.recallPercent }}</td>
                            <td>{{ item.recallTarget }}</td>
                            <td>
                                <button mat-button (click)="toggleAccDetail(item)" style="text-align: right;">
                                    {{ item.acc }} <mat-icon class="rotate" [class.rotated]="item.showAccDetail">chevron_right</mat-icon>
                                </button>
                            </td>
                            <td>{{ item.accTarget }}</td>
                            <td>{{ item.stars }}</td>
                            <td>{{ item.starTarget }}</td>
                            <!-- <td>{{ item.firstCallCompletes }}</td> -->
                            <td>{{ item.firstCallCompleteTarget }}</td>
                        </tr>
                        <tr *ngIf="item && item.showAccDetail">
                            <td></td>
                            <td colspan="12">
                                <h3>ACC Detail</h3>
                                <table>
                                    <tr>
                                        <th>Job</th>
                                        <th>Customer</th>
                                        <th>Address</th>
                                        <th style="text-align:right;">Invoices</th>
                                        <th style="text-align:right;">Cash Outs</th>
                                        <th style="text-align:right;">Purchase Orders</th>
                                        <th style="text-align:right;">Total</th>
                                    </tr>
                                    <tr *ngFor="let line of item.accDetail">
                                        <td>{{line.jobNumber}}</td>
                                        <td>{{line.customer}}</td>
                                        <td>{{line.address1}}</td>
                                        <td style="text-align:right;">{{line.totalContractorInvoices| number:'1.2-2'}}
                                        </td>
                                        <td style="text-align:right;">{{line.totalCashOuts| number:'1.2-2'}}</td>
                                        <td style="text-align:right;">{{line.totalPurchaseOrders| number:'1.2-2'}}</td>
                                        <td style="text-align:right; font-weight: bold;">
                                            {{line.totalCost | number:'1.2-2'}}</td>
                                    </tr>
                                </table>
                                <div style="height:15px"></div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>
