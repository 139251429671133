import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { startWith, map } from 'rxjs/operators';
import { SettingsApiService } from '@cogent/client/shared/services/api/settings-api.service';
import { WorkOrderStatus } from '@upkeeplabs/models/cogent';

@Component({
    selector: 'app-work-order-status-chip-selection',
    templateUrl: './work-order-status-chip-selection.component.html',
    styleUrls: ['./work-order-status-chip-selection.component.css']
})
export class WorkOrderStatusChipSelectionComponent implements OnInit {

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    @Input() placeholder: string;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    statusCtrl = new UntypedFormControl();
    filteredStatuses: Observable<WorkOrderStatus[]>;
    @Input() statuses: WorkOrderStatus[] = [];
    @Input() singleSelect: boolean;
    @Output() statusesChange: EventEmitter<WorkOrderStatus[]> = new EventEmitter();
    allStatuses: WorkOrderStatus[];

    @ViewChild('statusInput') statusInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;

    constructor(private settingsApi: SettingsApiService) {

    }

    ngOnInit() {
        this.settingsApi.getWorkOrderStatuses().then(statuses => {
            this.allStatuses = statuses.sort((a, b) => a.name > b.name ? 1 : -1);
            this.filteredStatuses = this.statusCtrl.valueChanges.pipe(
                startWith(null),
                map((status: string | null) => status ? this._filter(status) : this.allStatuses.slice()));
        });
    }

    add(event: MatChipInputEvent): void {

        if (!this.matAutocomplete.isOpen) {
            const input = event.input;
            if (input) {
                input.value = '';
            }

            this.statusCtrl.setValue(null);
        }
    }

    remove(status: WorkOrderStatus): void {
        const index = this.statuses.indexOf(status);

        if (index >= 0) {
            this.statuses.splice(index, 1);
        }
        this.statusesChange.emit(this.statuses);
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        if (!this.statuses) {
            this.statuses = [];
        }
        if (!this.statuses || this.singleSelect) {
            this.statuses = [];
        }
        this.statuses.push(event.option.value);
        this.statusInput.nativeElement.value = '';
        this.statusCtrl.setValue(null);
        this.statusesChange.emit(this.statuses);
    }

    private _filter(value: any): WorkOrderStatus[] {
        let filterValue = '';
        if (value.name) {
            filterValue = value;
        } else {
            filterValue = value.toLowerCase();
        }

        return this.allStatuses.filter(status => status.name.toLowerCase().indexOf(filterValue) === 0);
    }
}
