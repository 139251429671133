<div class="container fancy-container" [class.last-step]="selectedIndex === 9">
    <mat-tab-group [(selectedIndex)]="selectedIndex" class="hide-tabs" (selectedIndexChange)="onStepChange()" *ngIf="workOrderSummary">
        <mat-tab>


            <h2 class="sub-heading" bottommargin20>Item Selection</h2>
            <button mat-button *ngFor="let item of workOrderSummary.lines" class="toggle-button line-selection"
                [class.selected]="selectedWorkOrderLine && item.id === selectedWorkOrderLine.id"
                (click)="selectLine(item)">
                <img [src]="getItemThumbnailUrl(item)"><br>
                {{item.itemName}}
            </button>
        </mat-tab>
        <mat-tab>
            <h2 class="sub-heading" bottommargin20>Item Type</h2>
            <button mat-button *ngFor="let item of itemTypes" block class="toggle-button block-button"
                [class.selected]="item.id === authoArgs.itemTypeId" (click)="selectItem(item)">
                <mat-icon>chevron_right</mat-icon>
                {{item.name}}
            </button>

            <h2 class="sub-heading" *ngIf="itemLocations && itemLocations.length > 0">Location</h2>

            <button mat-button *ngFor="let item of itemLocations" [class.selected]="item.id === authoArgs.locationId"
                (click)="selectItemLocation(item)" class="toggle-button">
                {{item.name}}
            </button>
            <div topmargin40>
                <app-authorization-photos imagePrefix="general" [(attachments)]="authoArgs.photos">
                </app-authorization-photos>
            </div>
        </mat-tab>

        <mat-tab>
            <h2 class="sub-heading" bottommargin20>Problems</h2>
            <mat-form-field appearance="outline" style="max-width: 300px">
                <mat-label>
                    <mat-icon>filter_alt</mat-icon>
                    Filter
                </mat-label>
                <input matInput [(ngModel)]="problemFilter" (ngModelChange)="doProblemFilter($event)">
            </mat-form-field>
            <div *ngFor="let problem of filteredWorkOrderProblems" class="problem-container">
                <mat-slide-toggle class="problem" [(ngModel)]="problem.selected">{{problem.name}}
                </mat-slide-toggle>
            </div>
        </mat-tab>
        <mat-tab>
            <h2 class="sub-heading" bottommargin20>Cause</h2>

            <button mat-button class="toggle-button" style="text-align: left;width: 400px;" block bottommargin10
                (click)="authoArgs.causeId = cause.id" *ngFor="let cause of causes"
                [class.selected]="authoArgs.causeId === cause.id">
                <mat-icon>chevron_right</mat-icon>
                {{cause.name}}
            </button>
            <app-authorization-photos *ngIf="authoArgs.causeId && !isFirstCause" imagePrefix="cause"
                [(attachments)]="authoArgs.photos">
            </app-authorization-photos>
        </mat-tab>
        <mat-tab>


            <div *ngIf="coverageLanguage && coverageLanguage.coveredQuantityQuestion" topmargin40 bottommargin20>
                <h2 class="sub-heading" bottommargin20>{{coverageLanguage.coveredQuantityQuestion}}</h2>
                <mat-form-field appearance="outline" topmargin10 style="max-width: 200px;">
                    <mat-label>Quantity</mat-label>
                    <input matInput [(ngModel)]="authoArgs.coveredQuantity" type="number">
                </mat-form-field>
            </div>

            <h2 class="sub-heading" bottommargin20>Did this problem predate
                {{workOrderSummary.effectiveDate |
                date:
                'shortDate'}}</h2>
            <button mat-button mat-raised-button class="toggle-button"
                [class.selected]="authoArgs.preExistingConditions === 'No'"
                (click)="authoArgs.preExistingConditions = 'No'">
                No
            </button>
            <button style="margin-left: 20px;margin-right: 20px;" mat-button mat-raised-button class="toggle-button"
                [class.selected]="authoArgs.preExistingConditions === 'Yes'"
                (click)="authoArgs.preExistingConditions = 'Yes'">
                Yes
            </button>
            <button mat-button mat-raised-button class="toggle-button"
                [class.selected]="authoArgs.preExistingConditions === 'Unknown'"
                (click)="authoArgs.preExistingConditions = 'Unknown'">
                Unknown
            </button>

            <div topmargin40
                *ngIf="authoArgs.preExistingConditions === 'Unknown' ||  authoArgs.preExistingConditions === 'Yes'">
                <div class="data-label" bottommargin10>
                    Please provide any photos that show evidence the problem may have existed before
                    {{workOrderSummary.effectiveDate |
                    date:
                    'shortDate'}}.
                </div>
                <app-authorization-photos imagePrefix="preexisting" [(attachments)]="authoArgs.photos">
                </app-authorization-photos>
            </div>
        </mat-tab>
        <mat-tab>
            <h2 class="sub-heading" bottommargin20>Is This Covered By Manufacturer's Warranty?</h2>
            <button mat-button mat-raised-button class="toggle-button"
                [class.selected]="authoArgs.coveredByMfgWarranty === 'No'"
                (click)="authoArgs.coveredByMfgWarranty = 'No'">
                No
            </button>
            <button style="margin-left: 20px;margin-right: 20px;" mat-button mat-raised-button class="toggle-button"
                [class.selected]="authoArgs.coveredByMfgWarranty === 'Yes'"
                (click)="authoArgs.coveredByMfgWarranty = 'Yes'">
                Yes
            </button>
            <button mat-button mat-raised-button class="toggle-button"
                [class.selected]="authoArgs.coveredByMfgWarranty === 'Unknown'"
                (click)="authoArgs.coveredByMfgWarranty = 'Unknown'">
                Unknown
            </button>

            <div style="max-width: 300px;" topmargin40
                *ngIf="authoArgs.coveredByMfgWarranty === 'Yes' || authoArgs.coveredByMfgWarranty === 'Unknown'">

                <mat-form-field appearance="outline">
                    <mat-label>Name</mat-label>
                    <input matInput [(ngModel)]="authoArgs.manufacturer">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Age</mat-label>
                    <input matInput [(ngModel)]="authoArgs.ageOfUnit">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Model #</mat-label>
                    <input matInput [(ngModel)]="authoArgs.modelNumber">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Serial #</mat-label>
                    <input matInput [(ngModel)]="authoArgs.serialNumber">
                </mat-form-field>
            </div>
        </mat-tab>
        <mat-tab>
            <h2 class="sub-heading" bottommargin20>
                In Your Professional Judgement, can this item be repaired or does it need to be replaced?
            </h2>
            <button mat-button mat-raised-button class="toggle-button"
                [class.selected]="authoArgs.recommendation === 'Repair'" (click)="authoArgs.recommendation = 'Repair'">
                Repair
            </button>
            <button style="margin-left: 20px;margin-right: 20px;" mat-button mat-raised-button class="toggle-button"
                [class.selected]="authoArgs.recommendation === 'Replace'"
                (click)="authoArgs.recommendation = 'Replace'">
                Replace
            </button>

            <div topmargin40 *ngIf="authoArgs.recommendation === 'Repair'">
                <h2 class="sub-heading" bottommargin20>Can the repair be completed today?</h2>
                <button mat-button mat-raised-button class="toggle-button"
                    [class.selected]="authoArgs.canBeRepairedToday === true"
                    (click)="authoArgs.canBeRepairedToday = true">
                    Yes
                </button>
                <button style="margin-left: 20px;margin-right: 20px;" mat-button mat-raised-button class="toggle-button"
                    [class.selected]="authoArgs.canBeRepairedToday === false"
                    (click)="authoArgs.canBeRepairedToday = false">
                    No
                </button>

                <div topmargin40 style="max-width: 300px;">
                    <app-date-picker *ngIf="authoArgs.canBeRepairedToday === false"
                        [(date)]="authoArgs.estimatedRepairDate" placeholder="Estimated Repair Date">
                    </app-date-picker>
                </div>

            </div>
            <div topmargin40 *ngIf="authoArgs.recommendation === 'Replace'">


                <h2 class="sub-heading" bottommargin20>Replacement Reason</h2>

                <div style="max-width: 400px">
                    <button mat-button style="text-align: left;padding-left: 0;" block
                        *ngFor="let replacementReason of replaceReasons" class="toggle-button"
                        [class.selected]="replacementReason.id === authoArgs.reasonForTheReplacementId"
                        (click)="authoArgs.reasonForTheReplacementId = replacementReason.id">
                        <mat-icon>chevron_right</mat-icon>
                        {{replacementReason.name}}
                    </button>
                </div>

                <div topmargin40>
                    <div class="data-label" bottommargin10>
                        Please provide any photos that show evidence of the need for replacement
                    </div>
                    <app-authorization-photos imagePrefix="replacement" [(attachments)]="authoArgs.photos">
                    </app-authorization-photos>
                </div>
            </div>

        </mat-tab>
        <mat-tab>

            <div class="data-label">Repair Total</div>
            <h1 class="big-data">{{authorizationTotal | currency}}</h1>

            <button mat-button mat-raised-button mobile-block (click)="addItem()">
                <mat-icon>add</mat-icon> Add Repair Item
            </button>

            <div *ngFor="let item of authoArgs.authorizationLines" (click)="editItem(item)" class="autho-item" aflex>
                <div>
                    <div>{{item.description}}</div>
                    <div>
                        Qty: {{item.quantity}} &#64; {{item.unitPrice | currency}} = {{item.subTotal | currency}}
                    </div>
                </div>
                <div nogrow style="text-align: right;">
                    <button mat-button (click)="removeAuthoItem(item)">
                        <mat-icon>delete</mat-icon>
                    </button>
                    <br *ngIf="item.needsReview">
                    <span class="badge" *ngIf="item.needsReview">Needs Review</span>
                </div>
            </div>
        </mat-tab>

        <mat-tab>
            <ng-container *ngIf="authoArgs && authoArgs.technicianContact">
                <div class="data-label">Repair Total</div>
                <h1 class="big-data">{{authorizationTotal | currency}}</h1>

                <div class="data-label" topmargin40>Enter your contact information</div>

                <div aflex>
                    <div nogrow>
                        <mat-slide-toggle [(ngModel)]="authoArgs.technicianContact.contactViaSMS">SMS
                        </mat-slide-toggle>
                    </div>
                    <div>
                        <mat-form-field appearance="outline">
                            <input matInput type="tel" [(ngModel)]="authoArgs.technicianContact.smsNumber" mask="(000) 000-0000"
                                [disabled]="!authoArgs.technicianContact.contactViaSMS">
                        </mat-form-field>
                    </div>
                </div>
                <div aflex>
                    <div nogrow>
                        <mat-slide-toggle [(ngModel)]="authoArgs.technicianContact.contactViaEmail">Email
                        </mat-slide-toggle>
                    </div>
                    <div>
                        <mat-form-field appearance="outline">
                            <input matInput [(ngModel)]="authoArgs.technicianContact.email"
                                [disabled]="!authoArgs.technicianContact.contactViaEmail">
                        </mat-form-field>
                    </div>
                </div>
                <div aflex>
                    <div nogrow>
                        <mat-slide-toggle [(ngModel)]="authoArgs.technicianContact.contactViaPhone">Phone
                        </mat-slide-toggle>
                    </div>
                    <div>
                        <mat-form-field appearance="outline">
                            <input matInput type="tel" [(ngModel)]="authoArgs.technicianContact.phoneNumber" mask="(000) 000-0000"
                                [disabled]="!authoArgs.technicianContact.contactViaPhone">
                        </mat-form-field>
                    </div>
                </div>

                <button mat-button mat-raised-button color="primary" mobile-block large (click)="authorize()"
                    [disabled]="!canAuthorize">
                    <mat-spinner *ngIf="authorizing" class="thirty"></mat-spinner>
                    Authorize
                </button>
            </ng-container>
        </mat-tab>
        <mat-tab>
            <div class="padding center" *ngIf="authorizationResult">


                <div *ngIf="authorizationResult && !authorizationResult.approved">
                    <app-review-circle-animated height="150px">
                    </app-review-circle-animated>
                    <h1 class="sub-heading" topmargin40>Under Review</h1>
                    <p topmargin10>Don't worry, wer're on it. We just need to review a few things. We will contact you when we've
                        reached a decision or need more information.</p>

                </div>
                <div *ngIf="authorizationResult && authorizationResult.approved">
                    <app-check-circle-animated height="150px"
                        *ngIf="authorizationResult && authorizationResult.approved">
                    </app-check-circle-animated>
                    <h1 class="sub-heading" topmargin40>Approved</h1>
                    <p topmargin10>Please proceed with the repairs</p>
                </div>


                <ul *ngIf="authorizationResult && authorizationResult.warnings" class="autho-validation-list">
                    <li *ngFor="let warning of authorizationResult.warnings">
                        <div aflex>
                            <div nogrow>
                                <mat-icon>warning</mat-icon>
                            </div>
                            <div>
                                {{warning.message}}
                            </div>
                        </div>
                    </li>
                </ul>

                <a mat-button mat-raised-button mobile-block [href]="statementOfWorkUrl" target="_blank" style="margin-bottom: 20px;">
                    <mat-icon>assignment</mat-icon>
                    Statement Of Work
                </a>

                <a mat-button mat-raised-button mobile-block href="/work-order-detail/{{workOrderSummary.id}}" style="margin-bottom: 20px;">Back To Job</a>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
<button mat-button id="toggle-detail-button" title="Show job detail" class="toggle-button"
    [class.selected]="showDetailSidePanel" (click)="showDetailSidePanel = !showDetailSidePanel">
    <mat-icon>build</mat-icon>
</button>

<div id="detail-backdrop" *ngIf="showDetailSidePanel" (click)="showDetailSidePanel = false"></div>
<div id="detail-side-panel" *ngIf="showDetailSidePanel" [class.last-step]="selectedIndex === 9">
    <my-claim-summary [noCards]="true" [firstEffectiveDate]="firstEffectiveDate" [workOrderSummary]="workOrderSummary"
        [claimSummaryExpanded]="true">
    </my-claim-summary>
</div>

<div id="bottom-toolbar" *ngIf="selectedIndex < 9">
    <div>

        <div aflex>
            <div nogrow>
                <app-circle-widget-small [percent]="pctComplete" [height]="30"></app-circle-widget-small>

            </div>
            <div style="margin-left: 5px;">

                <h3 style="font-size: 13px;margin: 0">{{currentStepName}}</h3>
                <div style="font-size: 10px;">Next: {{nextStepName}}</div>
            </div>
            <div nogrow>
                <button mat-button mat-raised-button [disabled]="selectedIndex === 0" (click)="back()"
                    style="margin-right: 5px;">
                    <mat-icon>chevron_left</mat-icon>
                </button>
                <button mat-button mat-raised-button color="primary" *ngIf="hasNextButton" [disabled]="!canGoNext"
                    (click)="next()">
                    Next <mat-icon>chevron_right</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>