<div flex>
    <div id="technician-list">
        <div>
            <button mat-button block (click)="addNewUser()" style="width: 200px; height: 55px; margin-left:60px">
                <i class="material-icons" style="margin-right:5px">add</i>Add Contact</button>
        </div>

        <div *ngFor="let group of groups">
            <div class="flex-col" style="width:100%; justify-content: start;">
                <div class="data-label" style="margin-top:18px; font-size: 12pt;">
                    {{group.name}}
                </div>
                <div>
                    <button mat-button class="left-button" (click)="selectEmployee(employee)"
                        [class.active]="selected && employee.id == selected.id"
                        *ngFor="let employee of group.employees">
                        <div class="flex-row" style="margin-top:12px">
                            <img mat-list-avatar src="{{getEntityThumbnailUrl(employee.id)}}" style="margin-top:1px">
                            <div class="flex-col" style="margin-left: 14px; margin-top:-13px">
                                <h3 matLine *ngIf="employee.lastName || employee.firstName">
                                    {{employee.lastName}},
                                    {{employee.firstName}}</h3>
                                <h3 matLine *ngIf="!employee.lastName && !employee.firstName">(New
                                    {{group.newName}})</h3>
                                <!-- <h3 matLine>{{employee.email}}</h3> -->
                                <span style="margin-top:-12px; font-weight: lighter;" matLine>
                                    created by: {{employee.createdByName}}</span>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div padding>
        <div *ngIf="selected" class="slide-in-right" style="max-width: 1500px;">
            <div pull-right>
                <button mat-button large [matMenuTriggerFor]="menu"><i class="material-icons">more_vert</i></button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="deleteTechnician()">Delete</button>
                </mat-menu>
            </div>
            <div clearfix></div>
            <mat-card id="mainCard">
                <div flex>
                    <div nogrow>
                        <app-swapable-image #swapableImage [(url)]="url[selected.id]">
                        </app-swapable-image>
                    </div>
                    <div>
                        <app-basic-entity-entry showFirstAndLastName="true" [hideAddressEntry]="true"
                            [hideTimezoneEntry]="true" [hideInactiveBox]="true" [hidePreferreds]="true"
                            [hideContactMethodsEntry]="true" [hidePaperless]="true" [entity]="selected"
                            showPhoneNumberEntry="true">

                        </app-basic-entity-entry>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>