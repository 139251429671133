import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { PolicyApiService } from '@cogent/client/shared/services/api/policy-api.service';
import { TechnicianContact } from '@cogent/shared/models/authorizations/technician-contact.model';
import { WorkOrderLineSummaryClient } from '@cogent/shared/models/service/work-order-line-summary.model';
import { AuthorizationRequestArgs } from '@cogent/shared/models/authorizations/authorization-request-args.model';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { ApiService } from '@cogent/client/api';
import { AuthorizationsApiService } from '@cogent/client/shared/services/api/authorizations-api.service';
import { AuthorizationRequestResult } from '@cogent/shared/models/authorizations/authorization-request-result.model';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { AddClosingItemComponent } from '../add-closing-item/add-closing-item.component';
import { PolicyServiceOffering, ProblemCause, RepairItem, ReplacementReason, WorkOrderItemLocation, WorkOrderItemProblem, WorkOrderItemProblemType, WorkOrderItemType, WorkOrderLine } from '@upkeeplabs/models/cogent';
import { AuthorizationLine } from '@cogent/shared/models/authorizations/authorization-line.model';

@Component({
    selector: 'app-authorize',
    templateUrl: './authorize.component.html',
    styleUrls: ['./authorize.component.css']
})
export class AuthorizeComponent implements OnInit {
    workOrderSummary: WorkOrderSummaryClient;
    itemTypes: WorkOrderItemType[];
    itemLocations: WorkOrderItemLocation[];
    problems: WorkOrderItemProblem[];
    causes: ProblemCause[];
    replaceReasons: ReplacementReason[];
    selectedIndex = 0;
    problemFilter = '';
    authoItems: RepairItem[];
    modalVisible = false;
    minRepairDate = new Date();
    authorizing = false;
    authorizationResult: AuthorizationRequestResult;
    selectedWorkOrderLine: WorkOrderLine;
    authoComplete = false;
    problemTypes: WorkOrderItemProblemType[];
    authoArgs: AuthorizationRequestArgs;
    filteredWorkOrderProblems: WorkOrderItemProblem[];
    coverageLanguage: PolicyServiceOffering;
    diagnosisAmount = 0;
    showDetailSidePanel = false;
    firstEffectiveDate: Date;

    steps = [
        'Line Selection',
        'Coverage Item Determination',
        'Problem Determination',
        'Cause Of Problem',
        'Timing',
        'Manufacturers Warranty',
        'Remedy',
        'Repair Items',
        'Review'
    ];

    constructor(private activatedRoute: ActivatedRoute,
        private policyApi: PolicyApiService,
        private authorizationApi: AuthorizationsApiService,
        private entityApi: EntityApiService,
        private dialog: MatDialog,
        private missionService: MissionService,
        private serviceApi: ServiceApiService) { }

    async ngOnInit() {
        this.authoArgs = new AuthorizationRequestArgs();
        this.authoArgs.photos = [];
        this.authoArgs.authorizationLines = [];
        this.authoArgs.problemIds = [];

        this.activatedRoute.params.subscribe(params => {
            if (params.id) {
                this.serviceApi.getWorkOrderSummary(params.id).then(workOrderSummary => {
                    this.workOrderSummary = workOrderSummary;
                    this.serviceApi.getAnonymousFirstEffectiveDate(this.workOrderSummary.policyId).then(firstEffectiveDate => {
                        this.firstEffectiveDate = firstEffectiveDate;
                    });
                    if (!this.workOrderSummary.itemId && this.workOrderSummary.lines) {
                        this.workOrderSummary.itemId = this.workOrderSummary.lines[0].itemId;
                    }
                    const uncancelledLines = this.workOrderSummary.lines.filter(i => !i.cancelledDate);
                    if (uncancelledLines.length < 2) {
                        this.selectedIndex = 1;
                        this.selectLine(uncancelledLines[0] as any);
                    } else {
                        this.selectedIndex = 0;
                    }
                    this.onStepChange();

                    this.serviceApi.getReplacementReasons().then(results => {
                        this.replaceReasons = results as any;
                    });
                    if (this.selectedWorkOrderLine) {
                        this.loadRepairItems();
                    }
                });
            }
        });

        const user = await this.entityApi.getLoggedInUser();
        const fullUser = await this.entityApi.getFullEntity(user.id);
        if (localStorage.getItem('technician-contact-information')) {
            try {
                this.authoArgs.technicianContact = JSON.parse(localStorage.getItem('technician-contact-information'));
            } catch (e) {
                this.authoArgs.technicianContact = new TechnicianContact();
            }
        } else {
            this.authoArgs.technicianContact = new TechnicianContact();
            this.authoArgs.technicianContact.name = user.name;
            this.authoArgs.technicianContact.email = fullUser.email;
            this.authoArgs.technicianContact.smsNumber = fullUser.mobileNumberNumber;
            this.authoArgs.technicianContact.phoneNumber = fullUser.mobileNumberNumber;
        }

        const tomorrow = UtilitiesService.dayBegin(new Date());
        tomorrow.setDate(tomorrow.getDate() + 1);
        this.minRepairDate = tomorrow;
        this.authoArgs.estimatedRepairDate = new Date(this.minRepairDate);
    }

    toggleProblem(problem) {
        problem.selected = !problem.selected;
    }

    removeAuthoItem(item: AuthorizationLine) {
        item.deleting = true;

        this.authoArgs.authorizationLines.splice(this.authoArgs.authorizationLines.indexOf(item), 1);
    }

    selectLine(line: WorkOrderLine) {
        this.selectedWorkOrderLine = line;

        this.serviceApi.getWorkOrderItemTypes(line.itemId).then(results => {
            this.itemTypes = results;
        });
        this.serviceApi.getWorkOrderItemLocations(line.itemId).then(results => {
            this.itemLocations = results;
        });
        this.serviceApi.getWorkOrderItemProblems(line.itemId).then(results => {
            this.problems = results;
            this.filteredWorkOrderProblems = results;
        });
        this.serviceApi.getWorkOrderItemCauses(line.itemId).then(results => {
            this.causes = results as any;
        });
        this.policyApi.getWorkOrderItemDetailByWorkOrderLine(line.id).then(contractLanguage => {
            this.coverageLanguage = contractLanguage;
        });
    }

    get isFirstCause() {
        return this.authoArgs.causeId && this.causes.find(i => i.id == this.authoArgs.causeId).sort === 0;
    }

    get collectPhotosCause() {
        if (!this.authoArgs.causeId) {
            return false;
        }

        const cause = this.causes.find(i => i.id === this.authoArgs.causeId);
        if (cause) {
            return cause.flagForReview;
        }
    }

    get authorizationTotal() {
        if (!this.authoArgs.authorizationLines || this.authoArgs.authorizationLines.length === 0) {
            return 0;
        }

        return this.authoArgs.authorizationLines.map(i => i.quantity * i.unitPrice).reduce((a, b) => a + b);
    }

    get canAuthorize() {
        if (this.authorizing) {
            return false;
        }
        if (!this.authoArgs.technicianContact.contactViaEmail
            && !this.authoArgs.technicianContact.contactViaPhone
            && !this.authoArgs.technicianContact.contactViaSMS) {
            return false;
        }

        if (this.authoArgs.technicianContact.contactViaEmail && !UtilitiesService.validateEmail(this.authoArgs.technicianContact.email)) {
            return false;
        }

        if (this.authoArgs.technicianContact.contactViaPhone && !UtilitiesService.validatePhoneNumber(this.authoArgs.technicianContact.phoneNumber)) {
            return false;
        }

        if (this.authoArgs.technicianContact.contactViaSMS && !UtilitiesService.validatePhoneNumber(this.authoArgs.technicianContact.smsNumber)) {
            return false;
        }

        return true;
    }

    get underReview() {
        return this.authorizationResult && !this.authorizationResult.approved;
    }

    get hasNextButton() {
        return this.selectedIndex < 8;
    }

    get isFinished() {
        return this.selectedIndex === 9;
    }

    get outOfPocket() {
        return 0;
    }

    addItem() {
        const ref = this.dialog.open(AddClosingItemComponent, {
            data: {
                workOrderSummary: this.workOrderSummary,
                authoItems: this.authoItems
            }
        });
        ref.afterClosed().subscribe(results => {
            if (results) {
                this.authoArgs.authorizationLines.push(results);
            }
        });
    }

    editItem(item: AuthorizationLine) {
        const ref = this.dialog.open(AddClosingItemComponent, {
            data: {
                workOrderSummary: this.workOrderSummary,
                authoItems: this.authoItems,
                editItem: item
            }
        });
        ref.afterClosed().subscribe(results => {
            const currentItem = this.authoArgs.authorizationLines.find(i => i.id === results.id);
            if (currentItem) {
                const index = this.authoArgs.authorizationLines.indexOf(currentItem);
                this.authoArgs.authorizationLines.splice(index, 1, results);
            }
        });
    }

    get progress() {
        return ((this.selectedIndex + 1) / this.steps.length) * 100;
    }

    get currentStep() {
        return this.steps[this.selectedIndex];
    }

    get nextStep() {
        return this.steps[this.selectedIndex + 1];
    }

    back() {
        this.selectedIndex--;
    }

    private loadRepairItems() {
        this.serviceApi.findForWorkOrderLineId(this.selectedWorkOrderLine.id).then(repairItems => {
            this.authoItems = repairItems.filter(i => !i.inactive);
        });
    }

    next() {
        if (this.selectedIndex === 0 && this.selectedWorkOrderLine) {
            this.loadRepairItems();
        }
        if (this.selectedIndex === 1) {
            this.getProblemsFromType();
        }
        if (this.selectedIndex === 6) {
            this.getProblemRepairItems();
        }
        this.selectedIndex++;
    }

    private getProblemsFromType() {
        this.serviceApi.getProblemsByWorkOrderType(this.authoArgs.itemTypeId).then(problemTypes => {
            this.problemTypes = problemTypes;
        });
    }

    @HostListener('window:hashchange', ['$event'])
    watchUrlHash() {
        let hash = window.location.hash;
        if (!hash) {
            this.selectedIndex = 0;
            return;
        }

        hash = hash.replace('#', '');
        const stepNumber = parseInt(hash, 10);
        if (!isNaN(stepNumber)) { this.selectedIndex = stepNumber; }
    }

    async onStepChange() {
        window.location.hash = this.selectedIndex.toString();
    }

    private getProblemRepairItems() {
        const problemIds = this.problems.filter(i => i.selected).map(i => i.id);

        this.serviceApi.getRepairItemsFromProblems(problemIds, this.authoArgs.recommendation === 'Replace', this.authoArgs.itemTypeId).then(repairItems => {
            this.authoArgs.authorizationLines = [];
            
            for (const repairItem of repairItems) {
                const line = new AuthorizationLine();
                line.id = UtilitiesService.newid();
                line.description = repairItem.name;
                line.guideline = repairItem.amount;
                line.quantity = 1;
                line.repairItemId = repairItem.id;
                line.unitPrice = repairItem.amount;
                line.workOrderId = this.workOrderSummary.id;
                line.yellowRangePercent = repairItem.yellowRangePercent;
                line.needsReview = repairItem.attributes && repairItem.attributes.length > 0;
                line.repairItem = repairItem;
                line.pricingType = 'Flat';

                this.authoArgs.authorizationLines.push(line);
            }
        });
    }

    get pctComplete() {
        return ((this.selectedIndex + 1) / this.steps.length) * 100;
    }

    get currentStepName() {
        return this.steps[this.selectedIndex];
    }

    get nextStepName() {
        return this.steps[this.selectedIndex + 1];
    }



    get canGoNext() {
        if (this.selectedIndex === 0) {
            return this.selectedWorkOrderLine ? true : false;
        }
        if (this.selectedIndex === 1) {
            // return ((this.authoArgs.locationId || (this.itemLocations && this.itemLocations.length === 0)) && this.authoArgs.itemTypeId) ? true : false;
            return ((this.authoArgs.locationId || (this.itemLocations && this.itemLocations.length === 0)) && (this.authoArgs.itemTypeId || (this.itemTypes && this.itemTypes.length === 0))) ? true : false;

        }
        if (this.selectedIndex === 2) {
            return this.problems.filter(i => i.selected).length > 0;
        }
        if (this.selectedIndex === 3) {
            return (this.authoArgs.causeId) ? true : false;
        }
        if (this.selectedIndex === 4) {
            if (this.coverageLanguage && this.coverageLanguage.coveredQuantityQuestion && !this.authoArgs.coveredQuantity) return false;
            return (this.authoArgs.preExistingConditions) ? true : false;
        }
        if (this.selectedIndex === 5) {
            return (this.authoArgs.coveredByMfgWarranty) ? true : false;
        }
        if (this.selectedIndex === 6) {
            if (!this.authoArgs.recommendation) {
                return false;
            }
            if (this.authoArgs.recommendation === 'Repair') {
                if (this.authoArgs.canBeRepairedToday === false) {
                    return this.authoArgs.estimatedRepairDate ? true : false;
                } else if (this.authoArgs.canBeRepairedToday === true) {
                    return true;
                } else {
                    return false;
                }
            }
            if (this.authoArgs.recommendation === 'Replace') {
                return this.authoArgs.reasonForTheReplacementId ? true : false;
            }
            return (this.authoArgs.recommendation) ? true : false;
        }
        return true;
    }

    private problemTimeout;
    doProblemFilter(filter) {
        this.problemFilter = filter;

        clearTimeout(this.problemTimeout);
        this.problemTimeout = setTimeout(() => {
            this.filteredWorkOrderProblems = this.problems.filter(i => i.name.toLowerCase().indexOf(this.problemFilter.toLowerCase()) > -1);
        }, 1000);
    }


    selectItem(itemType) {
        this.authoArgs.itemTypeId = itemType.id;
    }

    selectItemLocation(itemLocation) {
        this.authoArgs.locationId = itemLocation.id;
    }

    selectCause(cause) {
        this.authoArgs.causeId = cause.id;
    }

    showAllProblems = false;

    get linesTotalOutOfPocket() {
        let total = 0;
        if (this.authoArgs && this.authoArgs.authorizationLines) {
            for (const line of this.authoArgs.authorizationLines) {

                let lineTotal = 0;
                if (line.isOutOfPocket) {
                    lineTotal = line.total;
                } else if (line.limit && line.quantity != 0 && line.limit < line.total / line.quantity) {
                    lineTotal = line.total - (line.limit * line.quantity);
                }

                if (line.overallLimit != null && line.overallLimit && (line.unitPrice * line.quantity) > line.overallLimit) {
                    lineTotal = (line.unitPrice * line.quantity) - line.overallLimit;
                }
                if (line.unitLimit != null && line.unitPrice > line.unitLimit) {
                    lineTotal = (line.unitPrice - line.unitLimit) * line.quantity;
                }
                total += lineTotal;
            }
        }

        const linesTotal = this.linesTotal;
        const limits = this.limits;
        if (linesTotal > limits) {
            total += linesTotal - limits;
        }
        return total;
    }

    get limits() {
        let limit = 100000000;
        if (!this.authorizationResult || !this.authorizationResult.warnings) {
            return limit;
        }

        const activeWarnings = this.authorizationResult.warnings.filter(i => !i.overrideAndApprove && i.limit != null && i.limit >= 0);
        if (activeWarnings.length === 0) {
            return limit;
        }

        for (const warning of activeWarnings) {
            if (warning.limit >= 0 && warning.limit < limit) {
                limit = warning.limit;
            }
        }

        return limit;
    }

    get linesTotal() {
        let total = 0;
        if (this.authoArgs && this.authoArgs.authorizationLines) {
            for (const line of this.authoArgs.authorizationLines) {
                if (!line.isOutOfPocket) {
                    let lineTotal = 0;
                    if (line.limit && line.quantity != 0 && line.limit < line.total / line.quantity) {
                        lineTotal = line.limit * line.quantity;
                    } else {
                        lineTotal = line.total;
                    }

                    if (line.overallLimit != null && line.overallLimit && lineTotal > line.overallLimit) {
                        lineTotal = line.overallLimit;
                    }
                    if (line.unitLimit != null && line.unitPrice > line.unitLimit) {
                        lineTotal = line.unitLimit * line.quantity;
                    }
                    total += lineTotal;
                }
            }
        }

        return total;
    }

    get filteredProblems() {
        if (!this.problems) {
            return [];
        }
        if (this.showAllProblems || !this.problemTypes) {
            return this.problems.sort((a, b) => a.sort > b.sort ? 1 : -1);
        }

        if (this.showAllProblems) {
            return this.problems.filter(i => i.name.toLowerCase().indexOf(this.problemFilter.toLowerCase()) > -1).sort((a, b) => a.sort > b.sort ? 1 : -1);
        } else {
            const ids = this.problemTypes.filter(i => i.workOrderItemTypeId === this.authoArgs.itemTypeId).map(i => (i as any).workORderItemProblemId ? (i as any).workORderItemProblemId : i.workOrderItemProblemId);

            return this.problems.filter(i => ids.indexOf(i.id) > -1).filter(i => i.name.toLowerCase().indexOf(this.problemFilter.toLowerCase()) > -1).sort((a, b) => a.sort > b.sort ? 1 : -1);
        }
    }

    get statementOfWorkUrl() {
        return `${ApiService.endPointDotNet}WorkOrder/${this.selectedWorkOrderLine.id}/statement-of-work/pdf`;
    }

    getItemThumbnailUrl(line: WorkOrderLineSummaryClient) {
        return this.serviceApi.getItemThumbnailUrl(line.itemId);
    }

    get linesTotalDisplay() {
        if (!this.authoArgs) {
            return 0;
        }
        if (this.authoArgs.overrideAuthoAmount) {
            return this.authoArgs.overrideAuthoAmount;
        }
        const linesTotal = this.linesTotal;
        const limits = this.limits;
        if (linesTotal > limits) {
            if (limits < this.diagnosisAmount) {
                return this.diagnosisAmount;
            }
            return limits;
        }
        if (linesTotal < this.diagnosisAmount) {
            return this.diagnosisAmount;
        }
        return linesTotal;
    }

    authorize() {
        localStorage.setItem('technician-contact-information', JSON.stringify(this.authoArgs.technicianContact));
        this.authorizing = true;
        // this.serviceApi.processAuthorizationRequest(this.selectedWorkOrderLine.id, this.authoArgs).then(results => {
        //     this.authorizing = false;
        //     this.next();
        //     this.authorizationResult = results;
        //     this.missionService.raiseGlobalMessage('JOB-UPDATED');
        //     this.authoComplete = true;
        // });
        this.authoArgs.validateType = true;
        this.authoArgs.validateProblem = true;
        this.authoArgs.validateCause = true;
        this.authoArgs.validatePreExisting = true;
        this.authoArgs.validateMfgWarranty = true;
        this.authoArgs.validateRepairItems = true;
        this.authoArgs.validatePlanTotals = true;
        this.authoArgs.saveAutho = true;

        if (this.problems) {
            this.authoArgs.problemIds = this.problems.filter(i => i.selected).map(i => i.id);
        }
        this.serviceApi.processAuthorizationRequest(this.selectedWorkOrderLine.id, this.authoArgs).then(async results => {

            this.authoArgs.result = results;
            this.authoArgs.result.calculatedOutOfPocket = this.linesTotalOutOfPocket;
            this.selectedWorkOrderLine.authorizationRequestArgs = JSON.stringify(this.authoArgs);
            if (this.authoArgs.result.approved) {
                this.selectedWorkOrderLine.authorizationAmount = this.linesTotalDisplay;
                this.selectedWorkOrderLine.authorizationLimit = this.linesTotalDisplay;
                this.selectedWorkOrderLine.authorizationApprovedDate = new Date();
                this.selectedWorkOrderLine.workOrderStatusId = 'C9F2C34D-36FD-4F36-BE31-41160EF3F11F';
            }
            await this.serviceApi.saveWorkOrderLine(this.selectedWorkOrderLine);
            this.authorizing = false;
            this.next();
            this.authorizationResult = results;
            this.missionService.raiseGlobalMessage('JOB-UPDATED');
            this.authoComplete = true;
        });
    }


}
