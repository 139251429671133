<mat-form-field class="example-chip-list" appearance="outline">
    
    <mat-label *ngIf="placeholder">{{placeholder}}</mat-label>
    <mat-chip-grid #chipList>
        <mat-chip-row *ngFor="let trade of selectedTrades"  [removable]="removable"
            (removed)="remove(trade)">
            {{trade.name}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip-row>
        <input autocomplete="off" matInput #tradeInput [formControl]="tradeCtrl"
            [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
    </mat-chip-grid>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let trade of filteredTrades | async" [value]="trade">
            {{trade.name}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
