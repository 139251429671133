import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AuthorizationRepairItem } from '@upkeeplabs/models/cogent';

@Component({
    selector: 'app-get-item-amount-dialog',
    standalone: true,
    imports: [CommonModule, MatDialogModule, MatButtonModule, MatFormFieldModule, FormsModule, ReactiveFormsModule, MatInputModule, MatIconModule],
    templateUrl: './get-item-amount-dialog.component.html',
    styleUrl: './get-item-amount-dialog.component.css'
})
export class GetItemAmountDialogComponent implements AfterViewInit {

    amount: number;
    constructor(public dialogRef: MatDialogRef<GetItemAmountDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AuthorizationRepairItem,) {
        this.amount = data.priceRangeMin;
    }
    ngAfterViewInit(): void {
        document.getElementById('item-amount-box').focus();
    }

    save() {
        this.dialogRef.close(this.amount);
    }

}
