<mat-card>
    <div style="position: relative">
      <mat-card-header>
        <mat-card-title>
          <i class="material-icons" mat-card-avatar>pie_chart_outlined</i> Call Types
        </mat-card-title>
      </mat-card-header>
    </div>
    <mat-card-content>
      <canvas height="200" style="max-height: 350px; max-width: 350px" id="call-breakdown-canvas"></canvas>
    </mat-card-content>
  </mat-card>