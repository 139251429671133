<div id="dialog-container">
    <div aflex mat-dialog-title style="margin: 0;">
        <h2 class="sub-heading">Add Repair Line</h2>
        <div nogrow>
            <button mat-button mat-dialog-close>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <div mat-dialog-content>
        <mat-tab-group class="hide-tabs" [(selectedIndex)]="selectedIndex">
            <mat-tab>

                <mat-form-field appearance="outline">
                    <mat-label>Filter</mat-label>
                    <input matInput [(ngModel)]="filter" (ngModelChange)="startFilter($event)">
                </mat-form-field>
                <div class="group" *ngFor="let group of groupedItems">
                    <div class="group-header">{{group.name}}</div>
                    <button mat-button *ngFor="let item of group.items" class="toggle-button group-button"
                        [class.selected]="selectedRepairItemId === item.id" (click)="selectedRepairItemId = item.id">
                        {{item.name}}
                    </button>
                </div>
            </mat-tab>
            <mat-tab>

                <div *ngIf="newItem">

                    <div *ngFor="let attribute of newItem.attributes">
                        <ng-container *ngIf="!attribute.availableValues || attribute.availableValues.length === 0">
                            <mat-form-field appearance="outline">
                                <mat-label>{{attribute.name}}</mat-label>
                                <input matInput [(ngModel)]="attribute.textValue">
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="attribute.availableValues || attribute.availableValues.length > 0">
                            <mat-form-field topmargin20 appearance="outline">
                                <mat-label>{{attribute.name}}</mat-label>
                                <mat-select [(ngModel)]="attribute.valueId">
                                    <mat-option *ngFor="let value of attribute.availableValues" [value]="value.id">
                                        {{value.value}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <div class="data-label">Pricing Type</div>
                <div class="toolbar">
                    <button mat-button style="text-transform: none;" class="toggle-button"
                        [class.selected]="newItem.pricingType === 'Flat'"
                        (click)="newItem.pricingType = 'Flat'">Flat</button>
                    <button mat-button style="text-transform: none;" class="toggle-button"
                        [class.selected]="newItem.pricingType === 'PartsAndLabor'"
                        (click)="newItem.pricingType = 'PartsAndLabor'">Parts &amp; Labor</button>
                    <button mat-button style="text-transform: none;" class="toggle-button"
                        [class.selected]="newItem.pricingType === 'LaborOnly'"
                        (click)="newItem.pricingType = 'LaborOnly'">Labor Only</button>
                </div>
                <div topmargin20 bottommargin20>
                    <div class="info" *ngIf="newItem.pricingType === 'Flat'">Price includes parts and labor combined
                    </div>
                    <div class="info" *ngIf="newItem.pricingType === 'PartsAndLabor'">Separate fields for parts cost and
                        labor
                        cost</div>
                    <div class="info" *ngIf="newItem.pricingType === 'LaborOnly'">Elevate to provide the parts, your
                        labor
                        cost
                        to complete the repair</div>
                </div>

                <div *ngIf="newItem.pricingType !== 'LaborOnly'" aflex>
                    <button mat-button class="narrow" nogrow (click)="less()">-</button>
                    <mat-form-field appearance="outline" style="max-width: 100px;margin-left: 0;">
                        <mat-label>Quantity</mat-label>
                        <input matInput type="number" [(ngModel)]="newItem.quantity">
                    </mat-form-field>
                    <button mat-button class="narrow" nogrow style="margin-left: 0;"
                        (click)="newItem.quantity = newItem.quantity + 1">+</button>
                </div>
                <div *ngIf="newItem.pricingType !== 'LaborOnly'" aflex>
                    <button mat-button class="narrow" nogrow (click)="lessUnitPrice()">-</button>
                    <mat-form-field style="margin-left: 0;" appearance="outline" style="max-width: 100px;margin-left: 0;">
                        <mat-label>Unit Price</mat-label>
                        <input matInput type="number" [(ngModel)]="newItem.unitPrice">
                    </mat-form-field>
                    <button mat-button class="narrow" nogrow style="margin-left: 0;"
                        (click)="newItem.unitPrice = newItem.unitPrice + 1">+</button>
                </div>
                <div *ngIf="newItem.pricingType !== 'Flat'" aflex>
                    <button mat-button class="narrow" nogrow (click)="newItem.labor = newItem.labor - 1">-</button>
                    <mat-form-field appearance="outline" style="max-width: 100px;margin-left: 0;">
                        <mat-label>Labor</mat-label>
                        <input matInput type="number" [(ngModel)]="newItem.labor">
                    </mat-form-field>
                    <button mat-button class="narrow" style="margin-left: 0;" nogrow (click)="newItem.labor = newItem.labor + 1">+</button>
                </div>


            </mat-tab>
            <mat-tab>

            </mat-tab>
        </mat-tab-group>
    </div>


    <div mat-dialog-actions style="display: block; width: 100%">
        <div *ngIf="selectedIndex === 0">
            <button mat-button mat-raised-button block color="primary" block [disabled]="!selectedRepairItemId"
                (click)="goNext()">
                Next <mat-icon>chevron_right</mat-icon>
            </button>
        </div>

        <div *ngIf="selectedIndex === 1">
            <button mat-button mat-raised-button block color="primary" (click)="selectedIndex = selectedIndex + 1">
                Next <mat-icon>chevron_right</mat-icon>
            </button>
        </div>

        <div *ngIf="selectedIndex === 2">
            <button mat-button mat-raised-button color="primary"  block [disabled]="!canAddItem"
                (click)="addItem()">
                Save Item
            </button>
        </div>
    </div>
</div>