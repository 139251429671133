<mat-form-field class="example-chip-list" appearance="outline">
    <mat-label *ngIf="placeholder">{{placeholder}}</mat-label>
  <mat-chip-grid #chipList>
    <mat-chip-row *ngFor="let item of selectedItems"  [removable]="removable"
      (removed)="remove(item)">
      {{item.name}}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip-row>
    <input #itemInput matInput [formControl]="itemCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="add($event)">
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let item of filteredItems | async" [value]="item">
      {{item.name}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
