import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { CompanyRegion } from '@upkeeplabs/service-pros/app/model/common/company-region.model';
import { Trade } from '@upkeeplabs/service-pros/app/model/common/trade.model';
import { SearchResult } from '@upkeeplabs/service-pros/app/model/common/search-result.model';
import { WorkOrderItemModel } from '@upkeeplabs/service-pros/app/model/claims/WorkOrderItem';
import { ContractorsService } from '@upkeeplabs/service-pros/app/services/contractors.service';
import { LocalSettingsRepositoryService } from '@upkeeplabs/service-pros/app/services/local-settings-repository.service';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { DateRangeType } from '@cogent/shared/models/common/date-range-type.model';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { WorkOrderStatus } from '@upkeeplabs/models/cogent';
 

@Component({
    templateUrl: './work-order-report.component.html',
    styleUrls: ['./work-order-report.component.css'],
})
export class WorkOrderReportComponent implements OnInit {
    startDate = new Date();
    endDate = new Date();
    workOrders: WorkOrderSummaryClient[];
    loading = false;
    groupedItems: any[];
    showJustMine = false;
    reportStartDate: Date;
    reportEndDate: Date;
    columnSelectVisible = false;
    selectedRegions: CompanyRegion[];
    selectedTrades: Trade[];
    selectedWorkOrderStatuses: WorkOrderStatus[];
    selectedCallTypes: string[];
    selectedContractors: SearchResult[];
    selectedEmployees: SearchResult[];
    selectedItems: WorkOrderItemModel[];
    moreCriteria = false;
    editDefinition = false;
    savingDefinition = false;
    savedExpanded = false;
    showDateRange = false;
    selectedRange: DateRangeType;


    selectedRegionsExcluded: CompanyRegion[];
    selectedTradesExcluded: Trade[];
    selectedWorkOrderStatusesExcluded: WorkOrderStatus[];
    selectedCallTypesExcluded: string[];
    selectedContractorsExcluded: SearchResult[];
    selectedEmployeesExcluded: SearchResult[];
    selectedItemsExcluded: WorkOrderItemModel[];
    selectedSLA: string[];
    selectedSLAExcluded: string[];

    constructor(
        private contractorService: ContractorsService,
        private settings: LocalSettingsRepositoryService,
        private entityApi: EntityApiService
    ) { }

    ngOnInit() {
        if (this.settings.getSetting('service-request-report-start-date')) {
            try {
                this.startDate = new Date(
                    this.settings.getSetting('service-request-report-start-date'));
            } catch (e) {
            }
        }

        if (this.settings.getSetting('service-request-report-end-date')) {
            try {
                this.endDate = new Date(
                    this.settings.getSetting('service-request-report-end-date'));
            } catch (e) {
            }
        }
    }

    selectedRangeChange(range: DateRangeType) {
        const dates = UtilitiesService.getDatesFromDateRange(range);
        this.startDate = dates[0];
        this.endDate = dates[1];
        this.showDateRange = false;
    }


    showReportDefinition(data) {

        const param: WorkOrderReportParameters = data.reportObject;

        if (param) {
            this.endDate = param.endDate;
            this.selectedCallTypes = param.selectedCallTypes;
            this.selectedCallTypesExcluded = param.selectedCallTypesExcluded;
            this.selectedContractors = param.selectedContractors;
            this.selectedContractorsExcluded = param.selectedContractorsExcluded;
            this.selectedEmployees = param.selectedEmployees;
            this.selectedEmployeesExcluded = param.selectedEmployeesExcluded;
            this.selectedItems = param.selectedItems;
            this.selectedItemsExcluded = param.selectedItemsExcluded;
            this.selectedRegions = param.selectedRegions;
            this.selectedRegionsExcluded = param.selectedRegionsExcluded;
            this.selectedSLA = param.selectedSLAs;
            this.selectedSLAExcluded = param.selectedSLAsExcluded;
            this.selectedTrades = param.selectedTrades;
            this.selectedTradesExcluded = param.selectedTradesExcluded;
            this.startDate = param.startDate;
        } else {
            this.selectedCallTypes = [];
            this.selectedCallTypesExcluded = [];
            this.selectedContractors = [];
            this.selectedContractorsExcluded = [];
            this.selectedEmployees = [];
            this.selectedEmployeesExcluded = [];
            this.selectedItems = [];
            this.selectedItemsExcluded = [];
            this.selectedRegions = [];
            this.selectedRegionsExcluded = [];
            this.selectedSLA = [];
            this.selectedSLAExcluded = [];
            this.selectedTrades = [];
            this.selectedTradesExcluded = [];
        }
    }

    get showSLAIndicator(): boolean {
        return this.settings.getSetting('srr_showSLAIndicator', 'true') === 'true';
    }
    set showSLAIndicator(value: boolean) {
        this.settings.setSetting('srr_showSLAIndicator', String(value));
    }

    get grouping(): string {
        return this.settings.getSetting('srr_grouping', 'none');
    }
    set grouping(value: string) {
        this.settings.setSetting('srr_grouping', value);
    }

    get showSLA(): boolean {
        return this.settings.getSetting('srr_showSLA', 'true') === 'true';
    }
    set showSLA(value: boolean) {
        this.settings.setSetting('srr_showSLA', String(value));
    }

    get showNumber(): boolean {
        return this.settings.getSetting('srr_showNumber', 'true') === 'true';
    }
    set showNumber(value: boolean) {
        this.settings.setSetting('srr_showNumber', String(value));
    }

    get showDate(): boolean {
        return this.settings.getSetting('srr_showDate', 'true') === 'true';
    }
    set showDate(value: boolean) {
        this.settings.setSetting('srr_showDate', String(value));
    }

    get showItem(): boolean {
        return this.settings.getSetting('srr_showItem', 'true') === 'true';
    }
    set showItem(value: boolean) {
        this.settings.setSetting('srr_showItem', String(value));
    }

    get showProperty(): boolean {
        return this.settings.getSetting('srr_showProperty', 'true') === 'true';
    }
    set showProperty(value: boolean) {
        this.settings.setSetting('srr_showProperty', String(value));
    }

    get showServiceProvider(): boolean {
        return this.settings.getSetting('srr_showServiceProvider', 'true') ===
            'true';
    }
    set showServiceProvider(value: boolean) {
        this.settings.setSetting('srr_showServiceProvider', String(value));
    }

    get showCustomer(): boolean {
        return this.settings.getSetting('srr_showCustomer', 'true') ===
            'true';
    }
    set showCustomer(value: boolean) {
        this.settings.setSetting('srr_showCustomer', String(value));
    }

    get showType(): boolean {
        return this.settings.getSetting('srr_showType', 'true') === 'true';
    }
    set showType(value: boolean) {
        this.settings.setSetting('srr_showType', String(value));
    }

    get showStatus(): boolean {
        return this.settings.getSetting('srr_showStatus', 'true') === 'true';
    }
    set showStatus(value: boolean) {
        this.settings.setSetting('srr_showStatus', String(value));
    }

    get columnCount(): number {
        let count = 0;
        if (this.showNumber) {
            count++;
        }
        if (this.showDate) {
            count++;
        }
        if (this.showItem) {
            count++;
        }
        if (this.showProperty) {
            count++;
        }
        if (this.showServiceProvider) {
            count++;
        }
        if (this.showType) {
            count++;
        }
        if (this.showStatus) {
            count++;
        }
        if (this.showSLA) {
            count++;
        }

        return count;
    }

    loadReport() {
        this.loading = true;
        this.entityApi.getLoggedInUser().then(user => {

            this.contractorService.getWorkOrderReport(
                this.startDate, this.endDate, user.id, this.selectedRegions,
                this.selectedTrades, this.selectedWorkOrderStatuses, this.selectedCallTypes,
                this.selectedItems, this.selectedRegionsExcluded, this.selectedTradesExcluded,
                this.selectedWorkOrderStatusesExcluded, this.selectedCallTypesExcluded,
                this.selectedItemsExcluded, this.selectedSLA, this.selectedSLAExcluded
            ).then(items => {
                items.forEach(item => {
                    if (!item.createdByName) {
                        item.createdByName = 'None';
                    }
                });
                this.workOrders = items;
                this.loading = false;
                this.setGrouping(this.grouping);
                this.reportStartDate = this.startDate;
                this.reportEndDate = this.endDate;
                this.settings.setSetting('service-request-report-start-date',
                    this.startDate.toString());
                this.settings.setSetting('service-request-report-end-date',
                    this.endDate.toString());
            });
        });
    }

    tableToExcel() { UtilitiesService.export(); }

    printReport() { UtilitiesService.printArea(); }

    setGrouping(grouping) {
        this.grouping = grouping;

        this.groupedItems = [];
        let groupColumn = '';
        if (grouping === 'trade') {
            groupColumn = 'tradeName';
        } else if (grouping === 'type') {
            groupColumn = 'type';
        } else if (grouping === 'slaStatus') {
            groupColumn = 'slaStatus';
        } else if (grouping === 'date') {
            groupColumn = 'formattedDate';
        } else if (grouping === 'contractor') {
            groupColumn = 'contractorName';
        } else if (grouping === 'createdByName') {
            groupColumn = 'createdByName';
        } else if (grouping === 'status') {
            groupColumn = 'status';
        } else {
            this.groupedItems.push({
                key: '',
                items: this.workOrders.sort(
                    (item1, item2) => item1.createdDate > item2.createdDate ? 1 : -1)
            });
            return;
        }
        let sortByField = groupColumn;
        if (grouping === 'date') {
            sortByField = 'createdDate';
        }

        this.workOrders.forEach(item => {
            let foundItem =
                this.groupedItems.filter(i => i.key === item[groupColumn])[0];
            if (!foundItem) {
                foundItem = {
                    key: item[groupColumn],
                    sortKey: item[sortByField],
                    items: [],
                };
                this.groupedItems.push(foundItem);
            }
            foundItem.items.push(item);
        });

        this.groupedItems = this.groupedItems.sort(
            (item1, item2) => item1.sortKey > item2.sortKey ? 1 : -1);

        this.groupedItems.forEach(item => {
            item.items = item.items.sort(
                (item1, item2) => item1.createdDate > item2.createdDate ? 1 : -1);
        });
    }
}

export class EntityReportDefinition {
    id: string;
    entityId: string;
    reportKey: string;
    name: string;
    reportParameters: string;

    get reportObject() {
        if (!this.reportParameters) {
            return null;
        }
        return JSON.parse(this.reportParameters);
    }
}

export class WorkOrderReportParameters {
    startDate: Date;
    endDate: Date;
    selectedRegions: any[];
    selectedTrades: any[];
    selectedStatuses: any[];
    selectedContractors: any[];
    selectedEmployees: any[];
    selectedCallTypes: any[];
    selectedItems: any[];
    selectedSLAs: any[];


    selectedRegionsExcluded: any[];
    selectedTradesExcluded: any[];
    selectedStatusesExcluded: any[];
    selectedContractorsExcluded: any[];
    selectedEmployeesExcluded: any[];
    selectedCallTypesExcluded: any[];
    selectedItemsExcluded: any[];
    selectedSLAsExcluded: any[];

}