import { Component, ViewChild, HostListener, OnInit, Input, SimpleChanges, Output, EventEmitter, OnChanges, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppointmentTimeSlot, GroupedAppointmentTimeSlot } from '@upkeeplabs/service-pros/app/model/claims/appointment-time-slot.model';
import { MatDialog } from '@angular/material/dialog';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { WorkOrderAttachmentModel } from '@cogent/shared/models/service/work-order-attachment.model';
import { WorkOrderLineSummaryClient } from '@cogent/shared/models/service/work-order-line-summary.model';
import { ObjectCommand } from '@cogent/client/shared/models/object-command.model';
import { QueueCommand, QueueQuery } from '@cogent/client/shared/models/object-queue.model';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { SettingsApiService } from '@cogent/client/shared/services/api/settings-api.service';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { PolicyApiService } from '@cogent/client/shared/services/api/policy-api.service';
import { DispatchApiService } from '@cogent/client/shared/services/api/dispatch-api.service';
import { ApiService } from '@cogent/client/api';
import { ScheduleViewComponent } from '../../parts/schedule-view/schedule-view.component';
import { ViewContractorStatementComponent, ViewContractorStatementDialogData } from '../view-contractor-statement/view-contractor-statement.component';
import { MissionService, ShellMenuItemModel } from '@cogent/client/shared/services/mission-service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { AuthService } from '@cogent/client/auth';
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { CommandRunnerService } from '@cogent/client/shared/services/command-runner.service';
import { AddNoteComponent } from '../add-note/add-note.component';
import { EditJobItemsStatusComponent } from '../edit-job-items-status/edit-job-items-status.component'
import { DatePipe } from '@angular/common';
import { ServiceRepositoryService } from '@cogent/client/shared/services/api/service-repository';
import { ContractorInvoice, DispatchJobStatus, Entity, EntitySummary, Singleton, Tag, WorkOrderStatusChange, WorkOrderSurveySummary } from '@upkeeplabs/models/cogent';
import { AlternateTimeSlotSuggestion } from '@cogent/shared/models/service/alternate-time-slot-suggestion.model';
import { AuthorizationLine } from '@cogent/shared/models/authorizations/authorization-line.model';
import { ContractorInvoiceSummaryClient } from '@cogent/client/shared/models/accounting/contractor-invoice-summary-client.model';
import { NoteClient } from '@cogent/client/shared/models/common/note-client.model';
import { WorkOrdersViewComponent } from '../../parts/contractors/work-orders-view/work-orders-view.component';
import { NoteApiService } from '@cogent/client/shared/services/api/note-api.service';

declare var Camera: any;
declare var google: any;


class GroupedCommands {
    group: string;
    commands: QueueCommand[];
}

export class DateSelection {
    constructor(public date: Date, daysToAdd: number = 0) {
        date.setDate(date.getDate() + daysToAdd);
    }

    get dayOfWeek(): string {
        if (this.date.getDay() === 0) { return 'Sun'; }
        if (this.date.getDay() === 1) { return 'Mon'; }
        if (this.date.getDay() === 2) { return 'Tue'; }
        if (this.date.getDay() === 3) { return 'Wed'; }
        if (this.date.getDay() === 4) { return 'Thu'; }
        if (this.date.getDay() === 5) { return 'Fri'; }
        if (this.date.getDay() === 6) { return 'Sat'; }
    }

    get heading(): string {
        let result = '';
        if (this.date.getMonth() === 0) { result = 'Jan'; }
        if (this.date.getMonth() === 1) { result = 'Feb'; }
        if (this.date.getMonth() === 2) { result = 'Mar'; }
        if (this.date.getMonth() === 3) { result = 'Apr'; }
        if (this.date.getMonth() === 4) { result = 'May'; }
        if (this.date.getMonth() === 5) { result = 'Jun'; }
        if (this.date.getMonth() === 6) { result = 'Jul'; }
        if (this.date.getMonth() === 7) { result = 'Aug'; }
        if (this.date.getMonth() === 8) { result = 'Sep'; }
        if (this.date.getMonth() === 9) { result = 'Oct'; }
        if (this.date.getMonth() === 10) { result = 'Nov'; }
        if (this.date.getMonth() === 11) { result = 'Dec'; }

        result += ' ' + this.date.getDate();

        return result;
    }
}


export class StatusItem {
    constructor(public description: string, public iconClass: string, public complete: boolean = false, public subStatus: string = ' ') {

    }
}




@Component({
    selector: 'app-work-order-detail',
    templateUrl: './work-order-detail.html',
    styleUrls: ['./work-order-detail.css'],
})
export class WorkOrderDetailComponent implements OnInit, OnChanges {
    @Input() workOrderId: string;

    @Output() workOrderSummaryChange: EventEmitter<WorkOrderSummaryClient> = new EventEmitter();
    @Input() selectedIndex = 0;

    workOrderInvalid = false;
    showQAndA = false;
    apptDate: Date = new Date();
    // startWindow = '8 AM';
    // endWindow = '1 PM';
    appointmentStartTime: Date;
    appointmentEndTime: Date;
    authoStarted = false;
    authoComplete = false;
    statusHistory: WorkOrderStatusChange[];
    maxDate: Date = new Date();
    completedDate: Date = new Date();
    workOrderSummary: WorkOrderSummaryClient;
    showTimeSlotAcceptance = false;
    preferredTimeSlots: AppointmentTimeSlot[];
    authorizationLines: AuthorizationLine[];
    invoice: ContractorInvoice = new ContractorInvoice();
    attachments: WorkOrderAttachmentModel[];
    selectedObject: WorkOrderLineSummaryClient;
    statusChanges: any[];

    tags: Tag[];
    objectCommands: ObjectCommand[];
    extraCommands: QueueCommand[];
    showWorkingMessage = false;
    workingMessage: string;
    groupedPocketCommands: GroupedCommands[];
    showNewInvoiceEntry = false;
    notes: NoteClient[];
    timeSlots: GroupedAppointmentTimeSlot[];
    selectedTimeSlots: AppointmentTimeSlot[];
    authoEnabled = false;
    jumpBallTaken = false;

    @ViewChild('scheduleView') scheduleView: ScheduleViewComponent;

    daysSchedule: WorkOrderSummaryClient[];
    contractorInvoices: ContractorInvoiceSummaryClient[];

    @ViewChild('gmap') gmapElement: any;
    updatingStatus = false;
    map: any;
    portalEnabled: boolean;
    contractorId: string;
    survey: WorkOrderSurveySummary;
    firstEffectiveDate: Date;
    jobItems: WorkOrderLineSummaryClient[];
    jobItemsCopy: WorkOrderLineSummaryClient[];
    details: any;
    companyInfo: any;

    testStartTime: Date = new Date();
    timeSlotSelectedIndex = 0;
    newTimeslotMessage: string;
    alternateTimeSlotSuggestion = new AlternateTimeSlotSuggestion();

    preExistingConditions = "";

    steps: StatusItem[] = [
        new StatusItem('Transmitted', 'send'),
        new StatusItem('Scheduled', 'today'),
        new StatusItem('Dispatched', 'local_shipping'),
        new StatusItem('Authorized', 'gavel'),
        new StatusItem('Completed', 'check'),
        new StatusItem('Invoiced', 'monetization_on'),
    ];
    preSelectDates = [
        new DateSelection(new Date()),
        new DateSelection(new Date(), 1),
        new DateSelection(new Date(), 2),
        new DateSelection(new Date(), 3),
        new DateSelection(new Date(), 4),
        new DateSelection(new Date(), 5),
    ];
    savingAppointment: boolean;
    appointmentMin: Date;
    appointmentMax: Date;
    authorizing = false;
    technicians: Entity[];
    contractor: EntitySummary;
    dispatchContractor = false;
    resolution: string;
    completingWorkOrder = false;
    newStatus: string;

    sendNotificationToCustomer: boolean;
    technicianId: string;
    dispatching = false;

    coverageLanguage: any[];
    stepNumber: number;
    confirmingAppointment = false;
    rejecting = false;
    savingInvoice = false;
    resendingAppointmentEmail = false;
    savingAlternate = false;
    schedulingFollowup = false;
    rescheduleCommands: QueueCommand[];
    rescheduleObjectCommands: ObjectCommand[];
    dispatchUrl: string;
    completedInDispatch = false;

    @HostListener('window:hashchange', ['$event'])
    watchUrlHash() {
        let hash = window.location.hash;
        if (!hash) {
            this.selectedIndex = 0;
            return;
        }

        hash = hash.replace('#step', '');
        const stepNumber = parseInt(hash, 10);
        if (!isNaN(stepNumber)) { this.selectedIndex = stepNumber; }
    }

    constructor(
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private router: Router,
        private dialogService: DialogsService,
        private auth: AuthService,
        private serviceApi: ServiceApiService,
        private commandRunner: CommandRunnerService,
        private serviceRepository: ServiceRepositoryService,
        private missionService: MissionService,
        private settingsApi: SettingsApiService,
        private zone: NgZone,
        private noteApi: NoteApiService,
        private dispatchApi: DispatchApiService,
        private entityApi: EntityApiService,
        private policyApi: PolicyApiService
    ) {
        this.invoice.receivedDate = new Date();
    }

    contractorIdFromPrarm: string;
    showDispatchWarning = false;

    ngOnInit() {
        this.route.params.subscribe(params => {
            if (params.id) {

                if (params.id.indexOf('_') === -1) {
                    this.workOrderId = params.id;
                    this.refreshScreen();

                } else {
                    const ids = params.id.split('_');
                    this.workOrderId = ids[0];
                    this.contractorId = ids[1];
                    this.contractorIdFromPrarm = ids[1];
                    this.refreshScreen();
                }
            }
        });
        this.route.queryParams.subscribe(params => {
            if (params.followup === "true") {
                this.schedulingFollowup = true;
                this.settingsApi.getObjectCommandsForJobReschedule().then(commands => {
                    this.rescheduleCommands = [].concat(...commands.map(i => i.queueQuery.commands));
                    this.rescheduleObjectCommands = commands;
                });
            }
        });
        if (this.auth.isLoggedIn) {
            this.settingsApi.getObjectCommandsForContractorPortal('Service').then(commands => {
                this.objectCommands = commands;
                this.setupCommands();
            });
            this.entityApi.getLoggedInUser().then(user => {
                if (user.allowOnlineAutho) {
                    this.authoEnabled = true;
                }
            });
        }
        this.serviceApi.getContractorPortalEnabled().then(result => {
            this.portalEnabled = result;
            if (!result) {
                this.steps = this.steps.slice(0, this.steps.length - 1);
            }
        });

        this.serviceApi.getOnlineAuthorizationEnabled().then(authoEnabled => {
            if (authoEnabled) {
                this.authoEnabled = authoEnabled;
            }
        });

        this.appointmentStartTime = new Date();
        this.appointmentEndTime = new Date();
        this.appointmentStartTime.setHours(8, 0);
        this.appointmentEndTime.setHours(13, 0);
        this.entityApi.getCompanyInfo2().then(i => this.companyInfo = i);

        this.appointmentMin = new Date();
        this.appointmentMin.setDate(this.appointmentMin.getDate() - 1);
        this.appointmentMax = new Date();
        this.appointmentMax.setMonth(this.appointmentMax.getMonth() + 3);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.workOrderId && changes.workOrderId.currentValue) {
            this.workOrderId = changes.workOrderId.currentValue;
            this.refreshScreen();
        }
    }

    getTechThumbnail(tech: Entity) {
        return `${ApiService.endPointDotNet}Entity/thumbnail/${tech.id}`;
    }

    get isJumpBall() {
        return this.workOrderSummary && this.workOrderSummary.status && this.workOrderSummary.status.indexOf('Jump Ball') > -1;
    }


    async refreshTags() {
        if (this.auth.isLoggedIn) {
            this.tags = await this.serviceApi.getWorkOrderTags(this.workOrderId);
            this.setupCommands();
        }
    }

    get hidePhoneNumber() {
        if (!this.contractorIdFromPrarm || !this.workOrderSummary) {
            return false;
        }

        return this.contractorIdFromPrarm.toLowerCase() !== this.workOrderSummary.contractorId.toLowerCase();
    }

    sendingOnMyWay = false;
    sendOnMyWay() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                this.zone.run(() => {

                    this.sendingOnMyWay = true;
                    const args = {
                        workOrderId: this.workOrderSummary.id,
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                        sendNotificationToHomeowner: true,
                        serviceProfessionalId: this.workOrderSummary.technicianId,
                    };

                    this.serviceApi.sendOnMyWayNotification(this.workOrderSummary.id, args).then(() => {
                        this.missionService.showSuccessToast('Notification Sent');
                        this.workOrderSummary.onMyWayNotificationSentDate = new Date();
                        this.sendingOnMyWay = false;
                    });
                });

            });
        }
    }

    private loadTimeSlots() {
        this.serviceRepository.getAppointmentTimeSlots(this.workOrderSummary.lines[0].itemId, true).then(timeSlots => {
            const groupedTimeSlots = GroupedAppointmentTimeSlot.fromAppointmentTimeSlots(timeSlots);
            this.timeSlots = groupedTimeSlots;
        });

    }

    showAlternateTimeSlots() {
        if (!this.timeSlots) {
            this.loadTimeSlots();
        }
        this.timeSlotSelectedIndex = 1;
    }

    get lineItems() {
        if (!this.workOrderSummary) {
            return null;
        }

        return this.workOrderSummary.lines;
    }

    get firstItem() {
        if (this.lineItems && this.lineItems.length) {
            return this.lineItems[0];
        }
    }

    get canSuggestAlternate() {
        if (this.savingAlternate) {
            return false;
        }

        return this.alternateTimeSlotSuggestion.timeSlots
            && this.alternateTimeSlotSuggestion.timeSlots.filter(i => i && i.startHour).length >= 2;
    }

    async requestAlternateTimes() {
        this.savingAlternate = true;
        if (!this.schedulingFollowup) {
            await this.serviceApi.requestAlternateTimes(this.workOrderSummary.id, this.alternateTimeSlotSuggestion);
        } else {
            await this.serviceApi.requestFollowupAlternateTimes(this.workOrderSummary.id, this.alternateTimeSlotSuggestion);
        }

        this.savingAlternate = false;
        this.showTimeSlotAcceptance = false;
    }

    async callToSchedule() {
        const result = await this.dialogService.confirm('Confirm', 'Call to schedule?').toPromise();
        if (result) {
            await this.serviceApi.setAcceptedUnscheduled(this.workOrderSummary.id);
            this.refreshScreen();
            this.showTimeSlotAcceptance = false;
        }
    }


    async executeCommandServer(command: QueueCommand) {
        (command as any).running = true;
        let selectedObjectCommand: ObjectCommand;

        if (this.rescheduleCommands) {
            for (const objectCommand of this.rescheduleObjectCommands) {
                if (objectCommand.queueQuery.commands.indexOf(command) > -1) {
                    selectedObjectCommand = objectCommand;
                }
            }
        }

        await this.commandRunner.runObjectCommandServerSide(selectedObjectCommand.id, command.id, this.workOrderId, 'WorkOrder');
        (command as any).running = false;
    }

    executeCommand(command: QueueCommand) {
        let queueQuery: QueueQuery;

        if (this.objectCommands) {
            for (const objectCommand of this.objectCommands) {
                if (objectCommand.queueQuery.commands.indexOf(command) > -1) {
                    queueQuery = objectCommand.queueQuery;
                }
            }
        }

        this.commandRunner.startDoCommand(command.actions, null, this.workOrderSummary, this.workOrderSummary, queueQuery, () => this.refreshScreen(), () => this.refreshScreen(), (show, message) => {
            this.showWorkingMessage = show;
            this.workingMessage = message;
        }, null, null, null, null, () => { }, null, null);
    }

    async viewStatement(invoice: ContractorInvoiceSummaryClient) {
        const paymentSummary = await this.serviceApi.getContractorInvoicePaymentSummaryByIdNoAuth(invoice.contractorInvoicePaymentId);
        this.dialog.open(ViewContractorStatementComponent, {
            data: new ViewContractorStatementDialogData(paymentSummary as any)
        });
    }

    addNote() {
        const ref = this.dialog.open(AddNoteComponent, { data: this.workOrderSummary });
        ref.afterClosed().subscribe(results => {
            if (results) {
                this.refreshNotes();
            }
        });
    }

    getItemThumbnailUrl(line: WorkOrderLineSummaryClient) {
        return this.serviceApi.getItemThumbnailUrl(line.itemId);
    }

    updateStatusOnItem(item: WorkOrderLineSummaryClient = null) {
        const ref = this.dialog.open(EditJobItemsStatusComponent, {
            data: {
                allItems: this.jobItems,
                item
            }
        });

        ref.afterClosed().subscribe(results => {
            if (results === 'COMPLETE') {
                this.startComplete();
            }
            else if (results) {
                this.getLineItems();
            }
        });
    }

    showDetail(item: WorkOrderLineSummaryClient) {
        this.selectedObject = item;
        this.statusChanges = [];
        this.serviceApi.getWorkOrderLineDetailNoAuth(item.id).then(details => {
            this.details = details;
        });
        this.serviceApi.getWorkOrderLineStatusChangesNoAuth(item.id).then(statusChanges => {
            this.statusChanges = statusChanges;
        });
    }

    private async setupCommands() {
        if (!this.objectCommands || !this.workOrderSummary || !this.tags) {
            return;
        }


        this.extraCommands = [];

        outerLoop:
        for (const objectCommand of this.objectCommands) {
            const queueQuery = objectCommand.queueQuery;

            if (queueQuery.selectedCallTypes && queueQuery.selectedCallTypes.length > 0 && queueQuery.selectedCallTypes.indexOf(this.workOrderSummary.type) === -1) {
                continue;
            }
            if (queueQuery.selectedCallTypesExcluded && queueQuery.selectedCallTypesExcluded.length > 0 && queueQuery.selectedCallTypesExcluded.indexOf(this.workOrderSummary.type) > -1) {
                continue;
            }
            if (queueQuery.selectedContractors && queueQuery.selectedContractors.length > 0 && queueQuery.selectedContractors.filter(i => i.id === this.workOrderSummary.contractorId).length === 0) {
                continue;
            }
            if (queueQuery.selectedContractorsExcluded && queueQuery.selectedContractorsExcluded.length > 0 && queueQuery.selectedContractorsExcluded.filter(i => i.id === this.workOrderSummary.contractorId).length > 0) {
                continue;
            }
            if (queueQuery.selectedEmployees && queueQuery.selectedEmployees.length > 0 && queueQuery.selectedEmployees.filter(i => i.id === this.workOrderSummary.createdById).length === 0) {
                continue;
            }
            if (queueQuery.selectedEmployeesExcluded && queueQuery.selectedEmployeesExcluded.length > 0 && queueQuery.selectedEmployeesExcluded.filter(i => i.id === this.workOrderSummary.createdById).length > 0) {
                continue;
            }
            if (queueQuery.selectedItems && queueQuery.selectedItems.length > 0 && queueQuery.selectedItems.filter(i => i.id === this.workOrderSummary.itemId).length === 0) {
                continue;
            }
            if (queueQuery.selectedItemsExcluded && queueQuery.selectedItemsExcluded.length > 0 && queueQuery.selectedItemsExcluded.filter(i => i.id === this.workOrderSummary.itemId).length > 0) {
                continue;
            }
            if (queueQuery.selectedRegions && queueQuery.selectedRegions.length > 0 && queueQuery.selectedRegions.filter(i => i.id === this.workOrderSummary.regionId).length === 0) {
                continue;
            }
            if (queueQuery.selectedRegionsExcluded && queueQuery.selectedRegionsExcluded.length > 0 && queueQuery.selectedRegionsExcluded.filter(i => i.id === this.workOrderSummary.regionId).length > 0) {
                continue;
            }
            if (queueQuery.selectedSLAStatuses && queueQuery.selectedSLAStatuses.length > 0 && queueQuery.selectedSLAStatuses.indexOf(this.workOrderSummary.sLAStatus) === -1) {
                continue;
            }
            if (queueQuery.selectedSLAStatusesExcluded && queueQuery.selectedSLAStatusesExcluded.length > 0 && queueQuery.selectedSLAStatusesExcluded.indexOf(this.workOrderSummary.sLAStatus) > -1) {
                continue;
            }
            if (queueQuery.selectedTags && queueQuery.selectedTags.length > 0) {
                for (const tag of queueQuery.selectedTags) {
                    if (this.tags.filter(i => i.id === tag.id).length === 0) {
                        continue outerLoop;
                    }
                }
            }

            if (queueQuery.selectedTagsExcluded && queueQuery.selectedTagsExcluded.length > 0) {
                for (const tag of queueQuery.selectedTagsExcluded) {
                    if (this.tags.filter(i => i.id === tag.id).length > 0) {

                        continue outerLoop;
                    }
                }
            }
            if (queueQuery.selectedTrades && queueQuery.selectedTrades.length > 0 && queueQuery.selectedTrades.filter(i => i.id === this.workOrderSummary.tradeId).length === 0) {
                continue;
            }
            if (queueQuery.selectedTradesExcluded && queueQuery.selectedTradesExcluded.length > 0 && queueQuery.selectedTradesExcluded.filter(i => i.id === this.workOrderSummary.tradeId).length > 0) {
                continue;
            }

            if (queueQuery.selectedWorkOrderStatuses && queueQuery.selectedWorkOrderStatuses.length > 0 && queueQuery.selectedWorkOrderStatuses.filter(i => i.id === this.workOrderSummary.workOrderStatusId).length === 0) {
                continue;
            }
            if (queueQuery.selectedWorkOrderStatusesExcluded && queueQuery.selectedWorkOrderStatusesExcluded.length > 0 && queueQuery.selectedWorkOrderStatusesExcluded.filter(i => i.id === this.workOrderSummary.workOrderStatusId).length > 0) {
                continue;
            }
            this.extraCommands = this.extraCommands.concat(objectCommand.queueQuery.commands.filter(i => !i.hideFromUI));
        }
    }

    async saveInvoice() {
        this.savingInvoice = true;

        const previousContractorInvoices = await this.serviceApi.getWorkOrderContractorInvoices(this.workOrderId);
        if (previousContractorInvoices.length > 0) {
            //this.dialogService.confirm('Confirm',`There are already ${previousContractorInvoices.length} invoice(s) created for this job.<br>Are you sure you want to create a new o`)
            this.dialogService.alert('Job Invoiced', 'This job has already been invoiced.');
            this.savingInvoice = false;
        } else {
            this.completeSave();
        }

    }

    private async completeSave() {
        this.invoice.workOrderId = this.workOrderId;

        this.invoice.appliedItemsJson = JSON.stringify(this.jobItemsCopy.filter(i => i.selected).map(i => i.id));
        await this.serviceApi.saveInvoiceNoAuth(this.invoice as any);
        if (!this.completedDate) {
            this.completedDate = new Date();
        }
        await this.serviceApi.setWorkOrderCompleteDateNoAuth(this.workOrderId, this.completedDate);

        if (this.workOrderSummary.dispatchExportDate && !this.completedInDispatch) {
            this.dispatchApi.sendCompletedStatus(this.workOrderId, this.workOrderSummary.number.toString(), this.workOrderSummary.policyId).then(value => { });
            this.completedInDispatch = true;
        }

        this.savingInvoice = false;
        this.steps[this.steps.length - 1].complete = true;
        this.selectedIndex = 0;
        this.refreshScreen();
        this.refreshContractorInvoices();
        this.showNewInvoiceEntry = false;
        this.invoice = new ContractorInvoice();
    }

    assignTimeSlot(timeSlot: AppointmentTimeSlot) {
        let contractorId = this.contractorIdFromPrarm;
        if (!contractorId) {
            contractorId = this.contractorId;
        }
        timeSlot.accepting = true;
        this.confirmingAppointment = true;
        if (!this.schedulingFollowup) {
            this.serviceApi.acceptTimeSlotNoAuth(this.workOrderId, timeSlot.id, contractorId).then(result => {
                if (result) {
                    this.workOrderSummary.scheduledDate = timeSlot.date;
                    this.workOrderSummary.scheduledStartWindow = timeSlot.startWindow;
                    this.workOrderSummary.scheduledEndWindow = timeSlot.endWindow;
                    this.workOrderSummary.status = 'Scheduled';
                    this.showTimeSlotAcceptance = false;
                    timeSlot.accepting = false;
                    this.stepNumber = 1;
                    this.confirmingAppointment = false;

                    if (this.steps && this.steps.length > 1) {
                        this.steps[1].complete = true;
                    }
                    this.serviceApi.getWorkOrderStatusHistoryNoAuth(this.workOrderId).then(status => {
                        this.statusHistory = status;

                    });
                    this.getLineItems();
                    this.missionService.showSuccessToast('Job Accepted');
                    this.hideCustomerName = false;
                    if (this.contractorIdFromPrarm) {
                        this.workOrderSummary.contractorId = this.contractorIdFromPrarm;
                    }
                    //this.contractorIdFromPrarm.toLowerCase() !== this.workOrderSummary.contractorId.toLowerCase();

                } else {
                    this.workOrderInvalid = true;
                    this.workOrderSummary = null;
                }
            });
        } else {
            this.serviceApi.acceptFollowupTimeSlotNoAuth(this.workOrderId, timeSlot.id, contractorId).then(() => {
                this.workOrderSummary.scheduledDate = timeSlot.date;
                this.workOrderSummary.scheduledStartWindow = timeSlot.startWindow;
                this.workOrderSummary.scheduledEndWindow = timeSlot.endWindow;
                this.workOrderSummary.status = 'Scheduled';
                this.showTimeSlotAcceptance = false;
                timeSlot.accepting = false;
                this.stepNumber = 1;
                this.confirmingAppointment = false;

                if (this.steps && this.steps.length > 1) {
                    this.steps[1].complete = true;
                }
                this.serviceApi.getWorkOrderStatusHistoryNoAuth(this.workOrderId).then(status => {
                    this.statusHistory = status;

                });
                this.getLineItems();
            });
        }
    }

    noParts() {
        this.dialogService.confirm('Confirm', 'You have not received the parts?').subscribe(result => {
            if (result) {

            }
        });
    }

    async createAppointment() {
        this.selectedIndex = 1;
    }

    createAutho() {
        if (this.workOrderSummary.tradeId === 'a22ce8d5-c080-4b56-8675-6a353c0ae678') {
            this.router.navigateByUrl(`/appliance-autho/${this.workOrderId}`);
        } else {
            this.router.navigateByUrl(`/authorize/${this.workOrderId}`);
        }
    }

    createAuthoForItem(line: WorkOrderLineSummaryClient) {
        if (this.workOrderSummary.tradeId === 'a22ce8d5-c080-4b56-8675-6a353c0ae678') {
            this.router.navigateByUrl(`/appliance-autho/${this.workOrderId}?lineId=${line.id}`);
        } else {
            this.router.navigateByUrl(`/authorize/${this.workOrderId}`);
        }
    }

    saveAppointment() {

        this.savingAppointment = true;
        const datePipe = new DatePipe('en-US');
        const startWindow = datePipe.transform(this.appointmentStartTime, 'shortTime');
        const endWindow = datePipe.transform(this.appointmentEndTime, 'shortTime');
        this.serviceApi.setWorkOrderAppointmentDateNoAuth(this.workOrderId, this.apptDate, startWindow, endWindow).then(() => {
            this.back();
            this.refreshScreen();
            this.savingAppointment = false;
        });
    }

    handleFileUpload(evt) { this.handleFiles(evt); }

    showAttachment(attachment: WorkOrderAttachmentModel) {
        if (attachment.failedToUpload) {
            this.dialogService.confirm('Retry', 'Would you like to retry uploading the photo?').subscribe(results => {
                if (results) {
                    this.attachments.splice(this.attachments.indexOf(attachment));
                    this.loadIt(attachment.base64, attachment.name);
                }
            });

        } else {
            window.open(attachment.downloadUrl);
        }
        //[href]="attachment.downloadUrl"
    }

    loadIt(base64, fileName) {
        const attachment = new WorkOrderAttachmentModel(fileName, null, base64);
        attachment.uploading = true;
        this.attachments.push(attachment);

        const note = new NoteClient();
        note.policyId = this.workOrderSummary.policyId;
        note.workOrderId = this.workOrderId;
        note.noteText = 'Authorization Pictures Attached';
        note.type = 'Contractor Generated';

        this.serviceApi.saveAuthorizationAttachmentsNoAuth(note as any, [attachment]).then(() => {
            attachment.uploading = false;

        }, () => {

            // this.attachments.splice(this.attachments.indexOf(attachment), 1);
            attachment.failedToUpload = true;
            this.dialogService.confirm('Upload Failed', 'Sorry, it appears the picture failed to upload.<br>Would you like to try again?').subscribe(results => {
                if (results) {
                    attachment.failedToUpload = false;
                    this.attachments.splice(this.attachments.indexOf(attachment));
                    this.loadIt(attachment.base64, attachment.name);
                }
            });
        });
    }

    handleFiles(files) {
        if (files.srcElement) {
            files = files.srcElement.files;
        }

        const thisItem = this;
        for (const file of files) {
            const reader = new FileReader();
            reader.onload = (fileName => {
                return e => {
                    thisItem.loadIt(e.target.result, fileName);
                };
            })(file.name);

            reader.readAsDataURL(file);
        }
        (document.getElementById('fileToUpload1') as any).value = '';
    }

    showFileUpload() {
        const nav: any = navigator;

        if (nav && nav.camera) {
            nav.camera.getPicture(
                imageData => {

                    const fileName =
                        UtilitiesService.newid().replace('-', '').substring(0, 9) +
                        '.jpg';
                    const attachment = new WorkOrderAttachmentModel(
                        fileName,
                        UtilitiesService.newid(),
                        'data:image/jpeg;base64,' + imageData);
                    this.attachments.push(attachment);


                },
                error => { console.error(error); }, {
                quality: 30,
                destinationType: Camera.DestinationType.DATA_URL,
                correctOrientation: true,
                mediaType: Camera.MediaType.PICTURE,
                encodingType: Camera.EncodingType.JPEG
            });
        } else {
            document.getElementById('fileToUpload1').click();
        }
    }



    back() {
        this.selectedIndex = 0;
    }

    setDate(date: DateSelection) {
        this.apptDate = date.date;
    }

    updateStatus() {
        this.selectedIndex = 6;
    }

    startDispatch() {
        this.selectedIndex = 2;

        if (!this.technicians) {
            this.serviceApi.getContractorTechniciansNoAuth(this.workOrderSummary.contractorId).then(technicians => this.technicians = technicians);
        }
    }

    get canSaveAppointment() {
        // if (!this.startWindow || !this.endWindow) {
        //     return false;
        // }
        // let startHour = parseInt(this.startWindow, 10);
        // if (startHour < 12 && this.startWindow.indexOf('PM') > -1) {
        //     startHour += 12;
        // }

        // let endHour = parseInt(this.endWindow, 10);
        // if (endHour < 12 && this.endWindow.indexOf('PM') > -1) {
        //     endHour += 12;
        // }
        return this.apptDate && (this.appointmentEndTime.getHours() > this.appointmentStartTime.getHours()
            || (this.appointmentEndTime.getHours() === this.appointmentStartTime.getHours()
                && this.appointmentEndTime.getMinutes() >= this.appointmentStartTime.getMinutes())
        );
    }

    startComplete() {
        this.selectedIndex = 4;
    }

    startInvoicing() {
        this.selectedIndex = 5;
    }

    get canCompleteWorkOrder() {
        if (this.workOrderSummary.dateCompleted) return false; //DISABLE COMPLETE BUTTON IF ALREADY HAS A COMPLETED DATE

        return this.resolution && this.completedDate;
    }

    completeWorkOrder() {
        this.completingWorkOrder = true;
        this.serviceApi.completeWorkOrderNoAuth(this.workOrderId, this.resolution, this.completedDate).then(async () => {
            if (this.workOrderSummary.dispatchExportDate && !this.completedInDispatch) {
                await this.dispatchApi.sendCompletedStatus(this.workOrderId, this.workOrderSummary.number.toString(), this.workOrderSummary.policyId);
                this.completedInDispatch = true;
            }
            this.invoice.workPerformed = this.resolution;
            this.back();
            this.refreshScreen();
            this.completingWorkOrder = false;
            this.workOrderSummary.dateCompleted = this.completedDate;
        });
    }

    saveNewStatus() {
        if (this.newStatus === 'COMPLETE') {
            this.startComplete();
            return;
        }
        this.updatingStatus = true;
        this.serviceApi.updateWorkOrderStatusNoAuth(this.workOrderId, this.newStatus).then(() => {
            this.back();
            this.refreshScreen();
            this.updatingStatus = false;
        });
    }

    sendDispatchNow() {
        this.dispatching = true;
        this.serviceApi.updateWorkOrderTechnicianNoAuth(this.workOrderId, this.technicianId).then(() => {
            this.back();
            this.dispatching = false;
            this.refreshScreen();
        });
    }

    showCoverageAndNotes() {
        this.selectedIndex = 7;


        for (const jobItem of this.jobItems) {
            this.loadJobItemContractLanguage(jobItem);
        }
    }

    private loadJobItemContractLanguage(jobItem: WorkOrderLineSummaryClient) {
        if (!jobItem.contractLanguage) {
            this.serviceApi.getWorkOrderItemDetailNoAuth(jobItem.id).then(contractLanguage => {
                jobItem.contractLanguage = contractLanguage;
            });
        }
    }

    private async refreshScreen() {
        if (!this.contractorId) {
            const contractor = (await this.entityApi.getLoggedInUser());
            if (contractor) {
                this.contractorId = contractor.id;
            }
        }

        const workOrderId = this.workOrderId;
        this.authorizationLines = await this.serviceApi.getAuthorizationLinesNoAuth(this.workOrderId);
        this.workOrderInvalid = false;
        const workOrderSummary = await this.serviceApi.getWorkOrderSummaryNoAuth(this.workOrderId, this.contractorId);
        if (!workOrderSummary) {
            this.workOrderInvalid = true;
            return;
        }
        this.contractor = await this.entityApi.getEntitySummary(workOrderSummary.contractorId);
        if (this.contractor?.dispatchActivatedDate) {
            this.dispatchContractor = true;
        }

        const statuses = [
            '51cdfa72-32f6-414c-8b0f-f7306175424d',
            '20fe5141-c36f-4c5f-9f1d-98e3b9f8d425',
            '549c677f-8818-4035-a3b7-cf4f850089bc',
            '3c665c21-205b-40f9-8517-2ee4ac9bdc04',
        ];
        if (this.schedulingFollowup) {
            const unscheduledCount = await this.serviceApi.getUnacceptedFollowUpAppointmentCount(workOrderSummary.id);
            if (unscheduledCount === 0) {
                this.schedulingFollowup = false;
            }
        }

        if (statuses.indexOf(workOrderSummary.workOrderStatusId) > -1 || this.schedulingFollowup) {
            this.serviceApi.getWorkOrderAppointmentTimeSlotsNoAuth(workOrderId, this.schedulingFollowup ? 'INSTALL' : null).then(timeSlots => {
                this.preferredTimeSlots = timeSlots;

                this.showTimeSlotAcceptance = timeSlots && timeSlots.length > 0 && !this.workOrderSummary.cancelledDate
                    && !this.workOrderSummary.scheduledDate;
                if (this.schedulingFollowup && timeSlots.length > 0) {
                    this.showTimeSlotAcceptance = true;
                }
                if (this.showTimeSlotAcceptance) {
                    setTimeout(async () => {
                        const address = await this.serviceApi.getAddressNoAuth(this.workOrderSummary.propertyId);
                        const mapProp = {
                            center: new google.maps.LatLng(address.latitude, address.longitude),
                            zoom: 14,
                            mapTypeId: google.maps.MapTypeId.ROADMAP,
                            disableDefaultUI: true
                        };
                        this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
                        new google.maps.Marker({
                            position: new google.maps.LatLng(address.latitude, address.longitude),
                            map: this.map,
                            title: this.workOrderSummary.propertyAddress
                        });
                    }, 10);

                }
            });
        }
        this.refreshTags();


        this.workOrderSummary = workOrderSummary;
        this.refreshNotes();
        if (!this.technicians) {
            this.technicians = await this.serviceApi.getContractorTechniciansNoAuth(this.workOrderSummary.contractorId);
        }

        if (this.workOrderSummary.scheduledDate && this.workOrderSummary.scheduledDate.getTime && !isNaN(this.workOrderSummary.scheduledDate.getTime())) {

            this.apptDate = this.workOrderSummary.scheduledDate;
            if (this.apptDate && this.workOrderSummary.scheduledStartWindow && this.workOrderSummary.scheduledEndWindow) {
                if (this.workOrderSummary.scheduledStartWindow.indexOf(':') === -1) {
                    this.workOrderSummary.scheduledStartWindow = this.workOrderSummary.scheduledStartWindow.split(' ').join(':00 ');
                }
                if (this.workOrderSummary.scheduledEndWindow.indexOf(':') === -1) {
                    this.workOrderSummary.scheduledEndWindow = this.workOrderSummary.scheduledEndWindow.split(' ').join(':00 ');
                }

                const datePipe = new DatePipe('en-US');
                const aptDateString = datePipe.transform(this.apptDate, 'shortDate');
                this.appointmentStartTime = new Date(aptDateString + ' ' + this.workOrderSummary.scheduledStartWindow);
                if (isNaN(this.appointmentStartTime.getTime())) {
                    this.appointmentStartTime = new Date(aptDateString + ' 8:00 AM');
                }
                this.appointmentEndTime = new Date(aptDateString + ' ' + this.workOrderSummary.scheduledEndWindow);
                if (isNaN(this.appointmentEndTime.getTime())) {
                    this.appointmentEndTime = new Date(aptDateString + ' 1:00 PM');
                }
            }

        }

        let stepNumber = -1;

        if (this.workOrderSummary.status === 'Sent' || this.workOrderSummary.status === 'Awaiting Service Pro Acceptance'
            || this.workOrderSummary.status === 'Awaiting 1st Service Pro Acceptance' || this.workOrderSummary.status === 'Awaiting Second Service Pro Acceptance'
            || this.workOrderSummary.status === 'Pending') {
            stepNumber = 0;
        } else if (this.workOrderSummary.status === 'Scheduled' && this.technicians && this.technicians.length > 0) {
            stepNumber = 1;
        } else if (this.workOrderSummary.status === 'Scheduled' && (!this.technicians || this.technicians.length === 0)) {
            stepNumber = 3;
        } else if (this.workOrderSummary.status === 'Dispatched' || this.workOrderSummary.status === 'Dispatch On The Way' || this.workOrderSummary.status === 'Technician Assigned') {
            stepNumber = 2;
        } else if (this.workOrderSummary.status === 'Authorized' || this.workOrderSummary.status === 'Authorization Requested' ||
            this.workOrderSummary.status === 'Authorization Under Review') {
            stepNumber = 3;
        } else if (this.workOrderSummary.status === 'Complete' || this.workOrderSummary.status === 'Pending Invoice') {
            stepNumber = 4;
        } else if (this.workOrderSummary.status === 'Invoiced') {
            stepNumber = 5;
        } else if (this.workOrderSummary.status === 'Parts Delay') {
            this.steps.splice(4, 0, new StatusItem('Parts Delay', 'timelapse'));
            stepNumber = 4;
        }

        if (!this.portalEnabled && this.workOrderSummary.scheduledDate && !this.workOrderSummary.dateCompleted) {
            stepNumber = 1;
        }

        this.stepNumber = stepNumber;
        for (let i = 0; i <= stepNumber; i++) {
            setTimeout((args) => { this.steps[args].complete = true; }, 1000 + (500 * i), i);
        }

        if (this.workOrderSummary.sentDate) { this.steps[0].subStatus = this.workOrderSummary.sentDescription; }
        if (this.workOrderSummary.scheduledDate) { this.steps[1].subStatus = this.workOrderSummary.appointmentDescription; }
        if (this.workOrderSummary.dateCompleted) { this.steps[4].subStatus = this.workOrderSummary.completedDescription; }
        if (this.workOrderSummary.dispatchedDate) { this.steps[2].subStatus = this.workOrderSummary.dispatchDescription; }
        this.completedDate = this.workOrderSummary.dateCompleted;

        if (this.workOrderSummary.resolution) this.resolution = this.workOrderSummary.resolution;

        this.workOrderSummaryChange.emit(this.workOrderSummary);

        this.serviceApi.getWorkOrderStatusHistoryNoAuth(this.workOrderId).then(status => {
            this.statusHistory = status;
        });

        this.refreshContractorInvoices();

        this.serviceApi.getWorkOrderAttachmentsNoAuth(this.workOrderId).then(attachments => {
            this.attachments = attachments;
        });

        this.serviceApi.getAnonymousWorkOrderSurvey(this.workOrderId).then(response => {
            if (response) {
                this.survey = response[0];
            }
        });
        this.serviceApi.getAnonymousFirstEffectiveDate(this.workOrderSummary.policyId).then(firstEffectiveDate => {
            this.firstEffectiveDate = firstEffectiveDate;
        });
        this.getLineItems();

        if (this.invoice && !this.invoice.workPerformed) {
            this.invoice.workPerformed = this.resolution;
        }

        if (this.workOrderSummary.dispatchExportDate) {
            this.showTimeSlotAcceptance = false;
            this.showDispatchWarning = true;
        }
        else {
            this.showDispatchWarning = false;
        }

        this.dispatchApi.getJobAcceptedInDispatch(this.workOrderId).then(result => {
            if (result) {
                const completed = (result as DispatchJobStatus[]).find(i => i.status == 'complete');
                if (completed) this.completedInDispatch = true;
            }
        });
        this.dispatchUrl = await this.dispatchApi.getDispatchUrl();
    }

    private async refreshContractorInvoices() {

        this.contractorInvoices = await this.serviceApi.getWorkOrderContractorInvoicesNoAuth(this.workOrderId);
    }

    private getLineItems() {
        this.serviceApi.getWorkOrderLinesNoAuth(this.workOrderSummary.id).then(lines => {
            this.jobItems = lines;
            this.jobItemsCopy = UtilitiesService.copyArrayToTypedArray(this.jobItems, () => new WorkOrderLineSummaryClient());
            for (const item of this.jobItemsCopy) {
                item.selected = true;
            }

            for (const jobItem of this.jobItems) {
                this.policyApi.getPolicyServiceOffering(jobItem.id).then(lines => {
                    jobItem.limitPerOccurrence = lines[0].limitPerOccurance;
                });

                if (!jobItem.isService && this.workOrderSummary.isWithin60Days && this.workOrderSummary.coverageType != "Renewal") {
                    this.preExistingConditions = "Claim is subject to pre-existing conditions. Please call in for authorization to provide diagnosis, cause, and nature of the failure.";
                    break;
                }
            }
        });
    }

    get jobItemsSelected() {
        return this.jobItemsCopy && this.jobItemsCopy.find(i => i.selected);
    }

    hideCustomerName = false;
    reassign() {
        this.dialogService.confirm('Confirm', 'Are you sure you want to re-assign the job').subscribe(reassign => {
            if (reassign) {
                //ADD NOTE
                const note = new NoteClient();
                note.id = UtilitiesService.newid();
                note.workOrderId = this.workOrderId;
                note.policyId = this.workOrderSummary.policyId;
                note.noteText = `rejected job in service pro portal`;
                this.noteApi.saveNote(note).then(() => { });

                this.hideCustomerName = true;
                this.rejecting = true;
                this.serviceApi.reassignWorkOrderNoAuth(this.workOrderId).then(() => {
                    this.workOrderSummary.cancelledDate = new Date();
                    this.workOrderSummary.status = 'Cancelled';
                    this.rejecting = false;
                    this.showTimeSlotAcceptance = false;
                });
            }
        });
    }

    private async refreshNotes() {
        this.notes = await this.serviceApi.getWorkOrderNotesNoAuth(this.workOrderId);
        if (this.workOrderSummary.additionalNotes) {
            const note = new NoteClient();
            note.noteText = this.workOrderSummary.additionalNotes;
            note.showOnContractorPortal = true;
            note.createdDate = this.workOrderSummary.createdDate;
            this.notes.push(note);
        }
    }

    get menuItems(): ShellMenuItemModel[] {
        const results = [];

        results.push(new ShellMenuItemModel('Home', 'home', () => { this.router.navigateByUrl('/contractors'); }));
        results.push(new ShellMenuItemModel('Overview', 'assessment', () => { this.back(); }));
        results.push(new ShellMenuItemModel('Schedule Appointment', 'event', () => { this.createAppointment(); }));
        results.push(new ShellMenuItemModel('Get Authorization', 'gavel', () => { this.createAutho(); }));
        results.push(new ShellMenuItemModel('Update Status', 'timeline', () => { this.updateStatus(); }));
        results.push(new ShellMenuItemModel('Dispatch', 'local_shipping', () => { this.startDispatch(); }));
        results.push(new ShellMenuItemModel('Complete Request', 'check_circle', () => { this.startComplete(); }));
        results.push(new ShellMenuItemModel('Invoice', 'attach_money', () => { this.startInvoicing(); }));
        results.push(new ShellMenuItemModel('Coverage and Notes', 'folder_open', () => { this.showCoverageAndNotes(); }));
        return results;
    }

    setCurrentAppointment() {
        if (this.technicianId) {
            if (this.daysSchedule.indexOf(this.workOrderSummary) === -1) {
                this.daysSchedule.push(this.workOrderSummary);
            }
        } else {
            const currentAppt = this.daysSchedule.find(i => i.id === this.workOrderSummary.id);
            if (currentAppt) {
                this.daysSchedule.splice(this.daysSchedule.indexOf(currentAppt), 1);
            }
        }
    }

    updateTechApptDisplay() {
        setTimeout(() => {
            this.workOrderSummary.technicianId = this.technicianId;
            this.setCurrentAppointment();
            this.scheduleView.refresh();
        });
    }

    async onStepChange() {
        window.location.hash = 'step' + this.selectedIndex.toString();

        if (this.selectedIndex === 2 && !this.daysSchedule && this.workOrderSummary.scheduledDate) {
            this.serviceApi.getAssignedAppointmentsForDayNoAuth(this.workOrderSummary.scheduledDate, this.workOrderSummary.contractorId).then(today => {
                this.daysSchedule = today.sort((a, b) => a.appointmentStartHour < b.appointmentStartHour ? 1 : -1);

                this.setCurrentAppointment();
            });
        }
        if (this.selectedIndex === 5 && !this.contractorInvoices) {
            this.refreshContractorInvoices();

        }
    }
}