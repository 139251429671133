<div class="top-toolbar" *ngIf="selectedIndex === 0">
    <div class="container">
        <div aflex>
            <div>
                <h1>Sign Up</h1>
            </div>
            <div nogrow> <button mat-button mat-raised-button color="primary" [disabled]="saving" (click)="save()">
                    <mat-spinner *ngIf="saving" class="thirty"></mat-spinner> Save
                </button></div>
        </div>
    </div>
</div>
<div class="container fancy-container" style="padding-top: 90px">


    <mat-tab-group [(selectedIndex)]="selectedIndex" class="hide-tabs">

        <mat-tab label="First">
            <div class="center">
                <img src="../../../assets/images/contact-orange-blob.svg" style="height: 100px;">
            </div>
            <mat-form-field style="max-width: 400px" appearance="outline">
                <mat-label>Your Name *</mat-label>
                <input matInput id="name" [(ngModel)]="args.name">
            </mat-form-field>

            <div flex style="max-width: 700px">
                <mat-form-field appearance="outline" flex1>
                    <mat-label>Phone *</mat-label>
                    <input matInput id="phoneNumber" [(ngModel)]="args.phoneNumber" type="tel" mask="(000) 000-0000">
                </mat-form-field>

                <mat-form-field appearance="outline" flex2>
                    <mat-label>Email *</mat-label>
                    <input matInput id="email" [(ngModel)]="args.email" type="email">
                </mat-form-field>
            </div>

            <hr>

            <div style="max-width:700px">

                <mat-form-field appearance="outline">
                    <mat-label>Company Name *</mat-label>
                    <input matInput id="companyName" [(ngModel)]="args.companyName">
                </mat-form-field>

                <app-address-editor [address]="args.address"></app-address-editor>
            </div>


            <hr>
            <div>
                <div class="data-label">What Areas Do You Service?</div>

                <div style=" overflow:hidden;" *ngFor="let area of areas">
                    <div class="slide-in-down">
                        <mat-checkbox [(ngModel)]="area.hasSelected" (ngModelChange)="areaChanged(area)">{{area.areaName}}</mat-checkbox>
                        <mat-icon class="rotate" (click)="area.expanded = !area.expanded"
                            [class.rotated]="area.expanded">chevron_right</mat-icon>

                        <div style="margin-left:20px; overflow:hidden;" *ngFor="let region of area.regions">
                            <div class="slide-in-down" *ngIf="area.expanded">

                                <mat-checkbox [(ngModel)]="region.hasSelected" (ngModelChange)="regionChanged(region)">{{region.regionName}}</mat-checkbox>
                                <mat-icon class="rotate" (click)="region.expanded = !region.expanded"
                                    [class.rotated]="region.expanded">chevron_right</mat-icon>

                                <div style="margin-left:20px; overflow:hidden;" *ngFor="let territory of region.territories">
                                    <div class="slide-in-down" *ngIf="region.expanded">
                                        <mat-checkbox [(ngModel)]="territory.hasSelected" (ngModelChange)="territoryChanged(territory)">{{territory.territoryName}}</mat-checkbox>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <hr>
            <div class="data-label">What Trades Do You Service?</div>
            <div class="checkbox-container">
                <mat-checkbox *ngFor="let trade of trades" [(ngModel)]="trade.selected">
                    {{trade.name}}
                </mat-checkbox>
            </div>

            <hr>

            <div>
                <mat-slide-toggle [(ngModel)]="args.fullyLicensed">Are you licensed in your trades?</mat-slide-toggle>

            </div>
            <div topmargin20>
                <mat-slide-toggle [(ngModel)]="args.hasLiabilityInsurance">Do you have liability Insurance?
                </mat-slide-toggle>

            </div>
            <div topmargin20>
                <mat-slide-toggle [(ngModel)]="args.hasWorkmansComp">Do you have workman's compensation insurance?
                </mat-slide-toggle>

            </div>

            <hr>
            <div class="data-label">How Did You Hear About Us?</div>
            @if(sources.length <= 4) {
                <mat-radio-group [(ngModel)]="selectedSource" class="source-radio-group">
                    @for(source of sources; track $index) {
                        <mat-radio-button [value]="source">{{source}}</mat-radio-button>
                    }
                </mat-radio-group>
            } @else {
                <br>
                <mat-form-field appearance="outline" style="max-width: 40rem">
                    <mat-label>Source</mat-label>
                    <mat-select [(ngModel)]="selectedSource">
                        @for(source of sources; track $index) {
                            <mat-option [value]="source">{{source}}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            }


            <div *ngIf="isApplianceContractor">
                <hr>

                <div class="data-label">What Brands Do You Work On?</div>
                <div class="checkbox-container">
                    <mat-checkbox [(ngModel)]="allBrands">All</mat-checkbox>
                    <mat-checkbox *ngFor="let brand of brands" [(ngModel)]="brand.selected">
                        {{brand.name}}
                    </mat-checkbox>
                </div>
            </div>


        </mat-tab>
        <mat-tab label="Second">
            <div style="margin-top: 100px" class="center">
                <app-check-circle-animated></app-check-circle-animated>
                <h1>Information Submitted</h1>
                <p class="info" topmargin40 bottommargin20>
                    Thanks for your interest. We will be in touch.<br>
                    While you have a moment, check out or Service Pro Agreement and starter pack:
                </p>

                <a mat-button mat-raised-button href="https://elevateh.blob.core.windows.net/cdn/pdfs/elevate-homescriptions-service-pro-agreement.pdf" target="_blank">Download</a>
            </div>
        </mat-tab>
    </mat-tab-group>



</div>
