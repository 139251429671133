import { Component, ViewChild, ElementRef } from '@angular/core';
import { ContractorStateGrouping } from '@upkeeplabs/models/cogent';
import { DataApiService } from '@cogent/client/shared/services/api/data-api.service';
import { ContractorApiService } from '@cogent/client/shared/services/api/contractor-api.service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { ContractorReportItem } from '@cogent/shared/models/contractor-relations/contractor-report-item.model';


@Component({
    templateUrl: './contractor-report-card.component.html',
    styleUrls: ['./contractor-report-card.component.css'],
})
export class ContractorReportCardComponent {

    @ViewChild('selectionPanel') selectionPanel: ElementRef;
    @ViewChild('reportArea') reportArea: ElementRef;

    reportData: any;
    loading = false;

    thisMonth: Date = UtilitiesService.monthStart(new Date());
    startDate: Date = UtilitiesService.monthStart(new Date());
    endDate: Date;
    states: ContractorStateGrouping[];

    tradeIdValue: string;
    get tradeId() { return this.tradeIdValue; }
    set tradeId(value) {
        this.tradeIdValue = value;
        this.refreshContractors();
    }

    areaIdValue: string;
    get areaId() { return this.areaIdValue; }
    set areaId(value) {
        this.areaIdValue = value;
        this.refreshContractors();
    }
    contractorId: string = null; // = 'b7f4e0c9-daa7-46ea-8d51-0634d4e3b23b';

    trades: any[];
    areas: any[];
    contractors: any[];
    constructor(private entityApi: EntityApiService, private contractorApi: ContractorApiService, private dataApi: DataApiService) {
        this.startDate.setMonth(this.startDate.getMonth() - 12);
        this.endDate = UtilitiesService.monthStart(new Date());
        this.endDate.setMonth(this.endDate.getMonth());

        this.entityApi.getLoggedInUser().then(user => {
            this.contractorApi.getContractorTrades(user.id).then(contractorTrades => {
                this.trades = contractorTrades;
                if (this.trades.length > 0) {
                    this.tradeId = this.trades[0].tradeId;
                }
                // this.refreshAll();
            });
            this.entityApi.getContractorRegionsForContractor(user.id).then(cr => {
                this.states = cr;
            });
        });

    }


    get canRunReport(): boolean {
        return this.startDate != null && this.endDate != null && this.tradeId != null && this.areaId != null;
    }

    get contractor() {
        if (this.reportData && this.reportData.length > 0) {
            return this.reportData[0].contractor;
        }
        return null;
    }

    get trade() {
        if (this.reportData && this.reportData.length > 0) {
            return this.reportData[0].trade;
        }
        return null;
    }

    get area() {
        if (this.reportData && this.reportData.length > 0) {
            return this.reportData[0].area;
        }
        return null;
    }

    async refreshContractors() {
        // if (!this.tradeId || !this.areaId) {
        //     this.contractors = [];
        //     this.contractorId = null;
        // }
        // else {
        //     this.contractors = await this.contractorApi.getContractorAreaSummaries(this.tradeId, this.areaId);
        // }
    }

    async runReport() {
        this.loading = true;

        var contractor = await this.entityApi.getLoggedInUser();

        this.contractorApi.getContractorReportItems(this.startDate, this.endDate, this.tradeId, this.areaId, contractor.id).then(results => {
            this.reportData = results;
            this.loading = false;
        });
    }

    async toggleAccDetail(item: ContractorReportItem) {
        item.showAccDetail = !item.showAccDetail;
        if (!item.accDetail) {
            const start = new Date(item.month);
            start.setDate(start.getDate() - 1);
            const end = new Date(item.month);
            end.setDate(end.getDate() + 1);
            item.accDetail = await this.contractorApi.getAccDetail(start, end, this.tradeId, this.areaId, this.contractorId);
        }
    }

    tableToExcel() {
        UtilitiesService.export();
    }

    printReport() {
        UtilitiesService.printArea();
    }
}
