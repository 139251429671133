import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-sla-chip-selection',
  templateUrl: './sla-chip-selection.component.html',
  styleUrls: ['./sla-chip-selection.component.css']
})
export class SlaChipSelectionComponent implements OnInit {


  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  statusCtrl = new UntypedFormControl();
  filteredStatuses: Observable<string[]>;
  @Input() statuses: string[] = [];
  @Output() statusesChange: EventEmitter<string[]> = new EventEmitter();
  @Input() placeholder: string;
  allStatuses: string[] = ['NONE', 'GREEN', 'YELLOW', 'RED'];

  @ViewChild('statusInput') statusInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor() {
    this.filteredStatuses = this.statusCtrl.valueChanges.pipe(
      startWith(null),
      map((status: string | null) => status ? this._filter(status) : this.allStatuses.slice()));
  }

  ngOnInit() { }

  add(event: MatChipInputEvent): void {

    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;


      if ((value || '').trim()) {
        if(!this.statuses) {
          this.statuses = [];
        }
        this.statuses.push(value.trim());
        this.statusesChange.emit(this.statuses);
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.statusCtrl.setValue(null);
    }
  }

  remove(status: string): void {
    const index = this.statuses.indexOf(status);

    if (index >= 0) {
      this.statuses.splice(index, 1);
    }
    this.statusesChange.emit(this.statuses);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if(!this.statuses) {
      this.statuses = [];
    }
    this.statuses.push(event.option.viewValue);
    this.statusInput.nativeElement.value = '';
    this.statusCtrl.setValue(null);
    this.statusesChange.emit(this.statuses);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allStatuses.filter(status => status.toLowerCase().indexOf(filterValue) === 0);
  }
}
