import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RepairItemService } from '@cogent/client/shared/services/api/repair-item.service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { RepairItem } from '@upkeeplabs/models/cogent';
import { AuthorizationLine, AuthorizationLineAttribute } from '@cogent/shared/models/authorizations/authorization-line.model';

class InputArgs {
    workOrderSummary: WorkOrderSummaryClient;
    editItem: any;
    authoItems: RepairItem[];
}

@Component({
    selector: 'app-add-closing-item',
    templateUrl: './add-closing-item.component.html',
    styleUrls: ['./add-closing-item.component.css']
})
export class AddClosingItemComponent implements OnInit {
    filter: string = '';
    repairItems: RepairItem[];
    allRepairItems: RepairItem[];
    groupedItems: any[];
    newItem: AuthorizationLine;
    selectedRepairItemId: string;
    initializing = false;
    isItemSelection = true;
    isAttributeEntry = false;
    isAmountEntry = false;
    resetVisible = false;
    selectedIndex = 0;
    constructor(public dialogRef: MatDialogRef<AddClosingItemComponent>,
        private repairItemApi: RepairItemService,
        @Inject(MAT_DIALOG_DATA) public data: InputArgs) { }

    ngOnInit(): void {
        if (!this.data.workOrderSummary) {
            return;
        }

        this.addNewLineItem();
        if (this.data.editItem) {
            const editItem: AuthorizationLine = this.data.editItem;
            this.newItem.id = editItem.id;
            this.newItem.quantity = editItem.quantity;
            this.newItem.unitPrice = editItem.unitPrice;
            this.newItem.pricingType = editItem.pricingType;
            if (editItem.attributes) {
                this.newItem.attributes = UtilitiesService.copyArrayToTypedArray(editItem.attributes, () => new AuthorizationLineAttribute());
            }
            this.initializing = true;
            this.selectedIndex = 1;
        }


        if (!this.data.authoItems) {
            this.repairItemApi.findForJobItem(this.data.workOrderSummary.itemId).then(items => {
                this.data.authoItems = items;
                this.repairItems = items;
                this.groupRepairItems();
                this.allRepairItems = UtilitiesService.copyArrayToTypedArray(items, () => new RepairItem());
                if (this.data.editItem) {
                    const item = this.repairItems.find(i => i.id === this.data.editItem.itemId);
                    if (item) {
                        this.selectedRepairItemId = item.id;
                    }
                }
                if (this.initializing) {
                    setTimeout(() => this.initializing = false, 500);
                }
            });
        } else {
            this.repairItems = this.data.authoItems;
            this.groupRepairItems();
            for (const item of this.repairItems) {
                if (!item.amount) {
                    item.amount = 0;
                }
            }
            this.allRepairItems = UtilitiesService.copyArrayToTypedArray(this.data.authoItems, () => new RepairItem());
            if (this.data.editItem) {
                const item = this.repairItems.find(i => i.id === this.data.editItem.repairItemId);

                if (item) {
                    this.repairItems = [item];
                    this.groupRepairItems();
                    this.selectedRepairItemId = item.id;
                    this.resetVisible = true;
                    this.itemSelected();
                }
            }
        }
    }

    clearItemFilter() {
        this.repairItems = UtilitiesService.copyArrayToTypedArray(this.allRepairItems, () => new RepairItem());
        this.groupRepairItems();
        this.resetVisible = false;
    }



    private groupRepairItems() {
        let results = [];

        for (const item of this.repairItems) {
            if (!item.groupName) {
                item.groupName = 'Other';
            }

            let result = results.find(i => i.name === item.groupName);
            if (!result) {
                result = {
                    name: item.groupName,
                    items: []
                };

                results.push(result);
            }

            result.items.push(item);
        }
        results = results.sort((a, b) => a.name > b.name ? 1 : -1);
        this.groupedItems = results;
    }

    addNewLineItem() {
        this.newItem = new AuthorizationLine();
        this.newItem.quantity = 1;
        this.newItem.id = UtilitiesService.newid();
        this.newItem.labor = 0;
        this.newItem.workOrderId = this.data.workOrderSummary.id;
    }

    onSearchLayoutLoaded(event) {
        if (event.object.android) {
            event.object.android.setFocusableInTouchMode(true);
        }
    }

    onSearchBarLoaded(event) {
        if (event.object.android) {
            event.object.android.clearFocus();
        }
    }


    get isItemSelected() {
        return this.selectedRepairItemId ? true : false;
    }

    timeout: any;

    startFilter(event) {
        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => this.doFilter(event), 1000);
    }

    doFilter(event) {
        if (!this.filter) {
            const allRepairItemsCopy = UtilitiesService.copyArrayToTypedArray(this.allRepairItems, () => new RepairItem());
            this.repairItems = allRepairItemsCopy;
            this.groupRepairItems();
    
            this.setItem();
            return;
        }

        const allRepairItemsCopy = UtilitiesService.copyArrayToTypedArray(this.allRepairItems, () => new RepairItem());
        this.repairItems = allRepairItemsCopy.filter(i => i.id && i.name && i.name.toLowerCase().indexOf(this.filter.toLowerCase()) > -1);
        this.groupRepairItems();

        this.setItem();
    }

    async goNext() {
        await this.itemSelected();
        if (this.newItem.attributes.length === 0) {
            this.selectedIndex = 2;
        } else {
            this.selectedIndex = 1;
        }
    }

    clearSearch(event) {
        this.filter = '';
        this.repairItems = UtilitiesService.copyArrayToTypedArray(this.allRepairItems, () => new RepairItem());
        this.groupRepairItems();

        setTimeout(() => {

            if (event.object.android) {
                event.object.android.clearFocus();
            }
        });
        this.setItem();
    }

    setItem() {
        setTimeout(() => {
            if (this.selectedItem && this.selectedItem.id) {
                this.newItem.guideline = this.selectedItem.amount;
                this.newItem.repairItemId = this.selectedItem.id;
                this.newItem.yellowRangePercent = this.selectedItem.yellowRangePercent;
            } else if (this.selectedItem && !this.initializing) {
                this.newItem.repairItemId = null;
                this.newItem.guideline = null;
                this.newItem.yellowRangePercent = null;
            }
        });

    }

    async itemSelected() {

        await this.loadAttributes(this.selectedItem);
        this.isItemSelection = false;
        this.isAttributeEntry = false;
        this.isAmountEntry = false;
        this.newItem.repairItemId = this.selectedItem.id;
        if (!this.newItem.unitPrice) {
            this.newItem.unitPrice = this.selectedItem.amount;
        }
        if (!this.newItem.attributes || this.newItem.attributes.length === 0) {
            this.isAmountEntry = true;
        } else {
            this.isAttributeEntry = true;
        }
    }

    get currentProgress() {
        if (this.isItemSelection) {
            return 1;
        } else if (this.isAttributeEntry) {
            return this.currentAttributeIndex + 2;
        } else if (this.isAmountEntry) {
            return this.newItem.attributes.length + 2;
        }
    }

    get totalSteps() {
        if (!this.newItem || !this.newItem.attributes) {
            return 2;
        }
        return this.newItem.attributes.length + 2;
    }

    get selectedItem(): RepairItem {
        if (!this.repairItems) {
            return null;
        }
        return this.repairItems.find(i => i.id === this.selectedRepairItemId);
    }


    less() {
        if (this.newItem.quantity > 1) {
            this.newItem.quantity--;
        }
    }

    lessUnitPrice() {
        if (this.newItem.unitPrice > 1) {
            this.newItem.unitPrice--;
        }
    }

    get canAddItem(): boolean {
        if (!this.newItem.pricingType) {
            return false;
        }

        if (!this.newItem.repairItemId) {
            if (this.newItem.quantity > 0 && this.newItem.unitPrice >= 0) {
                return true;
            }
        }
        return this.newItem.quantity > 0 && this.newItem.unitPrice >= 0
            && this.newItem.repairItemId !== undefined && this.newItem.repairItemId != null && this.newItem.repairItemId !== '';
    }

    get isInEditMode() {
        const inEdit: boolean = (this.data.editItem as boolean);

        return inEdit;
    }

    get pageTitle() {
        return this.isInEditMode ? 'Edit Item' : 'Add Item';
    }


    addItem() {
        this.newItem.description = this.selectedItem.name;
        if (this.newItem.attributes) {
            for (const attribute of this.newItem.attributes) {
                this.newItem.description += '/' + attribute.calculatedValue;
            }
        }
        let guideline = 0;
        let yellowRangePercent = 0;
        guideline += this.selectedItem.amount;
        yellowRangePercent += this.selectedItem.yellowRangePercent;
        for (const lineAttribute of this.newItem.attributes) {
            const value = lineAttribute.availableValues.find(v => v.id === lineAttribute.valueId);
            if (value) {
                guideline += value.amountDelta;
                yellowRangePercent += value.yellowRangePercentDelta;
            }
        }
        this.newItem.guideline = guideline;
        this.newItem.yellowRangePercent = yellowRangePercent;

        this.filter = '';
        this.repairItems = UtilitiesService.copyArrayToTypedArray(this.allRepairItems, () => new RepairItem());
        this.groupRepairItems();

        this.newItem.repairItem = this.allRepairItems.find(i => i.id === this.newItem.repairItemId);
        this.dialogRef.close(this.newItem);
    }

    currentAttributeIndex = 0;
    get currentAttribute(): AuthorizationLineAttribute {
        return this.newItem.attributes[this.currentAttributeIndex];
    }

    nextAttribute() {
        if (this.currentAttribute.selectedValueIndex === -1) {
            this.currentAttribute.selectedValueIndex = 0;
        }
        this.currentAttribute.valueId = this.currentAttribute.available[this.currentAttribute.selectedValueIndex].id;
        if (this.currentAttributeIndex < this.newItem.attributes.length - 1) {
            this.currentAttributeIndex++;
        } else {
            this.isAttributeEntry = false;
            this.isAmountEntry = true;
        }

        this.hideUnavailableAttributeValues(this.newItem, true);
        let guideline = 0;
        guideline += this.selectedItem.amount;
        for (const lineAttribute of this.newItem.attributes) {
            const value = lineAttribute.availableValues.find(v => v.id === lineAttribute.valueId);
            if (value) {
                guideline += value.amountDelta;
            }
        }
        this.newItem.unitPrice = guideline;
    }



    hideUnavailableAttributeValues(line: AuthorizationLine, clearInvaliid = true) {
        for (const attrib of line.attributes) {
            for (const value of attrib.availableValues) {
                if (value.dependencies) {
                    const dependentIds = JSON.parse(value.dependencies);
                    if (dependentIds.length > 0) {
                        value.hidden = true;
                        for (const dependentId of dependentIds) {
                            if (line.attributes.find(a => a.valueId === dependentId)) {
                                value.hidden = false;
                            }
                        }
                    }
                }
            }
        }
        if (clearInvaliid) {
            for (const attrib of line.attributes) {
                if (attrib.valueId) {
                    const value = attrib.availableValues.find(v => v.id === attrib.valueId);
                    if (value.hidden) {
                        attrib.valueId = null;
                    }
                }
            }
        }

        line.resetAttributeVisibility();

    }

    backToAttributes() {
        this.isAmountEntry = false;
        if (!this.newItem.attributes || this.newItem.attributes.length === 0) {
            this.isItemSelection = true;
        } else {
            this.isAttributeEntry = true;
        }
    }

    attributeBack() {
        if (this.currentAttributeIndex > 0) {
            this.currentAttributeIndex -= 1;
        } else {
            this.isAttributeEntry = false;
            this.isItemSelection = true;
        }
    }

    loadingAttributes = false;
    private async loadAttributes(selectedRepairItem: RepairItem) {
        const newItem = this.newItem;
        this.loadingAttributes = true;
        const attributes = await this.repairItemApi.getRepairItemAttributesForItem(selectedRepairItem.id);

        if (!newItem.attributes) {
            newItem.attributes = [];
        }
        if (newItem.attributes.length > 0 && !attributes.find(a => a.id === newItem.attributes[0].attributeId)) {
            newItem.attributes = [];
        }
        try {
            for (let i = 0; i < attributes.length; i++) {
                const attribute = attributes[i];
                if (!newItem.attributes.find(a => a.attributeId === attribute.id)) {
                    const newAttribute = new AuthorizationLineAttribute();
                    newAttribute.authorizationLineId = newItem.id;
                    newAttribute.id = UtilitiesService.newid();
                    newAttribute.attributeId = attribute.id;
                    newAttribute.name = attribute.name;
                    newAttribute.createdDate = new Date();
                    newAttribute.availableValues = await this.repairItemApi.getRepairItemAttributeValuesForAttribute(attribute.id);
                    newItem.attributes.push(newAttribute);
                    newAttribute.isChanged = true;
                }
            }
        } catch (e) {
            throw e;
        }

        for (const attribute of this.newItem.attributes) {
            attribute.authorizationLineId = this.newItem.id;
        }
        this.loadingAttributes = false;
    }

    updateAttributeValue(attribute: AuthorizationLineAttribute, value: any) {
        attribute.valueId = value;
    }


    async updateRepairItem(index) {
        if (!this.repairItems) {
            return;
        }

        const selectedRepairItem = this.repairItems[index];

        if (selectedRepairItem && selectedRepairItem.id) {

            this.newItem.repairItemId = selectedRepairItem.id;
            this.newItem.unitPrice = selectedRepairItem.amount;
            if (this.data.editItem && this.data.editItem.itemId === selectedRepairItem.id) {
                this.newItem.unitPrice = this.data.editItem.unitPrice;
            }

        } else {
            this.newItem.repairItemId = null;
        }
        this.setItem();
    }

}
