import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ContractorKPIModel } from '@upkeeplabs/service-pros/app/model/contractors/contractor-kpi.model';
import { WorkOrderSurveySummaryModel } from '@upkeeplabs/service-pros/app/model/contractors/work-order-survey.model';
// import { UserSessionService } from '@upkeeplabs/service-pros/app/services/user-session.service';
import { ContractorsService } from '@upkeeplabs/service-pros/app/services/contractors.service';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
declare var Chart: any;
declare var document: any;

@Component({
    selector: 'app-survey-view',
    templateUrl: './survey-view.component.html',
    styleUrls: ['./survey-view.component.css']
})
export class SurveyViewComponent implements OnInit, OnChanges {
    @Input() kpis: ContractorKPIModel[];
    @Input() selectedTradeId: string;
    hideShowMoreSurvyes = false;
    surveyHistoryChart: any;
    loadingSurveys = false;
    surveys: WorkOrderSurveySummaryModel[];

    constructor(
        // private userSession: UserSessionService,
        private entityApi: EntityApiService,
        private contractorRepository: ContractorsService
    ) { }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.kpis && changes.kpis.currentValue) {
            this.setSurveyChartHistory();
        }

        if (changes.selectedTradeId && changes.selectedTradeId.currentValue) {
            this.refreshSurveys();
        }
    }

    private refreshSurveys() {
        this.entityApi.getLoggedInUser().then(user => {
            this.contractorRepository.getSurveys(user.id, this.selectedTradeId, null, 5)
                .then(surveys => { this.surveys = surveys; });
        });
    }


    get currentKPIs(): ContractorKPIModel {
        if (!this.kpis) {
            return null;
        }

        return this.kpis[this.kpis.length - 1];
    }

    loadMoreSurveys() {
        this.loadingSurveys = true;
        let startDate: Date = null;
        if (this.surveys && this.surveys.length > 0) {
            startDate = this.surveys[this.surveys.length - 1].createdDate;
        }
        this.entityApi.getLoggedInUser().then(user => {
            this.contractorRepository.getSurveys(user.id, this.selectedTradeId, startDate)
                .then(surveys => {
                    this.loadingSurveys = false;
                    if (surveys.length < 10) {
                        this.hideShowMoreSurvyes = true;
                    }
                    surveys.forEach(element => { this.surveys.push(element); });
                });
        });
    }

    setSurveyChartHistory() {
        const canvas = document.getElementById('survey-history-chart');
        if (!canvas) {
            setTimeout(() => this.setSurveyChartHistory(), 500);
            return;
        }

        const MONTHS = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
        ];
        const config = {
            type: 'line',
            data: {
                labels:
                    this.kpis.map(i => MONTHS[i.reportMonth - 1] + ' ' + i.reportYear),
                datasets: [
                    {
                        data: this.kpis.map(i => i.rating),
                        fill: false,
                    },
                ]
            },
            options: {
                responsive: true,
                legend: {
                    position: 'right',
                    display: false,
                },
                title: { display: false, text: 'Chart.js Line Chart' },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                hover: { mode: 'nearest', intersect: true },
                scales: {
                    xAxes: [
                        {
                            display: true,
                            scaleLabel: { display: true, labelString: 'Month' },
                            gridLines: { display: false }
                        }
                    ],
                    yAxes: [
                        {
                            display: true,
                            scaleLabel: { display: true, labelString: 'Rating' }
                        }
                    ]
                }
            }
        };

        const ctx = canvas.getContext('2d');
        if (this.surveyHistoryChart) {
            this.surveyHistoryChart.destroy();
        }
        this.surveyHistoryChart = new Chart(ctx, config);
    }


}
