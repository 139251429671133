<div id="backdrop" *ngIf="moreCriteria" (click)="moreCriteria = false"></div>
<div flex>
    <div id="selection-container" nogrow appAutoHeight>

        <div style="text-align: center" bottommargin20>
            <img src="/assets/images/document-circle.png" style="height: 100px">
        </div>

        <h2 class="sub-heading" bottommargin20>Report Criteria
        </h2>

        <app-date-range-display-and-selector [(startDate)]="startDate" [(endDate)]="endDate"></app-date-range-display-and-selector>
        <button mat-button block (click)="moreCriteria = !moreCriteria">Advance <i class="material-icons">
                chevron_right
            </i></button>

        <button mat-button mat-raised-button color="primary" topmargin20 [disabled]="loading" block
            (click)="loadReport()">
            <mat-spinner class="thirty" *ngIf="loading"></mat-spinner>
            Load
        </button>
        <div id="extended-report-criteria" *ngIf="moreCriteria">
            <div id="extended-report-criteria-inner" class="condense-form-fields">
                <div flex>
                    <div>
                        <h1 class="page-title">
                            <span class="big">
                                Advanced</span><br> <span class="little"> Criteria</span></h1>
                    </div>
                    <div nogrow>
                        <button mat-button (click)="moreCriteria = false"><i class="material-icons">
                                close
                            </i></button>
                    </div>
                </div>


                <h2 class="sub-heading" topmargin30>Include in the report</h2>
                <div topmargin20>
                    <div flex>
                        <div nogrow class="left-column">
                            <div class="data-label">Areas</div>
                        </div>

                        <div>
                            <service-pro-territory-select [(selectedTerritories)]="selectedRegions">
                            </service-pro-territory-select>
                        </div>
                    </div>
                    <div flex>
                        <div nogrow class="left-column">
                            <div class="data-label">Trades</div>
                        </div>

                        <div>
                            <app-trade-chip-selection [(selectedTrades)]="selectedTrades"></app-trade-chip-selection>
                        </div>
                    </div>


                    <div flex>
                        <div nogrow class="left-column">
                            <div class="data-label">Statuses</div>
                        </div>

                        <div>
                            <app-work-order-status-chip-selection [(statuses)]="selectedWorkOrderStatuses">
                            </app-work-order-status-chip-selection>

                        </div>
                    </div>
                    <div flex>
                        <div nogrow class="left-column">
                            <div class="data-label">Call Type</div>
                        </div>

                        <div>
                            <app-call-type-chip-selection [(statuses)]="selectedCallTypes">
                            </app-call-type-chip-selection>
                        </div>
                    </div>
                    <div flex>
                        <div nogrow class="left-column">
                            <div class="data-label">Job Item</div>
                        </div>
                        <div>
                            <app-work-order-item-chip-selection [(selectedItems)]="selectedItems">
                            </app-work-order-item-chip-selection>
                        </div>
                    </div>
                    <div flex>
                        <div nogrow class="left-column">
                            <div class="data-label">SLA</div>
                        </div>
                        <div>
                            <app-sla-chip-selection [(statuses)]="selectedSLA"></app-sla-chip-selection>
                        </div>
                    </div>
                </div>

                <h2 class="sub-heading" topmargin40>Exclude from the report</h2>
                <div topmargin20>
                    <div flex>
                        <div nogrow class="left-column">
                            <div class="data-label">Regions</div>
                        </div>
                        <div>
                            <service-pro-territory-select [(selectedTerritories)]="selectedRegionsExcluded">
                            </service-pro-territory-select>
                        </div>
                    </div>
                    <div flex>
                        <div nogrow class="left-column">
                            <div class="data-label">Trades</div>
                        </div>
                        <div>
                            <app-trade-chip-selection [(selectedTrades)]="selectedTradesExcluded">
                            </app-trade-chip-selection>
                        </div>
                    </div>
                    <div flex>
                        <div nogrow class="left-column">
                            <div class="data-label">Statuses</div>
                        </div>
                        <div>
                            <app-work-order-status-chip-selection [(statuses)]="selectedWorkOrderStatusesExcluded">
                            </app-work-order-status-chip-selection>

                        </div>
                    </div>
                    <div flex>
                        <div nogrow class="left-column">
                            <div class="data-label">Call Type</div>
                        </div>
                        <div>
                            <app-call-type-chip-selection [(statuses)]="selectedCallTypesExcluded">
                            </app-call-type-chip-selection>
                        </div>
                    </div>
                    <div flex>
                        <div nogrow class="left-column">
                            <div class="data-label">Job Item</div>
                        </div>
                        <div>
                            <app-work-order-item-chip-selection [(selectedItems)]="selectedItemsExcluded">
                            </app-work-order-item-chip-selection>
                        </div>
                    </div>
                    <div flex>
                        <div nogrow class="left-column">
                            <div class="data-label">SLA</div>
                        </div>
                        <div>
                            <app-sla-chip-selection [(statuses)]="selectedSLAExcluded"></app-sla-chip-selection>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div *ngIf="workOrders">
            <mat-toolbar>
                <button mat-button (click)="printReport()">
                    <i class="material-icons">print</i> Print</button>
                <button mat-button (click)="tableToExcel()">
                    <i class="material-icons">file_download</i> Export</button>

                <button mat-button [matMenuTriggerFor]="menu">
                    <i class="material-icons">view_stream</i> Group
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="setGrouping('none')"><i class="material-icons"
                            *ngIf="grouping == 'none'">check</i> None</button>
                    <button mat-menu-item (click)="setGrouping('trade')"><i class="material-icons"
                            *ngIf="grouping == 'trade'">check</i>Trade</button>
                    <button mat-menu-item (click)="setGrouping('date')"><i class="material-icons"
                            *ngIf="grouping == 'date'">check</i>Date</button>
                    <button mat-menu-item (click)="setGrouping('type')"><i class="material-icons"
                            *ngIf="grouping == 'type'">check</i>Type</button>
                    <button mat-menu-item (click)="setGrouping('contractor')"><i class="material-icons"
                            *ngIf="grouping == 'contractor'">check</i>Service Pro</button>
                    <button mat-menu-item (click)="setGrouping('status')"><i class="material-icons"
                            *ngIf="grouping == 'status'">check</i>Status</button>
                    <button mat-menu-item (click)="setGrouping('createdByName')"><i class="material-icons"
                            *ngIf="grouping == 'createdByName'">check</i>Created By</button>
                    <button mat-menu-item (click)="setGrouping('slaStatus')"><i class="material-icons"
                            *ngIf="grouping == 'slaStatus'">check</i>SLA</button>

                </mat-menu>
                <button mat-button (click)="columnSelectVisible = !columnSelectVisible">
                    <i class="material-icons">view_column</i>
                    Columns
                </button>
                <div id="column-select-backdrop" *ngIf="columnSelectVisible" (click)="columnSelectVisible = false">
                </div>
                <div id="columnSelect" *ngIf="columnSelectVisible">
                    <mat-checkbox [(ngModel)]="showNumber">Show Number</mat-checkbox>
                    <mat-checkbox [(ngModel)]="showDate">Show Date</mat-checkbox>
                    <mat-checkbox [(ngModel)]="showItem">Show Item</mat-checkbox>
                    <mat-checkbox [(ngModel)]="showProperty">Show Property</mat-checkbox>
                    <!-- <mat-checkbox [(ngModel)]="showServiceProvider">Show Service Pro</mat-checkbox> -->
                    <mat-checkbox [(ngModel)]="showType">Show Type</mat-checkbox>
                    <mat-checkbox [(ngModel)]="showStatus">Show Status</mat-checkbox>
                    <mat-checkbox [(ngModel)]="showSLA">Show SLA</mat-checkbox>
                    <mat-checkbox [(ngModel)]="showCustomer">Show Customer</mat-checkbox>
                    
                </div>
                <mat-checkbox style="font-size: 14px" [(ngModel)]="showSLAIndicator">Show SLA Indicators</mat-checkbox>
            </mat-toolbar>
            <div appAutoHeight id="reportArea">
                <table style="width: 100%;" *ngIf="workOrders">
                    <tr>
                        <td>
                            <h1>Service Request Report</h1>
                            <p topmargin10 style="font-style: italic">From {{reportStartDate | date: 'shortDate'}} to
                                {{reportEndDate | date: 'shortDate'}}</p>
                        </td>
                        <td style="text-align: right">
                            <div class="data-label">Total Count</div>
                            <h1 class="big-data">{{workOrders.length}}</h1>
                        </td>
                    </tr>
                </table>


                <table class="table" style="">
                    <thead>
                        <tr>
                            <th *ngIf="showNumber">Job #</th>
                            <th *ngIf="showDate">Date</th>
                            <th *ngIf="showItem">Item</th>
                            <th *ngIf="showProperty">Property</th>
                            <!-- <th *ngIf="showServiceProvider">Service Pro</th> -->
                            <th *ngIf="showCustomer">Customer</th>
                            <th *ngIf="showType">Type</th>
                            <th *ngIf="showStatus">Status</th>
                            <th *ngIf="showSLA">SLA</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let group of groupedItems">
                            <tr *ngIf="group.key" class="group-header">
                                <td [colSpan]="columnCount - 1">{{group.key}}</td>
                                <td>{{group.items.length}}</td>
                            </tr>
                            <tr *ngFor="let item of group.items"
                                [class.green]="showSLAIndicator && item.slaStatus == 'GREEN'"
                                [class.yellow]="showSLAIndicator && item.slaStatus == 'YELLOW'"
                                [class.red]="showSLAIndicator && item.slaStatus == 'RED'">
                                <td *ngIf="showNumber">
                                    <a routerLink="/work-order-detail/{{item.id}}">{{item.number}}</a>
                                </td>
                                <td *ngIf="showDate">{{item.createdDate | date: 'shortDate'}}</td>
                                <td *ngIf="showItem">{{item.itemName}}</td>
                                <td *ngIf="showProperty">{{item.propertyAddress}}</td>
                                <!-- <td *ngIf="showServiceProvider">{{item.contractorName}}</td>
                                 -->
                                 <td *ngIf="showCustomer">{{item.homeownerName}}</td>
                                <td *ngIf="showType">{{item.type}}</td>
                                <td *ngIf="showStatus">{{item.status}}</td>
                                <td *ngIf="showSLA">{{item.slaStatus}}</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div id="backdrop1" *ngIf="showDateRange" (click)="showDateRange = false;"></div>