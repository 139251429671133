import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { startWith, map } from 'rxjs/operators';
import { WorkOrderItemClient } from '@cogent/client/shared/models/items/work-order-item-client.model';
import { DataApiService } from '@cogent/client/shared/services/api/data-api.service';

@Component({
    selector: 'app-work-order-item-chip-selection',
    templateUrl: './work-order-item-chip-selection.component.html',
    styleUrls: ['./work-order-item-chip-selection.component.css']
})
export class WorkOrderItemChipSelectionComponent implements OnInit {

    allItems: WorkOrderItemClient[] = [];

    @Input() selectedItems: WorkOrderItemClient[] = [];
    @Output() selectedItemsChange: EventEmitter<WorkOrderItemClient[]> = new EventEmitter();
    @Input() singleSelect: boolean;
    @Input() placeholder: string;

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    itemCtrl = new UntypedFormControl();
    filteredItems: Observable<WorkOrderItemClient[]>;
    @ViewChild('itemInput') regionInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;

    constructor(private dataAccess: DataApiService) {
        this.filteredItems = this.itemCtrl.valueChanges.pipe(
            startWith(null),
            map((item: string | null) => item ? this._filter(item) : this.allItems.slice()));
    }



    ngOnInit() {
        this.dataAccess.getWorkOrderItemList().then(items => {
            this.allItems = items;
        });
    }


    add(event: MatChipInputEvent): void {

        if (!this.matAutocomplete.isOpen) {
            const input = event.input;

            // Reset the input value
            if (input) {
                input.value = '';
            }

            this.itemCtrl.setValue(null);
        }
    }

    remove(region: WorkOrderItemClient): void {
        const index = this.selectedItems.indexOf(region);

        if (index >= 0) {
            this.selectedItems.splice(index, 1);
        }

        this.selectedItemsChange.emit(this.selectedItems);
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        if (!this.selectedItems || this.singleSelect) {
            this.selectedItems = [];
        }
        this.selectedItems.push(event.option.value);
        this.regionInput.nativeElement.value = '';
        this.itemCtrl.setValue(null);
        this.selectedItemsChange.emit(this.selectedItems);
    }

    private _filter(value: any): WorkOrderItemClient[] {
        let filterValue = '';
        if (value.name) {
            filterValue = value;
        } else {
            filterValue = value.toLowerCase();
        }

        return this.allItems.filter(item => item.name.toLowerCase().indexOf(filterValue) === 0);
    }
}
