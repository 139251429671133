<mat-card *ngIf="currentKPIs">
    <div style="position: relative">
      <mat-card-header>
        <mat-card-title>
          <i class="material-icons" mat-card-avatar>record_voice_over</i> Surveys
        </mat-card-title>
      </mat-card-header>
    </div>
    <mat-card-content>
      <div class="star-ratings-sprite" topmargin20 bottommargin20>
        <span id="rating" [style.width]="(currentKPIs.rating / 5 * 100) + '%'" class="star-ratings-sprite-rating"></span>
      </div>


      <canvas id="survey-history-chart"></canvas>

      <h3 style="color: rgba(0,0,0,.5); text-transform: lowercase; font-size: 24px; margin-top: 20px">Recent Responses</h3>
      <a class="survey-response-link" mat-button *ngFor="let response of surveys" routerLink="/work-order-detail/{{response.workOrderId}}">
        <div flex>
          <div>
            <div class="star-ratings-sprite-sm">
              <span id="rating" [style.width]="(response.rating / 5 * 100) + '%'" class="star-ratings-sprite-rating-sm"></span>
            </div>

          </div>
          <div nogrow>
            {{response.createdDate | date: 'shortDate'}}
          </div>
        </div>

        <div *ngIf="response.comments">
          <div class="conversation-bubble incoming">{{response.comments}}</div>
          <div class="said"> ~ {{response.customerName}}</div>
        </div>
      </a>

      <button *ngIf="!hideShowMoreSurvyes" mat-button block [disabled]="loadingSurveys" (click)="loadMoreSurveys()">
        <mat-spinner class="thirty" *ngIf="loadingSurveys"></mat-spinner> Load More
      </button>
    </mat-card-content>
  </mat-card>