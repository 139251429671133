import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContractorsService } from '@upkeeplabs/service-pros/app/services/contractors.service';
import { ContractorInvoicePaymentSummary } from '@upkeeplabs/service-pros/app/model/contractors/contractor-invoice-payment-summary.model';
import { ViewContractorStatementComponent, ViewContractorStatementDialogData } from '@upkeeplabs/service-pros/app/pages/view-contractor-statement/view-contractor-statement.component';
import { ApiService } from '@cogent/client/api';

@Component({
    selector: 'app-contractor-statements',
    templateUrl: './contractor-statements.component.html',
    styleUrls: ['./contractor-statements.component.css']
})
export class ContractorStatementsComponent implements OnInit, OnChanges {

    @Input() contractorId: string;
    @Input() useMaintenanceService: boolean;

    hideShowMoreHistoryItems: boolean;
    contractorInvoicePayments: ContractorInvoicePaymentSummary[];
    lastDate: Date;
    loading = false;
    hasMore = true;

    constructor(private contractorRepository: ContractorsService, private dialog: MatDialog) { }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes['contractorId'] && changes['contractorId'].currentValue) {
            this.loadMore();
        }
    }


    async loadMore() {
        this.loading = true;
        let items = await this.contractorRepository.getContractorPaymentSummaries(this.contractorId, this.lastDate, 7);

        if (!this.useMaintenanceService) {
            items = items.filter(i => !i.type || i.type !== 'Maint')
        } else {
            items = items.filter(i => i.type === 'Maint');
        }

        this.loading = false;

        if (items.length > 0) {
            this.lastDate = items[items.length - 1].createdDate;
        }
        this.hasMore = items.length === 7;
        if (!this.contractorInvoicePayments) {
            this.contractorInvoicePayments = items;
        } else {
            this.contractorInvoicePayments = this.contractorInvoicePayments.concat(items);
        }
    }

    showStatement(contractorInvoicePayment: ContractorInvoicePaymentSummary) {
        if (this.useMaintenanceService) {

            window.open(`${ApiService.endPointNode}scheduled-maintenance/contractor-payment-pdf/${contractorInvoicePayment.id}`);
            return;
        }
        this.dialog.open(ViewContractorStatementComponent, {
            data: new ViewContractorStatementDialogData(contractorInvoicePayment)
        });
    }
}
