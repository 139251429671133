import { Component, OnInit, Inject } from '@angular/core';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { LoginApiService } from '@cogent/client/shared/services/api/login-api.service';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';

export class ChangePasswordDialogData {
    entityId: string;
    loginId: string;
}

@Component({
    selector: 'app-change-password',
    standalone: true,
    imports: [CommonModule, MatDialogModule, MatFormFieldModule, FormsModule, ReactiveFormsModule, MatInputModule, MatIconModule, MatProgressSpinnerModule, MatButtonModule],
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
    newPassword: string;
    newPasswordConfirm: string;
    changingPassword = false;

    constructor(private entityApi: EntityApiService,
        private loginApi: LoginApiService,
        private dialogService: DialogsService,
        public dialogRef: MatDialogRef<ChangePasswordComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ChangePasswordDialogData) { }

    ngOnInit() {
    }

    async changePassword() {

        this.changingPassword = true;
        const tempPasswordId = UtilitiesService.newid();
        await this.loginApi.createTemporaryLogin(tempPasswordId);
        let results: any = null;
        if (this.data.entityId) {
            results = await this.entityApi.changeLocalLoginPassword(tempPasswordId, this.newPassword, this.data.entityId);
        }
        else if (this.data.loginId) {
             results = await this.entityApi.changeLocalLoginPasswordForLoginId(tempPasswordId, this.newPassword, this.data.loginId);
        }

        this.changingPassword = false;

        if (results && !results.success) {
            this.dialogService.alert('Password Not Changed', `Sorry, we could not change your password.<br>${results.message}`);
        } else {
            this.dialogRef.close(true);
        }
    }

    get canChangePassword() {
        return this.newPassword && this.newPassword === this.newPasswordConfirm;
    }

}
